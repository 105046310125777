import { cloneDeep, orderBy, uniqBy } from 'lodash-es';
import { useField, useFormikContext } from 'formik';

import {
  apiOptionToFormOption,
  getQuestionNumber,
  internalOptionTypeToAPI,
  isIdeaPresenterQuestion,
  isOptionPopulated,
  requiresCarryForwardMatrixOption,
} from '../../../util/questions';
import {
  CARRY_FORWARD_MATRIX_ANY_OPTION,
  getCarryForwardTypeOptions,
  getQuestionOptions,
} from '../../../util/formOptions';
import { Question, QUESTION_TYPE } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormSearchSelectInput from '../../common/forms/FormSearchSelectInput';

function getOptionsToCarryForward({
  existingOptions,
  question,
}: {
  existingOptions: QuestionFormOption[];
  question: Question;
}): QuestionFormOption[] {
  // Grab all the options from the selected question and add them as
  // options to this question with the "carryOverParentId" set.
  const carryForwardOptionIds: number[] = [];
  const carryForwardOptions = question.options.map((option) => {
    const newOption = apiOptionToFormOption();
    // Existing option might correlate to carried option
    const existingOption = existingOptions.find(
      (eo) => eo.title === option.title,
    );
    if (existingOption) {
      newOption.id = existingOption.id;
    }

    newOption.carryOverParentId = option.id;
    newOption.image = { data: option.dataUrl, preview: null };
    newOption.title = option.title;

    carryForwardOptionIds.push(option.id);

    return newOption;
  });

  const nonEmptyOptions: QuestionFormOption[] = [];
  const existingCarryForwardOptions: QuestionFormOption[] = [];
  existingOptions.forEach((option) => {
    if (!isOptionPopulated(option)) {
      return;
    }

    // Non-empty options and options that haven't been carried over from a different question
    // are eligible to remain as options.
    if (!option.carryOverParentId) {
      nonEmptyOptions.push(option);
    } else if (option.id && carryForwardOptionIds.includes(option.id)) {
      existingCarryForwardOptions.push(option);
    }
  });

  const uniqCarriedElements = uniqBy(
    [...existingCarryForwardOptions, ...carryForwardOptions],
    (o) => o.carryOverParentId,
  );

  // We order the options to ensure that options carried over always appear in the same order.
  return orderBy(
    [
      // We might already have an option carried over. For example, the user may have selected
      // to carry forward options A and B, then deleted option B and then clicked to carry
      // forward the same options again. In that case we'll already have option A.
      ...uniqBy([...uniqCarriedElements, ...nonEmptyOptions], (o) => o.title),
    ],
    (o) => o.carryOverParentId,
  );
}

const CarryForward = ({
  formFieldName = 'options',
  question,
  questions,
}: {
  formFieldName?: 'statements' | 'options';
  question: Question | undefined;
  questions: Question[];
}) => {
  const [{ value: options }, , optionsHelpers] =
    useField<QuestionFormOption[]>(formFieldName);
  const [{ value: carryForward }, , carryForwardHelpers] = useField<
    QuestionFormData['features']['carryForward']
  >('features.carryForward');

  // Carry forward isn't relevant for the first question BUT the user may have dragged a later
  // question to be first in which case we want to show the carry forward feature so they can
  // remove it / adjust as necessary.
  const firstActiveQuestionId = questions.find((q) => q.isActive)?.id;
  const isDisabledFirstQuestion =
    !carryForward.enabled &&
    (questions.length === 0 || firstActiveQuestionId === question?.id);

  return (
    <>
      <FormCheckbox
        checkboxLabel="Carry Forward"
        disabled={isDisabledFirstQuestion}
        name="features.carryForward.enabled"
        onChange={(value) => {
          if (!value) {
            // If carry forward is disabled, we keep the carry forwarded options because it's currently
            // a convenient way to essentially transfer in a copy of a previous option and then modify it.
            // Eventually, I'd like to have a dedicated way to do this because this feels like a workaround.
            optionsHelpers.setValue(
              options.map((option) => {
                return {
                  ...option,
                  carryOverParentId: null,
                };
              }),
            );

            carryForwardHelpers.setValue({
              enabled: false,
              matrixOption: null,
              question: null,
              type: null,
            });
          }
        }}
        tooltip={
          <div>
            <p>
              This feature allows you to insert options from a previous question
              into this question.
            </p>
            {isDisabledFirstQuestion && (
              <p className="mt-4 font-bold">
                Disabled: Cannot carry forward into the first active question.
              </p>
            )}
          </div>
        }
      />
      {carryForward.enabled && (
        <CarryForwardConfiguration
          formFieldName={formFieldName}
          options={options}
          question={question}
          questions={questions}
        />
      )}
    </>
  );
};

export default CarryForward;

const CarryForwardConfiguration = ({
  formFieldName,
  options,
  question,
  questions,
}: {
  formFieldName: 'statements' | 'options';
  options: QuestionFormOption[];
  question: Question | undefined;
  questions: Question[];
}) => {
  const { setValues, values } = useFormikContext<QuestionFormData>();

  const [{ value: carryForward }] = useField<
    QuestionFormData['features']['carryForward']
  >('features.carryForward');
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');

  const questionId = question?.id;
  const isCurrentQuestionDemographic = question?.isDemographic;
  const questionNumber = getQuestionNumber({ question, questions });
  const carryForwardQuestionOptions = getQuestionOptions({
    filterFn: (question) => {
      const { contentTypeId, id, isDemographic, questionTypeId, sort } =
        question;

      const isNotCurrentQuestion = id !== questionId;
      const hasSameContentType =
        internalOptionTypeToAPI(optionType?.value) === contentTypeId;
      const hasOptionsToCarryForward =
        [
          QUESTION_TYPE.MULTIPLE_CHOICE,
          QUESTION_TYPE.RANKING,
          QUESTION_TYPE.MATRIX,
        ].includes(questionTypeId) && !isIdeaPresenterQuestion(question);
      const isPreviousQuestion = isCurrentQuestionDemographic
        ? sort < questionNumber
        : isDemographic || sort < questionNumber;

      return (
        isNotCurrentQuestion &&
        hasSameContentType &&
        hasOptionsToCarryForward &&
        isPreviousQuestion
      );
    },
    questions,
  });

  return (
    <div>
      <div className="flex p-2 space-x-4">
        <span className="flex-shrink-0 text-xs">
          Carry
          <br />
          forward
        </span>
        <div className="w-44">
          <FormSearchSelectInput
            name="features.carryForward.type"
            options={getCarryForwardTypeOptions(carryForward.question?.value)}
          />
        </div>
        <span className="flex-shrink-0 text-xs">
          from
          <br />
          question
        </span>
        <div className="flex-grow">
          <FormSearchSelectInput
            name="features.carryForward.question"
            onChange={(question) => {
              if (!question) {
                return;
              }

              const newFormData = cloneDeep(values);

              // The carry forward type is dependent on the type of the question so we need to
              // update this field when the question is changed.
              const newTypeOptions = getCarryForwardTypeOptions(question.value);
              newFormData.features.carryForward.type =
                newTypeOptions.find(({ value }) => {
                  return (
                    value === newFormData.features.carryForward.type?.value
                  );
                }) ?? null;

              newFormData.features.carryForward.question = question;
              newFormData[formFieldName] = getOptionsToCarryForward({
                existingOptions: options,
                question: question.value,
              });

              setValues(newFormData);
            }}
            options={carryForwardQuestionOptions}
          />
        </div>
      </div>
      <CarryForwardMatrixConfiguration carryForward={carryForward} />
    </div>
  );
};

const CarryForwardMatrixConfiguration = ({
  carryForward,
}: {
  carryForward: QuestionFormData['features']['carryForward'];
}) => {
  if (!requiresCarryForwardMatrixOption(carryForward)) {
    return null;
  }

  const matrixOptions = carryForward.question?.value.matrixOptions ?? [];
  const matrixOptionOptions = [
    CARRY_FORWARD_MATRIX_ANY_OPTION,
    ...orderBy(matrixOptions, (o) => o.sort).map((option) => {
      return {
        label: option.title,
        value: { id: option.id, title: option.title },
      };
    }),
  ];

  return (
    <div className="flex p-2 space-x-4">
      <span className="flex-shrink-0 text-xs">
        where selected
        <br />
        option is
      </span>
      <div className="flex-grow">
        <FormSearchSelectInput
          name="features.carryForward.matrixOption"
          options={matrixOptionOptions}
        />
      </div>
    </div>
  );
};
