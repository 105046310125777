import { clsx } from 'clsx';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { commentQueries } from 'hooks/backend/comments';
import {
  Question,
  Survey,
  SurveyVariable,
  SurveyWave,
} from '../../types/domainModels';
import { questionBlockQueries } from 'hooks/backend/questionBlocks';
import { questionQueries, useReorderQuestions } from 'hooks/backend/questions';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import { surveyFlowMachine } from './machines/surveyFlow';
import { surveyQueries } from 'hooks/backend/surveys';
import { useRouteBlocker } from 'contexts/routeBlocker';
import { variableQueries } from 'hooks/backend/surveyVariables';

import AudienceStep from './AudienceStep';
import Comments from './Comments';
import CreateQuestionBlocks from './CreateQuestionBlocks';
import CustomizeStep from './CustomizeStep';
import ErrorDisplay from '../common/ErrorDisplay';
import SurveyBuildWorkspaceSidebar, {
  MenuName,
} from '../common/SurveyBuildWorkspaceSidebar';
import OverviewStep from './OverviewStep';
import QuestionsStep from './QuestionsStep';
import ReviewStep from './ReviewStep';
import SidebarNavBuild from './SidebarNavBuild';
import SurveyVariablesPage from './SurveyVariablesPage';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';
import WavesSidebar from 'components/common/WavesSidebar';
import WavesStep from './WavesStep';

const SurveyEditPage = () => {
  const {
    id: surveyIdParam,
    resourceId,
    step: currentStep,
  } = useParams<{
    id: string;
    resourceId?: string;
    step: SurveyFlowStep;
  }>();
  const surveyId = Number(surveyIdParam);
  const resourceIdNum = Number.isNaN(Number(resourceId))
    ? undefined
    : Number(resourceId);

  const {
    data: survey,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useQuery(surveyQueries.survey({ surveyId }));

  const {
    data: loadWavesResults,
    isError: hasLoadWavesError,
    isLoading: isLoadingWaves,
  } = useQuery(surveyQueries.waves({ surveyId }));
  const waves = loadWavesResults?.waves || [];

  if (!surveyId) {
    return (
      <div className="p-6">
        <ErrorDisplay message="Missing URL variables for editing a survey." />
      </div>
    );
  }

  if (isLoadingSurvey || isLoadingWaves) {
    // Ideally this load is fast so we don't need a loading indicator.
    return null;
  } else if (hasLoadSurveyError || hasLoadWavesError || !survey) {
    return (
      <div className="p-6">
        <ErrorDisplay message="Error loading survey information." />
      </div>
    );
  }

  return (
    <SurveyEditPageLoaded
      currentStep={currentStep}
      resourceId={resourceIdNum}
      survey={survey}
      waves={waves}
    />
  );
};

const SurveyEditPageLoaded = ({
  currentStep,
  resourceId,
  survey,
  waves,
}: {
  currentStep?: SurveyFlowStep;
  resourceId?: number;
  survey: Survey;
  waves: SurveyWave[];
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const surveyId = survey.id;
  const activeWaveId = currentStep === 'waves' ? resourceId : undefined;
  const questionId = currentStep === 'questions' ? resourceId : undefined;
  const variableId = currentStep === 'variables' ? resourceId : undefined;

  const {
    data: questions = [],
    error: getQuestionsError,
    isLoadingError: hasLoadQuestionsError,
    isLoading: isLoadingQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const question = questions.find(({ id }) => id === questionId);

  const {
    data: variables = [],
    error: getVariablesError,
    isLoadingError: hasLoadVariablesError,
    isLoading: isLoadingVariables,
  } = useQuery({
    ...variableQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });
  const variable = variables.find(({ id }) => id === variableId);

  const {
    data: questionBlocks = [],
    error: getQuestionBlocksError,
    isLoadingError: hasLoadQuestionBlocksError,
    isLoading: isLoadingQuestionBlocks,
  } = useQuery({
    ...questionBlockQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const { data: questionComments = [] } = useQuery(
    commentQueries.questions({ surveyId }),
  );
  const { data: surveyComments = [] } = useQuery(
    commentQueries.survey({ surveyId }),
  );
  const comments = [...questionComments, ...surveyComments];

  const { mutate: reorderQuestions } = useReorderQuestions({
    onError: (err) => {
      showErrorMessage({ err });
    },
  });

  const { cancelBlock, isBlocked, performRedirect, shouldBlock } =
    useRouteBlocker();

  const [surveyFlowState, surveyFlowSend] = useMachine(
    surveyFlowMachine.provide({
      actions: {
        cancelBlock: () => {
          cancelBlock();
        },
        navigateToBlocked: () => {
          performRedirect();
        },
        navigateToNewQuestion: () => {
          navigate(`/surveys/${surveyId}/build/questions`);
        },
        navigateToSpecificResource: (_, params) => {
          if (
            currentStep === 'questions' ||
            currentStep === 'variables' ||
            currentStep === 'waves'
          ) {
            const resourceId = params.newResourceId
              ? `/${params.newResourceId}`
              : '';

            navigate(`/surveys/${surveyId}/build/${currentStep}${resourceId}`);
          }
        },
        shouldBlock: () => {
          shouldBlock();
        },
        updateRouteForLaunched: () => {
          navigate(`/surveys/${surveyId}/analyze`);
        },
        updateRouteForNewVariable: () => {
          navigate(`/surveys/${surveyId}/build/variables`);
        },
        updateRouteForNewBlock: () => {
          navigate(`/surveys/${surveyId}/build/question-blocks`);
        },
        updateRouteForStep: (_, params) => {
          const surveyIdToUse = params.surveyId ?? surveyId;

          let questionIdPart = '';
          if (
            params.step === 'questions' &&
            questions.filter((q) => !q.isDemographic).length > 0
          ) {
            questionIdPart = `/${questions[0].id}`;
          }

          navigate(
            `/surveys/${surveyIdToUse}/build/${params.step}${questionIdPart}`,
          );
        },
      },
      guards: {
        shouldNavigateAfterSave: (_, params) => {
          if (
            currentStep === 'questions' ||
            currentStep === 'variables' ||
            currentStep === 'waves'
          ) {
            return !params.existingResourceId;
          }

          return false;
        },
      },
    }),
  );
  const { formDataToDuplicate } = surveyFlowState.context;
  const isShowingUnsavedChanges = surveyFlowState.matches('unsavedChanges');

  const onDiscardChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISCARD_CHANGES' });
  }, [surveyFlowSend]);

  const onDismissUnsavedChanges = useCallback(() => {
    surveyFlowSend({ type: 'DISMISS_UNSAVED_CHANGES' });
  }, [surveyFlowSend]);

  const onFormDirtyChanged = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        surveyFlowSend({ type: 'FORM_DIRTY' });
      } else {
        surveyFlowSend({ type: 'FORM_NOT_DIRTY' });
      }
    },
    [surveyFlowSend],
  );

  const onHasFormError = useCallback(() => {
    surveyFlowSend({ type: 'HAS_FORM_ERROR' });
  }, [surveyFlowSend]);

  useEffect(() => {
    if (isBlocked) {
      surveyFlowSend({ type: 'BLOCKED_NAVIGATION' });
    }
  }, [isBlocked, surveyFlowSend]);

  const [openMenus, setOpenMenus] = useState<Record<MenuName, boolean>>({
    demos: true,
    questionBlocks: true,
    questions: true,
    variables: true,
  });

  function onMenuToggled(menuName: MenuName) {
    setOpenMenus((openMenus) => {
      return {
        ...openMenus,
        [menuName]: !openMenus[menuName],
      };
    });
  }

  const demographicQuestions = questions.filter((q) => q.isDemographic);
  const questionsSidebar = (
    <SurveyBuildWorkspaceSidebar
      comments={comments}
      curQuestion={question}
      curVariable={variable}
      demographicQuestions={demographicQuestions}
      onBlockCloned={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
        queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));
      }}
      onClickNewBlock={() => {
        surveyFlowSend({ type: 'enterNewBlock' });
      }}
      onClickNewQuestion={() => {
        surveyFlowSend({ type: 'enterNewQuestion' });
      }}
      onClickNewVariable={() => {
        surveyFlowSend({ type: 'enterNewVariable' });
      }}
      onMenuToggled={onMenuToggled}
      onQuestionsReordered={(newQuestions) => {
        queryClient.setQueryData(
          questionQueries.forSurvey({ surveyId }).queryKey,
          (oldData) => {
            if (!oldData) {
              return;
            }

            return [...demographicQuestions, ...newQuestions];
          },
        );

        reorderQuestions({
          data: newQuestions.map((q) => {
            return {
              blockId: q.blockId,
              id: q.id,
              monadicId: q.monadicId ?? null,
              sort: q.sort,
            };
          }),
          surveyId,
        });
      }}
      onTemplateQuestionsAdded={() => {
        queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
      }}
      openMenus={openMenus}
      questionBlocks={questionBlocks}
      questions={questions.filter((q) => !q.isDemographic)}
      survey={survey}
      variables={variables}
    />
  );

  const isContentWidthConstrained =
    currentStep === 'audience' ||
    currentStep === 'customize' ||
    currentStep === 'overview' ||
    currentStep === 'questions' ||
    currentStep === 'question-blocks' ||
    currentStep === 'review' ||
    currentStep === 'waves';

  return (
    <SurveyWithSidebar
      sidebarNav={<SidebarNavBuild surveyId={surveyId} />}
      sidebarRight={
        currentStep === 'variables' ? null : (
          <Comments
            comments={comments}
            questionId={question?.id}
            surveyId={surveyId}
          />
        )
      }
      sidebarWorkspace={
        currentStep === 'questions' ||
        currentStep === 'question-blocks' ||
        currentStep === 'variables' ? (
          questionsSidebar
        ) : currentStep === 'waves' ? (
          <WavesSidebar
            activeWaveId={activeWaveId}
            survey={survey}
            waves={waves}
          />
        ) : null
      }
    >
      <div
        className={clsx({
          'max-w-[800px] mx-auto': isContentWidthConstrained,
        })}
      >
        {currentStep === 'overview' ? (
          <OverviewStep
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            onOverviewDirtyChanged={onFormDirtyChanged}
            onOverviewSaved={() => {
              surveyFlowSend({ type: 'RESOURCE_SAVED' });
            }}
            questions={questions}
            survey={survey}
            waves={waves}
          />
        ) : currentStep === 'waves' ? (
          <WavesStep
            key={activeWaveId || 'newWave'}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            onWaveDirtyChanged={onFormDirtyChanged}
            onWaveSaved={(newWave) => {
              surveyFlowSend({
                newResourceId: newWave.id,
                type: 'RESOURCE_SAVED',
              });
            }}
            survey={survey}
            waveId={activeWaveId}
            waves={waves}
          />
        ) : currentStep === 'audience' ? (
          <AudienceStep
            demographicQuestions={demographicQuestions}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            onAudienceSaved={() => {
              surveyFlowSend({ type: 'RESOURCE_SAVED' });
            }}
            onDirtyChanged={onFormDirtyChanged}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            survey={survey}
          />
        ) : currentStep === 'variables' ? (
          <SurveyVariablesPage
            key={variable?.id || 'newVariable'}
            demographicQuestions={demographicQuestions}
            isLoadingDemographicQuestions={false}
            isLoadingQuestions={isLoadingQuestions}
            isLoadingVariables={isLoadingVariables}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            loadVariablesError={
              // Errors that occur after loading are handled on an individual basis (e.g. showing
              // an error toast or something else depending on the context).
              hasLoadVariablesError && getVariablesError instanceof Error
                ? getVariablesError
                : null
            }
            onDirtyChanged={onFormDirtyChanged}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            onVariableDeleted={() => {
              const currentVariableIndex = variables.findIndex((variable) => {
                return variable.id === variableId;
              });

              let nextVariable: SurveyVariable | null = null;
              if (currentVariableIndex !== -1) {
                // Try to use the previous question for the next question to show but if there is no
                // previous question (e.g. the user deleted the first question), try to use the next
                // question.
                nextVariable =
                  variables[currentVariableIndex - 1] ??
                  variables[currentVariableIndex + 1] ??
                  null;
              }

              surveyFlowSend({
                newResourceId: nextVariable?.id,
                type: 'RESOURCE_DELETED',
              });
            }}
            onVariableSaved={(data) => {
              surveyFlowSend({
                existingResourceId: variable?.id,
                newResourceId: data.id,
                type: 'RESOURCE_SAVED',
              });
            }}
            questions={questions}
            questionsSidebar={questionsSidebar}
            survey={survey}
            surveyId={surveyId}
            variable={variable}
          />
        ) : currentStep === 'questions' ? (
          <QuestionsStep
            key={question?.id || 'newQuestion'}
            initialFormData={formDataToDuplicate}
            isLoadingQuestions={isLoadingQuestions}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            loadQuestionsError={
              // Errors that occur after loading are handled on an individual basis (e.g. showing
              // an error toast or something else depending on the context).
              hasLoadQuestionsError && getQuestionsError instanceof Error
                ? getQuestionsError
                : null
            }
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onDuplicate={(formData) => {
              surveyFlowSend({ formData, type: 'duplicateQuestion' });
            }}
            onHasError={onHasFormError}
            onQuestionDeleted={() => {
              const currentQuestionIndex = questions.findIndex((question) => {
                return question.id === questionId;
              });

              let nextQuestion: Question | null = null;
              if (currentQuestionIndex !== -1) {
                // Try to use the previous question for the next question to show but if there is no
                // previous question (e.g. the user deleted the first question), try to use the next
                // question.
                nextQuestion =
                  questions[currentQuestionIndex - 1] ??
                  questions[currentQuestionIndex + 1] ??
                  null;
              }

              surveyFlowSend({
                newResourceId: nextQuestion?.id,
                type: 'RESOURCE_DELETED',
              });
            }}
            onQuestionDirtyChanged={onFormDirtyChanged}
            onQuestionSaved={(data) => {
              surveyFlowSend({
                existingResourceId: question?.id,
                newResourceId: data.id,
                type: 'RESOURCE_SAVED',
              });
            }}
            question={question}
            questionBlocks={questionBlocks}
            questions={questions}
            survey={survey}
            variables={variables}
          />
        ) : currentStep === 'question-blocks' ? (
          <CreateQuestionBlocks
            isLoadingDemographicQuestions={false}
            isLoadingQuestionBlocks={isLoadingQuestionBlocks}
            isLoadingQuestions={isLoadingQuestions}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            loadQuestionBlocksError={
              // Errors that occur after loading are handled on an individual basis (e.g. showing
              // an error toast or something else depending on the context).
              hasLoadQuestionBlocksError &&
              getQuestionBlocksError instanceof Error
                ? getQuestionBlocksError
                : null
            }
            onBlockDirtyChanged={onFormDirtyChanged}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            onQuestionBlockDeleted={() => {
              queryClient.invalidateQueries(
                questionQueries.forSurvey({ surveyId }),
              );
              queryClient.invalidateQueries(
                questionBlockQueries.list({ surveyId }),
              );
            }}
            onQuestionBlocksSaved={() => {
              queryClient.invalidateQueries(
                questionQueries.forSurvey({ surveyId }),
              );
              queryClient.invalidateQueries(
                questionBlockQueries.list({ surveyId }),
              );

              surveyFlowSend({ type: 'RESOURCE_SAVED' });
            }}
            questionBlocks={questionBlocks}
            questions={questions}
            survey={survey}
            surveyId={surveyId}
          />
        ) : currentStep === 'customize' ? (
          <CustomizeStep
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            onCustomizeDirtyChanged={onFormDirtyChanged}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasFormError}
            onSurveySaved={() => {
              surveyFlowSend({ type: 'RESOURCE_SAVED' });
            }}
            survey={survey}
          />
        ) : currentStep === 'review' ? (
          <ReviewStep
            onHasError={onHasFormError}
            onStepCompleted={() => {
              surveyFlowSend({
                type: 'launched',
              });
            }}
            questions={questions}
            survey={survey}
            surveyId={surveyId}
            surveyVariables={variables}
            waves={waves}
          />
        ) : (
          <Navigate to={`/surveys/${surveyId}/build/overview`} />
        )}
      </div>
    </SurveyWithSidebar>
  );
};

export default SurveyEditPage;
