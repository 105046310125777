import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { Tab as HeadlessUITab } from '@headlessui/react';

import Icon from './Icon';

const TabGroup = (props: {
  children: ReactNode;
  onChange?: (index: number) => void;
  selectedIndex?: number;
}) => {
  return <HeadlessUITab.Group {...props} />;
};

export default TabGroup;

export const TabList = ({
  children,
  size,
}: {
  children: ReactNode;
  size: 'sm' | 'md' | 'lg';
}) => {
  return (
    <HeadlessUITab.List
      className={clsx('flex space-x-6', {
        'h-8': size === 'sm',
        'h-10': size === 'md',
        'h-12': size === 'lg',
      })}
    >
      {children}
    </HeadlessUITab.List>
  );
};

export const Tab = ({ children }: { children: ReactNode }) => {
  return (
    <HeadlessUITab
      className={
        // We need both the "ui-selected" and "ui-not-selected" because otherwise for nested tabs
        // the individual tabs will all be selected (since they'll inherit from the parent tabs).
        // Specifying both variants is a workaround.
        // See https://github.com/tailwindlabs/headlessui/issues/1857
        'flex border-primary-d-700 h-full cursor-pointer text-sm font-semibold ui-selected:border-b-2 ui-selected:text-primary-d-700 ui-selected:-mb-2 ui-not-selected:border-transparent ui-not-selected:text-gray-d-500 items-start'
      }
    >
      {children}
    </HeadlessUITab>
  );
};

export const TabPanels = ({ children }: { children: ReactNode }) => {
  return <HeadlessUITab.Panels>{children}</HeadlessUITab.Panels>;
};

export const TabPanel = ({ children }: { children: ReactNode }) => {
  return <HeadlessUITab.Panel>{children}</HeadlessUITab.Panel>;
};

export const TabWithAlert = ({
  children,
  hasAlert,
}: {
  children: string;
  hasAlert: boolean;
}) => {
  return (
    <Tab>
      <div className="flex items-center gap-2">
        <span>{children}</span>

        {hasAlert && (
          <div className="h-4 w-4 text-red">
            <Icon id="alert-circle" />
          </div>
        )}
      </div>
    </Tab>
  );
};
