import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  Outlet,
  useLocation,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { initReplay } from 'util/replays';

import { useAuth } from '../../contexts/auth';
import AccountSettingsPage from 'components/accountSettings/AccountSettingsPage';
import App from '../App';
import { Crosstab } from 'components/surveyAnalytics/Crosstab';
import {
  EmailConfirmationNotFound,
  EmailConfirmationSuccess,
} from '../createAccount/partials/EmailConfirmation';
import DashboardPage from '../dashboard/DashboardPage';
import { ErrorFallback } from 'components/errors/ErrorBoundary';
import ForgotPasswordPage from '../login/ForgotPasswordPage';
import LoginPage from '../login/LoginPage';
import ManageResponses from 'components/surveyAnalytics/ManageResponses';
import NotFoundPage from '../errors/NotFoundPage';
import ResetPasswordPage from '../login/ResetPasswordPage';
import Password from '../onboarding/Password';
import Welcome from '../onboarding/Welcome';
import {
  PulleyRedirectFailure,
  PulleyRedirectSuccess,
} from '../redirects/LucidPulleyRedirects';
import SummaryReports from 'components/surveyAnalytics/SummaryReports';
import SurveyEditPage from '../surveyEdit/SurveyEditPage';
import SurveyLauncedPage, {
  SurveyAnalytics,
  SurveyExports,
} from '../surveyAnalytics/SurveyLaunchedPage';
import SurveyPage from 'components/layout/SurveyPage';
import TeamPage from 'components/team/TeamPage';

// See the Sentry documentation on integrating with react-router:
// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} errorElement={<ErrorFallback />} path="/">
      {/* Pre-Auth pages */}
      <Route element={<LoginPage />} path="/login" />
      <Route element={<ForgotPasswordPage />} path="/forgotpassword" />
      <Route element={<ResetPasswordPage />} path="/resetpassword/:token" />

      <Route
        element={<EmailConfirmationSuccess />}
        path="/onboarding/confirm/success"
      />
      <Route
        element={<EmailConfirmationNotFound />}
        path="/onboarding/confirm/notFound"
      />
      <Route element={<PulleyRedirectSuccess />} path="/partnered/success" />
      <Route element={<PulleyRedirectFailure />} path="/partnered/failure" />

      <Route element={<Welcome />} path="/onboarding/welcome/:token" />
      <Route element={<Password />} path="/onboarding/password/:token" />

      <Route element={<AuthenticatedRoute />}>
        <Route element={<DashboardPage />} path="/dashboard/:tab" />
        <Route element={<AccountSettingsPage />} path="/account" />
        <Route element={<TeamPage />} path="/team" />

        <Route element={<SurveyPage />} path="/surveys/:id">
          <Route
            element={<SurveyEditPage />}
            path="build/:step?/:resourceId?"
          />
          <Route element={<SurveyLauncedPage />} path="analyze">
            <Route element={<SurveyAnalytics />} index />
            <Route element={<SummaryReports />} path="summary-reports" />
            <Route element={<Crosstab />} path="crosstab" />
            <Route element={<ManageResponses />} path="manage-responses" />
            <Route element={<SurveyExports />} path="exports" />
          </Route>
        </Route>
      </Route>

      {/* Redirect legacy pages to new dashboard. */}
      <Route element={<Navigate to="/dashboard/launched" />} path="/projects" />
      <Route
        element={<Navigate to="/dashboard/launched" />}
        path="/projects/:id"
      />
      <Route element={<Navigate to="/dashboard/launched" />} path="/account" />
      <Route
        element={<Navigate to="/dashboard/launched" />}
        path="/teams/:id"
      />
      <Route
        element={<Navigate to="/dashboard/launched" />}
        path="/teams/members/:id"
      />
      <Route element={<Navigate to="/dashboard/launched" />} path="/" />

      {/* Redirect v2 flow to default flow. */}
      <Route
        element={<Navigate to="/campaign/create" />}
        path="/v2/campaign/create"
      />
      <Route
        element={<Navigate to="/campaign/edit/:id/:step/:questionId?" />}
        path="/v2/campaign/edit/:id/:step/:questionId?"
      />

      <Route element={<NotFoundPage />} path="*" />
    </Route>,
  ),
);

function AuthenticatedRoute() {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  // We only start replays when a user is authenticated to save sessions.
  useEffect(() => {
    if (isLoggedIn) {
      initReplay();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    const redirect =
      location.pathname === '' ||
      location.pathname === '/login' ||
      location.pathname === '/logout'
        ? ''
        : `?redirect=${location.pathname}`;

    return <Navigate replace to={`/login${redirect}`} />;
  }

  return <Outlet />;
}
