import { isEqual, map, pick } from 'lodash-es';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createAudience } from '../../services/backend/audience';
import {
  DemographicFormData,
  DemographicFormDataValidated,
  formDataToApiData,
  formInboundCensusToApiData,
} from '../../util/demographics';
import { isLucidSurvey } from 'util/surveys';
import { Survey, SurveyQualityCheck } from '../../types/domainModels';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import {
  surveyQueries,
  useRelaunchExternalSurvey,
  useUpdateInboundCensus,
  useUpdateSurvey,
} from './surveys';
import { UpdateSurveyRequestData } from '../../services/backend/surveys';

export function useSaveAudience({
  initialValues,
  onError,
  onSuccess,
  survey,
}: {
  initialValues: DemographicFormData;
  onError(err: Error): void;
  onSuccess(): void;
  survey: Survey;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: relaunchExternalSurvey } = useRelaunchExternalSurvey();
  const { mutateAsync: updateSurvey } = useUpdateSurvey();
  const { mutateAsync: updateInboundCensus } = useUpdateInboundCensus();

  return useMutation({
    mutationFn: async (formData: DemographicFormDataValidated) => {
      const isBringYourOwnAudience =
        formData.audienceProfile.audienceSource === 'byoa';
      let commonSurveyUpdateData: UpdateSurveyRequestData = {
        incidenceTypeId: survey.incidenceTypeId,
        isBringYourOwnAudience,
        panelProvider: isBringYourOwnAudience
          ? null
          : formData.audienceProfile.panelProvider,
        participants: survey.participants,
        projectId: survey.projectId,
        qualityChecks: map(
          formData.respondentQuality.qualityChecks,
          (enabled, type) => {
            return { enabled, type: type as SurveyQualityCheck['type'] };
          },
        ),
        statusId: survey?.statusId ?? 6,
        title: survey.title,
      };

      const customAudienceFields = [
        'audienceProfile.demographicQuotas',
        'audienceProfile.title',
      ];
      const hasCustomAudienceChanged = !isEqual(
        pick(formData, customAudienceFields),
        pick(initialValues, customAudienceFields),
      );

      if (
        formData.audienceProfile.audienceType === 'custom' &&
        hasCustomAudienceChanged
      ) {
        const newAudienceData = await createAudience({
          data: formDataToApiData({ formData }),
        });
        commonSurveyUpdateData = {
          ...commonSurveyUpdateData,
          audienceId: newAudienceData.id,
        };
      } else if (
        formData.audienceProfile.audienceType === 'natural' &&
        survey.audienceId !== 0
      ) {
        commonSurveyUpdateData = { ...commonSurveyUpdateData, audienceId: 0 };
      }

      await updateSurvey({ data: commonSurveyUpdateData, surveyId: survey.id });

      if (
        survey.status?.name === SURVEY_STATUSES.APPROVED.name &&
        isLucidSurvey(survey)
      ) {
        await relaunchExternalSurvey({ surveyId: survey.id });
      }

      await updateInboundCensus({
        data: formInboundCensusToApiData({ formData }),
        surveyId: survey.id,
      });

      await queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );
    },
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: surveyQueries.allAudienceSlices({ surveyId: survey.id }),
      });

      onSuccess();
    },
  });
}
