import { useState } from 'react';
import {
  FieldArray,
  Formik,
  FormikTouched,
  useField,
  useFormikContext,
} from 'formik';

import { errorsToTouched } from '../../util/forms';
import {
  getEmptyVariableQuota,
  hasConfiguredVariableQuotas,
  SurveyVariableFormData,
  SurveyVariableSegment,
  validateVariableQuotas,
} from '../../util/surveyVariables';
import { SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS } from '../../util/formOptions';

import AddButton from '../common/forms/AddButton';
import Button from '../common/forms/Button';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Modal, { ModalHeader } from '../common/Modal';
import WordSeparator from 'components/common/WordSeparator';
import XButton from '../common/forms/XButton';

interface QuotasFormData {
  modalQuotas: SurveyVariableFormData['quotas'];
}

const VariableQuotasModal = ({
  onCloseModal,
}: {
  onCloseModal(): void;
}): JSX.Element => {
  const [{ value: segments }] =
    useField<SurveyVariableFormData['segments']>('segments');
  const [{ value: quotas }, quotasMeta, quotasHelpers] =
    useField<SurveyVariableFormData['quotas']>('quotas');

  const [validateOnBlur, setValidateOnBlur] = useState(!!quotasMeta.error);

  return (
    <Modal
      header={<ModalHeader onClickClose={onCloseModal}>Quotas</ModalHeader>}
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <Formik<QuotasFormData>
        initialErrors={{
          modalQuotas: quotasMeta.error,
        }}
        initialTouched={
          quotasMeta.error
            ? // The cast to FormikTouched<QuotasFormData> is not ideal but Formik TypeScript
              // support is not great in the current version so this is a workaround until they have
              // better support.
              ({
                modalQuotas: errorsToTouched(quotasMeta.error),
              } as FormikTouched<QuotasFormData>)
            : undefined
        }
        initialValues={{
          modalQuotas: quotas,
        }}
        onSubmit={(formData) => {
          const newQuotas = formData.modalQuotas;

          if (hasConfiguredVariableQuotas(newQuotas)) {
            quotasHelpers.setValue(newQuotas);
          } else {
            quotasHelpers.setValue([]);
          }

          onCloseModal();
        }}
        validate={(formData) => {
          const errors = validateVariableQuotas({
            quotas: formData.modalQuotas,
            segmentIds: segments.map(({ tempId }) => tempId),
          });
          if (errors) {
            return { modalQuotas: errors };
          }

          return {};
        }}
        // Validation on blur is annoying if you haven't yet attempted to submit the form
        // because it may alert you to something you were already going to address.
        validateOnBlur={validateOnBlur}
        validateOnChange={false}
      >
        <VariableQuotasForm
          onClickClose={onCloseModal}
          onSubmit={() => {
            setValidateOnBlur(true);
          }}
          segments={segments}
        />
      </Formik>
    </Modal>
  );
};

export default VariableQuotasModal;

const VariableQuotasForm = ({
  generateSubmitButton = true,
  onClickClose,
  onSubmit,
  segments,
}: {
  generateSubmitButton?: boolean;
  onClickClose(): void;
  onSubmit?(): void;
  segments: SurveyVariableSegment[];
}): JSX.Element => {
  const { submitForm } = useFormikContext();
  const [{ value: modalQuotas }] =
    useField<QuotasFormData['modalQuotas']>('modalQuotas');

  return (
    <div>
      <div className="w-edit-quotas-modal">
        <FieldArray
          name="modalQuotas"
          render={(arrayHelpers) => {
            return (
              <>
                <div>
                  {modalQuotas.length === 0 && (
                    <p className="mb-4 text-sm">
                      No quotas are currently configured.
                    </p>
                  )}
                  {modalQuotas.map((_quota, index) => {
                    return (
                      <div key={index}>
                        <Quota
                          index={index}
                          onClickRemove={() => {
                            arrayHelpers.remove(index);
                          }}
                          segments={segments}
                        />
                        {index !== modalQuotas.length - 1 && (
                          <WordSeparator word="and" />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex mt-2">
                  <AddButton
                    label="Add Quota"
                    onClick={() => {
                      arrayHelpers.push(getEmptyVariableQuota());
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </div>
      {generateSubmitButton && (
        <div className="mt-8 flex gap-3 flex-row-reverse">
          {onSubmit && (
            <Button
              hierarchy="primary"
              onClick={() => {
                submitForm();
                onSubmit();
              }}
              size="md"
              type="button"
            >
              Apply
            </Button>
          )}
          <Button
            hierarchy="secondary-gray"
            onClick={onClickClose}
            size="md"
            type="button"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

const Quota = ({
  index,
  onClickRemove,
  segments,
}: {
  index: number;
  onClickRemove?: () => void;
  segments: SurveyVariableFormData['segments'];
}): JSX.Element => {
  const quotaNamePrefix = `modalQuotas.${index}`;
  const [{ value: type }] = useField<
    SurveyVariableFormData['quotas'][number]['type']
  >(`${quotaNamePrefix}.type`);

  return (
    <div className="flex p-2 space-x-4">
      <div className="w-28">
        <FormSearchSelectInput
          name={`${quotaNamePrefix}.type`}
          options={SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS}
        />
      </div>
      {type?.value === 'at_most' && (
        <div className="w-24">
          <FormInput
            name={`${quotaNamePrefix}.value`}
            size="md"
            type="number"
          />
        </div>
      )}
      <span className="w-24 text-xs">
        qualifiers for
        <br /> either segment(s)
      </span>
      <div className="flex-grow w-2/6">
        <FormSearchSelectInput
          isMulti={true}
          name={`${quotaNamePrefix}.segments`}
          options={segments
            .filter((segment) => !!segment.title)
            .map((segment) => {
              return {
                label: segment.title,
                value: segment,
              };
            })}
        />
      </div>
      <div className="mt-2">
        {onClickRemove && <XButton onClick={onClickRemove} title="Remove" />}
      </div>
    </div>
  );
};
