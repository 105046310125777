import { ReactNode } from 'react';
import { toast } from 'react-toastify';

import { getErrorMessage } from './errors';

export function showErrorMessage(opts: { err: Error } | { message: string }) {
  let message = 'An error occurred. Please try again.';
  if ('err' in opts) {
    message = getErrorMessage(opts.err);
  } else {
    message = opts.message;
  }

  toast.error(message);
}

export function showSuccessMessage(message: ReactNode) {
  toast.success(message);
}
