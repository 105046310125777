import { forEach, groupBy, isEmpty, orderBy, some } from 'lodash-es';
import { FormikErrors } from 'formik';

import { CreateQuestionBlocksBody } from '../services/backend/questionBlocks';
import {
  DisplayLogicOrGroups,
  DisplayLogicOrGroupsValidated,
} from '../types/forms';
import {
  getApiDataForDisplayLogicV2,
  getFormDisplayLogicConstraintsV2,
  MODIFIER_OPTIONS_DEFAULT,
  validateDisplayLogic,
} from './displayLogic';
import { getQuestionOption } from './formOptions';
import { isIdeaPresenterQuestion } from './questions';
import {
  ChildBlock,
  Question,
  QuestionBlock,
  Survey,
} from '../types/domainModels';

export interface QuestionBlockDisplayLogic {
  displayLogic: DisplayLogicOrGroups;
}

interface QuestionBlockChild {
  displayLogic: {
    enabled: boolean;
    values: DisplayLogicOrGroups;
  };
  countInX: {
    enabled: boolean;
    value: boolean;
  };
  end: number | '';
  start: number | '';
  title: string;
  id?: number;
}

interface QuestionBlockChildValidated {
  displayLogic: {
    enabled: boolean;
    values: DisplayLogicOrGroupsValidated;
  };
  countInX: { enabled: boolean; value: boolean };
  end: number;
  start: number;
  title: string;
  id?: number;
}

export interface QuestionBlocksFormData {
  blocks: QuestionBlockChild[];
  displayXOfY: { enabled: boolean; value: number | '' };
  isRandomized: boolean;
}

export interface QuestionBlocksFormDataValidated {
  blocks: QuestionBlockChildValidated[];
  displayXOfY: { enabled: boolean; value: number };
  isRandomized: boolean;
}

export function apiDisplayLogicToFormDisplayLogic({
  block,
  questions,
}: {
  block: ChildBlock;
  questions: Question[];
}) {
  const groupedDisplayLogic = groupBy(
    block.displayLogic,
    (dl) => dl.andGrouping,
  );
  const displayLogic: QuestionBlockChild['displayLogic']['values'] = [];

  forEach(groupedDisplayLogic, (apiAndGrouping) => {
    displayLogic.push(
      apiAndGrouping.map((attrs) => {
        const displayLogicQuestion = questions.find(
          ({ id }) => id === attrs.questionId,
        );

        return {
          concept: null,
          constraints: displayLogicQuestion
            ? getFormDisplayLogicConstraintsV2({
                apiConstraints: attrs.constraints,
                fullQuestion: displayLogicQuestion,
              })
            : [],
          modifier:
            MODIFIER_OPTIONS_DEFAULT.find(
              ({ value }) => value === attrs.logicalModifier,
            ) ?? null,
          question: displayLogicQuestion
            ? getQuestionOption({ question: displayLogicQuestion })
            : null,
        };
      }),
    );
  });

  return displayLogic;
}

export function formDataToApiData({
  formData,
  survey,
}: {
  formData: QuestionBlocksFormDataValidated;
  survey: Survey;
}): CreateQuestionBlocksBody {
  return {
    amountViewed: formData.displayXOfY.enabled
      ? formData.displayXOfY.value
      : null,
    isRandomized: formData.isRandomized,
    questionBlocks: formData.blocks.map((block) => {
      return {
        displayLogic: block.displayLogic.enabled
          ? getApiDataForDisplayLogicV2({
              displayLogic: block.displayLogic.values,
            })
          : [],
        countInX: block.countInX.enabled,
        end: block.end,
        start: block.start,
        title: block.title,
        id: block.id,
      };
    }),
    title: `Master Block ${survey.id}`,
  };
}

export function getEmptyQuestionBlock(): QuestionBlocksFormData['blocks'][number] {
  return {
    displayLogic: { enabled: false, values: [] },
    countInX: { enabled: true, value: true },
    end: '',
    start: '',
    title: '',
  };
}

export function getInitialQuestionBlocksFormData({
  questionBlocks,
  questions,
}: {
  questionBlocks: QuestionBlock[];
  questions: Question[];
}): QuestionBlocksFormData {
  const existingQuestionBlock = questionBlocks[0];
  const orderedBlocks = existingQuestionBlock
    ? orderBy(existingQuestionBlock?.questionBlocks, (qb) => qb.sort, ['asc'])
    : [];

  return {
    blocks: orderedBlocks.map((block) => {
      const displayLogic = apiDisplayLogicToFormDisplayLogic({
        block,
        questions,
      });

      return {
        displayLogic: {
          enabled: displayLogic.length > 0,
          values: displayLogic,
        },
        countInX: {
          enabled: block.countInX,
          value: block.countInX,
        },
        end: block.end ?? '',
        start: block.start ?? '',
        title: block.title,
        id: block.id,
      };
    }) ?? [getEmptyQuestionBlock()],
    displayXOfY: {
      enabled: !!existingQuestionBlock?.amountViewed,
      value: existingQuestionBlock?.amountViewed ?? '',
    },
    isRandomized: existingQuestionBlock?.isRandomized ?? true,
  };
}

/**
 * Returns the Idea Presenter question that represents the start of a sequential monadic block.
 */
export function getMonadicBlockPromptQuestion({
  questions,
}: {
  questions: Question[];
}) {
  const orderedQuestions = orderBy(questions, (q) => q.sort, ['asc']);
  const monadicPromptQuestion = orderedQuestions.find(
    (q) => isIdeaPresenterQuestion(q) && q.monadicId,
  );

  return monadicPromptQuestion;
}

function validateQuestionBlocksChildren(
  blocks: QuestionBlockChild[],
): FormikErrors<QuestionBlockChild>[] | undefined {
  const childrenErrors: FormikErrors<QuestionBlockChild>[] = [];

  blocks.forEach((block) => {
    const blockErrors: FormikErrors<QuestionBlockChild> = {};

    if (!block.title) {
      blockErrors.title = 'Please provide a title.';
    }

    if (block.start === '') {
      blockErrors.start = 'Please provide a starting question.';
    }

    if (block.end === '') {
      blockErrors.end = 'Please provide an ending question.';
    }

    const displayLogicErrors = validateDisplayLogic(block.displayLogic.values);
    if (displayLogicErrors) {
      blockErrors.displayLogic = { values: displayLogicErrors };
    }

    childrenErrors.push(blockErrors);
  });

  return childrenErrors.some((errors) => !isEmpty(errors))
    ? childrenErrors
    : undefined;
}

export function validateQuestionBlocksData(
  formData: QuestionBlocksFormData,
): FormikErrors<QuestionBlocksFormData> {
  const errors: FormikErrors<QuestionBlocksFormData> = {};

  errors.blocks = validateQuestionBlocksChildren(formData.blocks);

  if (formData.displayXOfY.enabled && formData.displayXOfY.value === '') {
    errors.displayXOfY = { value: 'Please provide a value.' };
  }

  return some(errors) ? errors : {};
}
