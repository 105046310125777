import { orderBy } from 'lodash-es';

import { SurveyWave } from 'types/domainModels';

export function getCurrentWaveId(waves: SurveyWave[]) {
  const orderedWaves = orderWaves(waves);

  return orderedWaves[0]?.id;
}

export function getSurveyWaveOptions({ waves }: { waves: SurveyWave[] }) {
  const orderedWaves = orderWaves(waves);
  const waveOptions = orderedWaves.map((wave) => {
    return { label: getSurveyWaveTitle(wave), value: wave.id };
  });

  return [...waveOptions, { label: 'All Waves', value: null }];
}

export function getSurveyWaveTitle(wave: SurveyWave) {
  return `Wave ${wave.waveValue}: ${wave.title}`;
}

export function orderWaves(waves: SurveyWave[]) {
  return orderBy(waves, (w) => w.waveValue, 'desc');
}
