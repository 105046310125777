import { cloneDeep, groupBy, orderBy } from 'lodash-es';
import { Fragment, useState } from 'react';
import { NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getSurveyWaveOptions } from 'util/surveyWaves';
import {
  orderQuotasByOptions,
  questionSupportsQuotas,
} from 'util/questionQuotas';
import { Question, Survey, SurveyWave } from '../../types/domainModels';
import { questionQueries } from 'hooks/backend/questions';
import { questionQuotaQueries } from 'hooks/backend/questionQuotas';
import { surveyQueries } from 'hooks/backend/surveys';
import { useHasRole } from '../../hooks/users';
import { variableQueries } from 'hooks/backend/surveyVariables';

import Button from 'components/common/forms/Button';
import ErrorDisplay from '../common/ErrorDisplay';
import ExportsTable from './ExportsTable';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Listbox, {
  ListboxButton,
  ListboxOption,
} from 'components/common/Listbox';
import QuestionAnalytics from './QuestionAnalytics';
import {
  QuotaProgress,
  QuotasProgressHeader,
  QuestionQuotasDropdown,
  Requirements,
} from './Quotas';
import SidebarNavAnalytics from './SidebarNavAnalytics';
import SurveyLaunchedSidebar from './SurveyLaunchedSidebar';
import SurveyStepStickyHeader from 'components/surveyEdit/SurveyStepStickyHeader';
import SurveySummary, { SkeletonSurveySummary } from './SurveySummary';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import TitledCard, { TitledCardHeader } from './TitledCard';
import VariableAnalytics from './VariableAnalytics';
import WordSeparator from 'components/common/WordSeparator';

export type SurveyLaunchedContext = {
  isLoadingQuestions: boolean;
  isLoadingSurvey: boolean;
  isLoadingWaves: boolean;
  questions: Question[];
  resultsWaveId: number | null | undefined;
  setSelectedWaveId(waveId: number | null): void;
  survey: Survey | undefined;
  surveyId: number;
  waveIds: number[];
  waves: SurveyWave[];
};

const SurveyLauncedPage = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const surveyId = Number(id);

  // "undefined" indicates that a user hasn't yet made any selection. A "null"
  // value indicates they selected to view the data for all the survey waves.
  const [selectedWaveId, setSelectedWaveId] = useState<
    number | null | undefined
  >();

  const {
    data: survey,
    error: loadSurveyError,
    isError: hasLoadSurveyError,
    isLoading: isLoadingSurvey,
  } = useQuery(surveyQueries.survey({ surveyId }));
  const latestSurveyWaveId = survey?.waveId;

  // Note: It's important we check for an "undefined" value since that indicates no selection was
  // made, as opposed to a "null" value, which indicates the user would like to view the
  // data for all waves.
  const resultsWaveId =
    selectedWaveId === undefined ? latestSurveyWaveId : selectedWaveId;

  const {
    data: questions = [],
    error: getQuestionsError,
    isLoadingError: hasLoadQuestionsError,
    isLoading: isLoadingQuestions,
  } = useQuery({
    ...questionQueries.forSurvey({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const {
    data: loadWavesResult,
    error: loadWavesError,
    isLoadingError: hasLoadWavesError,
    isLoading: isLoadingWaves,
  } = useQuery(surveyQueries.waves({ surveyId }));
  const allWaves = loadWavesResult?.waves ?? [];
  const waveIds = resultsWaveId
    ? [resultsWaveId]
    : allWaves.map((wave) => wave.id);

  return (
    <div className="h-full">
      {hasLoadSurveyError || hasLoadQuestionsError || hasLoadWavesError ? (
        <ErrorDisplay
          message={`Failed to load survey results. (${
            loadSurveyError?.message ??
            getQuestionsError?.message ??
            loadWavesError?.message
          })`}
        />
      ) : (
        <Outlet
          context={
            {
              isLoadingQuestions,
              isLoadingSurvey,
              isLoadingWaves,
              questions,
              resultsWaveId,
              setSelectedWaveId,
              survey,
              surveyId,
              waveIds,
              waves: allWaves,
            } satisfies SurveyLaunchedContext
          }
        />
      )}
    </div>
  );
};

export default SurveyLauncedPage;

export const SurveyAnalytics = () => {
  const {
    isLoadingQuestions,
    isLoadingSurvey,
    isLoadingWaves,
    questions,
    resultsWaveId,
    setSelectedWaveId,
    survey,
    surveyId,
    waveIds,
    waves,
  } = useOutletContext<SurveyLaunchedContext>();

  const [curQuestionId, setCurrentQuestionId] = useState<number | null>(null);
  const [curVariableId, setCurrentVariableId] = useState<number | null>(null);

  const isAdmin = useHasRole('admin');
  const queryClient = useQueryClient();

  const {
    data: variables = [],
    error: getVariablesError,
    isLoadingError: hasLoadVariablesError,
    isLoading: isLoadingVariables,
  } = useQuery({
    ...variableQueries.list({ surveyId }),
    refetchOnWindowFocus: false,
  });

  const waveOptions = getSurveyWaveOptions({ waves });
  const selectedWave = waves.find(({ id }) => id === resultsWaveId);
  const selectedWaveOption = waveOptions.find(
    ({ value }) => value === resultsWaveId,
  );

  const selectedQuestion = (questions ?? []).find(
    ({ id }) => id === curQuestionId,
  );
  const selectedVariable = variables.find(({ id }) => id === curVariableId);

  function onQuestionActivationToggled(questionId: number) {
    queryClient.setQueryData(
      questionQueries.forSurvey({ surveyId }).queryKey,
      (existingQuestions) => {
        if (!existingQuestions) {
          return;
        }

        const newQuestions = cloneDeep(existingQuestions);
        const question = existingQuestions.find(({ id }) => id === questionId);
        if (question) {
          question.isActive = !question.isActive;
        }

        return newQuestions;
      },
    );
    queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
  }

  const sidebar = survey ? (
    <div className="pt-6 space-y-6">
      {waves.length > 1 && selectedWaveOption && (
        <Listbox
          button={
            <ListboxButton as={Fragment}>
              <Button
                grow
                hierarchy="secondary-gray"
                icon={<Icon id="chevron-down" />}
                iconPlacement="trailing"
                justification="justify-between"
                size="sm"
              >
                <span className="truncate">
                  {selectedWaveOption?.label ?? 'Select wave'}
                </span>
              </Button>
            </ListboxButton>
          }
          onChange={setSelectedWaveId}
          placement="bottom-start"
          value={selectedWaveOption?.value}
        >
          {waveOptions.map((option) => {
            return (
              <ListboxOption key={option.value} value={option.value}>
                {option.label}
              </ListboxOption>
            );
          })}
        </Listbox>
      )}

      <SurveyLaunchedSidebar
        curQuestionId={curQuestionId}
        curVariableId={curVariableId}
        isLoadingSurvey={isLoadingSurvey}
        isLoadingVariables={isLoadingVariables}
        loadVariablesError={
          hasLoadVariablesError && getVariablesError instanceof Error
            ? getVariablesError
            : null
        }
        onClickQuestion={(questionId) => {
          setCurrentQuestionId(questionId);
          setCurrentVariableId(null);
        }}
        onClickSurveySummary={() => {
          setCurrentQuestionId(null);
          setCurrentVariableId(null);
        }}
        onClickVariable={(variableId) => {
          setCurrentQuestionId(null);
          setCurrentVariableId(variableId);
        }}
        onQuestionActivationToggled={onQuestionActivationToggled}
        questions={questions}
        survey={survey}
        variables={variables}
      />
    </div>
  ) : (
    <div />
  );

  return (
    <SurveyWithSidebar
      sidebarNav={<SidebarNavAnalytics surveyId={surveyId} />}
      sidebarWorkspace={sidebar}
    >
      <div>
        {isLoadingQuestions || isLoadingSurvey || isLoadingWaves ? (
          <SkeletonSurveySummary />
        ) : (
          survey && (
            <>
              {selectedQuestion && (
                <div>
                  <SurveyStepStickyHeader>
                    <h2 className="flex items-center space-x-2 text-base font-semibold">
                      <span>{selectedQuestion.title}</span>
                      {isAdmin && (
                        <NavLink
                          to={`/surveys/${surveyId}/build/questions/${selectedQuestion.id}`}
                        >
                          <IconBackground tooltip="Edit Question">
                            <div className="w-3 h-3">
                              <Icon id="pencil" />
                            </div>
                          </IconBackground>
                        </NavLink>
                      )}
                    </h2>
                  </SurveyStepStickyHeader>

                  <div className="w-210 space-y-4">
                    <QuestionAnalytics
                      questionId={selectedQuestion.id}
                      waveIds={waveIds}
                    />
                    <QuestionQuotas
                      question={selectedQuestion}
                      survey={survey}
                      waveIds={waveIds}
                    />
                  </div>
                </div>
              )}

              {selectedVariable && (
                <div>
                  <SurveyStepStickyHeader>
                    <h2 className="flex items-center space-x-2 text-base font-semibold">
                      <span>{selectedVariable.title}</span>
                      {isAdmin && (
                        <NavLink
                          to={`/surveys/${surveyId}/build/variables/${selectedVariable.id}`}
                        >
                          <IconBackground tooltip="Edit Variable">
                            <div className="w-3 h-3">
                              <Icon id="pencil" />
                            </div>
                          </IconBackground>
                        </NavLink>
                      )}
                    </h2>
                  </SurveyStepStickyHeader>

                  <div className="w-210">
                    <VariableAnalytics
                      survey={survey}
                      variable={selectedVariable}
                      variableId={selectedVariable.id}
                      waveIds={waveIds}
                    />
                  </div>
                </div>
              )}

              {!selectedQuestion && !selectedVariable && (
                <div className="w-210">
                  <SurveySummary
                    questions={questions}
                    selectedWave={selectedWave}
                    survey={survey}
                    surveyVariables={variables}
                    viewingWaveId={selectedWaveOption?.value}
                    waveIds={waveIds}
                    waves={waves}
                  />
                </div>
              )}
            </>
          )
        )}
      </div>
    </SurveyWithSidebar>
  );
};

const QuestionQuotas = ({
  question,
  survey,
  waveIds,
}: {
  question: Question;
  survey: Survey;
  waveIds: number[];
}) => {
  const isAdmin = useHasRole('admin');
  const surveyId = survey.id;

  const { data: questionQuotas = [] } = useQuery(
    questionQuotaQueries.forSurvey({ surveyId, waveIds }),
  );
  const questionQuotasByQuestionId = groupBy(
    questionQuotas,
    (quota) => quota.question.id,
  );

  const quotasForQuestion = orderQuotasByOptions(
    questionQuotasByQuestionId[question.id] ?? [],
  );

  // Non-admins can't add question quotas to a live survey.
  if (
    (!isAdmin && quotasForQuestion.length === 0) ||
    !questionSupportsQuotas(question)
  ) {
    return null;
  }

  return (
    <TitledCard
      header={
        <TitledCardHeader
          rightContent={
            isAdmin ? (
              <QuestionQuotasDropdown
                numQuotas={quotasForQuestion.length}
                question={question}
                waveIds={waveIds}
              />
            ) : undefined
          }
        >
          Question Quotas
        </TitledCardHeader>
      }
    >
      {/*
       * We only show this messaging for admins since non-admins can't edit the
       * question quotas for a live survey.
       */}
      {isAdmin && quotasForQuestion.length === 0 && (
        <p className="p-4">No quotas</p>
      )}

      {quotasForQuestion.length > 0 && (
        <div className="p-4">
          <div className="mb-2">
            <QuotasProgressHeader titleFirstColumn="Option(s)" />
          </div>

          {quotasForQuestion.map((quota, quotaIdx) => {
            return (
              <div key={quota.id}>
                <QuotaProgress
                  firstColumnContent={
                    <Requirements
                      logic="should"
                      requirements={orderBy(quota.options, (o) => o.sort).map(
                        (o) => o.title,
                      )}
                    />
                  }
                  numCompletes={quota.count}
                  numNeeded={quota.numberNeeded}
                  type={quota.logicalModifier}
                />
                {quotaIdx !== quotasForQuestion.length - 1 && (
                  <WordSeparator word="and" />
                )}
              </div>
            );
          })}
        </div>
      )}
    </TitledCard>
  );
};

export const SurveyExports = () => {
  const { survey, surveyId } = useOutletContext<SurveyLaunchedContext>();

  return (
    <SurveyWithSidebar sidebarNav={<SidebarNavAnalytics surveyId={surveyId} />}>
      <div className="pt-6">
        <div className="overflow-x-clip">
          <div className="min-w-[800px]">
            {survey ? (
              <ExportsTable
                surveyHash={survey.hash}
                surveyTitle={survey.title}
              />
            ) : null}
          </div>
        </div>
      </div>
    </SurveyWithSidebar>
  );
};
