import { clsx } from 'clsx';
import { NavLink, useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash-es';
import { useEffect } from 'react';

import { Survey, SurveyWave } from 'types/domainModels';

import IndexCard from './IndexCard';

const WavesSidebar = ({
  activeWaveId,
  survey,
  waves,
}: {
  activeWaveId: number | undefined;
  survey: Survey;
  waves: SurveyWave[];
}) => {
  const navigate = useNavigate();
  const surveyId = survey.id;

  const orderedWaves = orderBy(waves, (w) => w.waveValue, 'asc');
  const mostRecentWaveId = orderedWaves[orderedWaves.length - 1]?.id;

  // Only "Completed" surveys can have new waves added. We don't want multiple
  // live waves running at the same time.
  const canAddNewWave = survey.statusId === 7;
  const shouldNavToMostRecentWave = !canAddNewWave && !activeWaveId;

  useEffect(() => {
    if (shouldNavToMostRecentWave) {
      navigate(`/surveys/${surveyId}/build/waves/${mostRecentWaveId}`);
    }
  }, [navigate, shouldNavToMostRecentWave, mostRecentWaveId, surveyId]);

  return (
    <div>
      <div className="text-gray-900 flex justify-between items-center pb-5 pt-6 sticky top-0 z-10 bg-white">
        <span>Survey Waves</span>
      </div>
      <div className="space-y-6">
        <IndexCard>
          <div>
            {orderedWaves.map((wave) => {
              return (
                <NavLink
                  key={wave.id}
                  className={({ isActive }) =>
                    clsx('min-h-10 flex items-center text-gray-900 px-4 py-2', {
                      'bg-mint': isActive,
                      'hover:bg-gray-300': !isActive,
                    })
                  }
                  to={`/surveys/${surveyId}/build/waves/${wave.id}`}
                >
                  {wave.title}
                </NavLink>
              );
            })}
          </div>

          {canAddNewWave ? (
            <div className="px-4 py-2 text-xs text-gray-600 flex items-center justify-between">
              <NavLink
                className="hover:bg-gray-300 rounded px-1"
                to={`/surveys/${surveyId}/build/waves`}
              >
                New Wave
              </NavLink>
            </div>
          ) : null}
        </IndexCard>
      </div>
    </div>
  );
};

export default WavesSidebar;
