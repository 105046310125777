import { Form, Formik, FormikErrors, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { CreateSurveyBody } from '../../services/backend/surveys';
import { createTeamMemberOption } from '../../util/team';
import { getIncidenceTypeOptions } from '../../util/incidence';
import { getNestedErrorMessages } from 'util/forms';
import { getOrgStatus } from '../../util/users';
import {
  IncidenceType,
  Question,
  Survey,
  SurveyWave,
  Tag,
  TeamMember,
} from '../../types/domainModels';
import { incidenceTypeQueries } from 'hooks/backend/incidenceTypes';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage } from '../../util/notifications';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { useCreateTag, useTags } from 'hooks/backend/tags';
import { useHasRole } from '../../hooks/users';
import { useOrderedOrganizations } from '../../hooks/backend/organizations';
import { useOrderedTeamMembers } from '../../hooks/backend/team';
import { useSaveSurvey } from 'hooks/backend/surveys';
import { useSubmitValidation } from 'hooks/forms';

import ButtonLoading from '../common/forms/ButtonLoading';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import PutSurveyIntoDraftDialog from 'components/common/PutSurveyIntoDraftDialog';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

type OverviewFormProps = {
  incidenceTypes: ReactSelectValue<IncidenceType>[];
  isAdmin: boolean;
  isSavingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onOverviewDirtyChanged(isDirty: boolean): void;
  organizations: ReactSelectValue<number>[];
  questions: Question[];
  waves: SurveyWave[];
};

interface SurveyOverviewFormData {
  contact: ReactSelectValue<TeamMember> | null;
  incidenceType: ReactSelectValue<IncidenceType> | null;
  organizationId: ReactSelectValue<number> | null;
  respondents: number | '';
  tag: ReactSelectValue<Tag> | null;
  title: string;
}

interface SurveyOverviewFormDataValidated {
  contact: ReactSelectValue<TeamMember> | null;
  incidenceType: ReactSelectValue<IncidenceType>;
  organizationId: ReactSelectValue<number>;
  respondents: number;
  tag: ReactSelectValue<Tag> | null;
  title: string;
}

const OverviewStep = ({
  isShowingUnsavedChanges,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onOverviewDirtyChanged,
  onOverviewSaved,
  questions,
  survey,
  waves,
}: {
  isShowingUnsavedChanges: boolean;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onOverviewDirtyChanged(isDirty: boolean): void;
  onOverviewSaved(): void;
  questions: Question[];
  survey: Survey;
  waves: SurveyWave[];
}) => {
  const isAdmin = useHasRole('admin');

  const { currentOrganizationId } = getOrgStatus();

  const { data: incidenceTypes = [], isLoading: isLoadingIncidenceTypes } =
    useQuery(incidenceTypeQueries.list);

  const { isLoadingTags, tags } = useTags({
    organizationId: survey.organizationId,
  });
  const initialTag = tags.find(({ id }) => id === survey.projectId);

  const { isLoadingOrgs, organizations } = useOrderedOrganizations();
  const initialSurveyOrg = organizations.find(({ value }) => {
    return value === currentOrganizationId;
  });

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId: survey.organizationId,
  });
  const initialContact = orderedTeamMembers.find(
    ({ id }) => id === survey.userId,
  );

  const isLoading =
    isLoadingIncidenceTypes ||
    isLoadingTags ||
    isLoadingTeamMembers ||
    isLoadingOrgs;

  return isLoading ? (
    <SkeletonSurveyCard />
  ) : (
    <OverviewStepLoaded
      incidenceTypes={getIncidenceTypeOptions(incidenceTypes)}
      initialContact={initialContact}
      initialSurveyOrg={initialSurveyOrg}
      initialTag={initialTag}
      isAdmin={isAdmin}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasError}
      onOverviewDirtyChanged={onOverviewDirtyChanged}
      onOverviewSaved={onOverviewSaved}
      organizations={organizations}
      questions={questions}
      survey={survey}
      waves={waves}
    />
  );
};

export default OverviewStep;

const OverviewStepLoaded = ({
  incidenceTypes,
  initialContact,
  initialSurveyOrg,
  initialTag,
  isAdmin,
  onHasError,
  onOverviewSaved,
  organizations,
  survey,
  ...rest
}: Omit<OverviewFormProps, 'isSavingSurvey'> & {
  initialContact: TeamMember | undefined;
  initialSurveyOrg: ReactSelectValue<number> | undefined;
  initialTag: Tag | undefined;
  onOverviewSaved(): void;
  survey: Survey;
}) => {
  const [submitStep, setSubmitStep] = useState<
    'editing' | 'needsConfirmation' | 'readyToSubmit'
  >('editing');

  const { isPending: isSavingSurvey, mutateAsync: saveSurvey } = useSaveSurvey({
    onError: (err) => {
      onHasError();
      showErrorMessage({ err });
    },
    onSuccess: () => {
      setSubmitStep('editing');
      onOverviewSaved();
    },
  });

  const initialValues = apiDataToFormData({
    incidenceTypes,
    initialContact,
    initialSurveyOrg,
    initialTag,
    organizations,
    survey,
  });

  return (
    <Formik<SurveyOverviewFormData>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(formData) => {
        if (
          survey.statusId === SURVEY_STATUSES.LIVE.id &&
          submitStep === 'editing'
        ) {
          setSubmitStep('needsConfirmation');
          return;
        }

        const data = formDataToApiData({
          formData: formData as SurveyOverviewFormDataValidated,
        });

        return saveSurvey({ data, surveyId: survey.id });
      }}
      validate={(formData) => {
        return validateOverviewData(formData, { isAdmin });
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full" id="overview">
        <OverviewForm
          {...rest}
          incidenceTypes={incidenceTypes}
          isAdmin={isAdmin}
          isSavingSurvey={isSavingSurvey}
          onHasError={onHasError}
          organizations={organizations}
        />

        {submitStep === 'needsConfirmation' ? (
          <PutSurveyIntoDraftDialog
            formId="overview"
            isSaving={isSavingSurvey}
            onCloseModal={() => {
              setSubmitStep('editing');
            }}
          />
        ) : null}
      </Form>
    </Formik>
  );
};

const OverviewForm = ({
  incidenceTypes,
  isAdmin,
  isSavingSurvey,
  isShowingUnsavedChanges,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onOverviewDirtyChanged,
  organizations,
  waves,
}: OverviewFormProps) => {
  const { dirty } = useFormikContext();

  const [{ value: organizationIdSelect }] =
    useField<SurveyOverviewFormData['organizationId']>('organizationId');
  const [, , setContactHelpers] =
    useField<SurveyOverviewFormData['contact']>('contact');
  const [, , setTagHelpers] = useField<SurveyOverviewFormData['tag']>('tag');

  const organizationId = organizationIdSelect?.value;

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });

  // We only want to display survey contact options to the admin users if they have already selected the
  // organization to which the survey will belong.
  const teamMembers =
    isAdmin && !!organizationId
      ? orderedTeamMembers.map((teamMember) => {
          return createTeamMemberOption(teamMember);
        })
      : [];

  const { errors, onClickSubmit, validateAndSubmit } = useSubmitValidation({
    isSaving: isSavingSurvey,
    onHasError,
  });

  useEffect(() => {
    onOverviewDirtyChanged(dirty);
  }, [dirty, onOverviewDirtyChanged]);

  const gridClassess = 'grid grid-cols-[3fr_4fr]';

  return (
    <div>
      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isSavingSurvey}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={validateAndSubmit}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      <div className="space-y-4">
        <SurveyStepStickyHeader>
          <h2 className="text-base font-semibold">Overview</h2>

          <ButtonLoading
            disabled={!dirty}
            hierarchy="primary"
            isLoading={isSavingSurvey}
            onClick={onClickSubmit}
            size="sm"
            // This can't currently be a submit button since we handle the form submission
            // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
            type="button"
          >
            Save Changes
          </ButtonLoading>
        </SurveyStepStickyHeader>

        {errors && (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
          </div>
        )}

        <div className={gridClassess}>
          <div>Survey Title</div>
          <FormInput
            id="title"
            labelFor="title"
            name="title"
            placeholder=" ex: Package testing study"
            size="md"
            type="text"
          />
        </div>

        {/*
         * If there's more than one wave, the number of respondents is added to the next wave,
         * not the survey. For a single wave survey it's here, because it's more intuitive if
         * you only ever have a single wave.
         */}
        {waves.length === 1 ? (
          <div className={gridClassess}>
            <div>Number of Respondents</div>
            <FormInput
              id="respondents"
              labelFor="respondents"
              name="respondents"
              placeholder="1000"
              size="md"
              type="number"
            />
          </div>
        ) : null}

        <div className={gridClassess}>
          <div>Estimated Incidence</div>
          <FormSearchSelectInput
            borderColor="#D9D9D9"
            inputId="incidenceType"
            labelFor="incidenceType"
            name="incidenceType"
            options={incidenceTypes}
          />
        </div>
        <div className={gridClassess}>
          <div>Tags (optional)</div>
          <TagSelect />
        </div>
        {organizations.length > 1 && (
          <div className={gridClassess}>
            <div>Organization</div>
            <FormSearchSelectInput
              borderColor="#D9D9D9"
              inputId="organization"
              labelFor="organization"
              name="organizationId"
              onChange={() => {
                // If an organization is changed, we load new options for the survey contact and tag so we want to clear out
                // the existing ones and make the user select an updated value.
                setContactHelpers.setValue(null);
                setTagHelpers.setValue(null);
              }}
              options={organizations}
            />
          </div>
        )}

        {isAdmin && (
          <>
            <h2 className="text-base font-semibold">Admin Settings</h2>
            <div className={gridClassess}>
              <div>Survey Contact</div>
              <FormSearchSelectInput
                borderColor="#D9D9D9"
                inputId="contact"
                isDisabled={isLoadingTeamMembers}
                isLoading={isLoadingTeamMembers}
                labelFor="contact"
                name="contact"
                options={teamMembers}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const TagSelect = () => {
  // The organization might be a form field if this is an admin filling out a survey for a different organization -
  // otherwise it will be the organization for the user.
  const [{ value: organizationIdSelect }] =
    useField<SurveyOverviewFormData['organizationId']>('organizationId');
  const organizationId = organizationIdSelect?.value;

  const [, , tagHelpers] = useField<SurveyOverviewFormData['tag']>('tag');

  const { isLoadingTags, onTagCreated, tags } = useTags({ organizationId });
  const tagOptions = tags.map((tag) => {
    return createTagOption(tag);
  });

  const { isPending: isCreatingTag, mutate: createTag } = useCreateTag({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: async (data) => {
      await onTagCreated();
      tagHelpers.setValue(createTagOption(data));
    },
  });

  return (
    <FormSearchSelectInput
      borderColor="#D9D9D9"
      inputId="tag"
      isCreatable={true}
      isDisabled={isLoadingTags || isCreatingTag}
      isLoading={isLoadingTags || isCreatingTag}
      labelFor="tag"
      name="tag"
      onCreateOption={(newTagName: string) => {
        if (!organizationId) {
          throw new Error(
            'Could not determine your organization. Please log out and log back in.',
          );
        }

        return createTag({ data: { organizationId, title: newTagName } });
      }}
      options={tagOptions}
    />
  );
};

function apiDataToFormData({
  incidenceTypes,
  initialContact,
  initialSurveyOrg,
  initialTag,
  organizations,
  survey,
}: {
  incidenceTypes: ReactSelectValue<IncidenceType>[];
  initialContact: TeamMember | undefined;
  initialSurveyOrg: ReactSelectValue<number> | undefined;
  initialTag: Tag | undefined;
  organizations: ReactSelectValue<number>[];
  survey: Survey;
}): SurveyOverviewFormData {
  // We could have an initial organization if the user is modifying the details of an existing survey
  // or if this is an admin that has changed organizations and is creating a new survey for that
  // organization.
  let organization = initialSurveyOrg;
  if (survey.organizationId) {
    organization = organizations.find(({ value }) => {
      return value === survey.organizationId;
    });
  }

  return {
    contact: initialContact ? createTeamMemberOption(initialContact) : null,
    incidenceType:
      incidenceTypes.find(({ value }) => {
        return value.id === survey.incidenceTypeId;
      }) ?? null,
    organizationId: organization ?? null,
    respondents: survey.participants,
    tag: initialTag ? createTagOption(initialTag) : null,
    title: survey.title,
  };
}

function createTagOption(tag: Tag): ReactSelectValue<Tag> {
  return {
    label: tag.title,
    value: tag,
  };
}

function formDataToApiData({
  formData,
}: {
  formData: SurveyOverviewFormDataValidated;
}): CreateSurveyBody {
  const { contact, incidenceType, organizationId, respondents, tag, title } =
    formData;

  return {
    incidenceTypeId: incidenceType.value.id,
    organizationId: organizationId?.value,
    ownerId: contact?.value.id,
    participants: respondents,
    projectId: tag?.value.id ?? null,
    // Currently any edit to the survey details will cause the survey to be put back into
    // "Draft" status. This is due to the fact that changing some information could cause
    // a price change. In the future, this should be done on the backend and should only happen
    // if price-related properties are edited.
    statusId: 6,
    title,
  };
}

function validateOverviewData(
  formData: SurveyOverviewFormData,
  { isAdmin = false } = {},
): FormikErrors<SurveyOverviewFormData> {
  const errors: FormikErrors<SurveyOverviewFormData> = {};

  if (!formData.incidenceType) {
    errors.incidenceType = 'Please select an incidence type.';
  }

  if (!formData.organizationId) {
    errors.organizationId = 'Please select an organization.';
  }

  if (!formData.contact && isAdmin) {
    errors.contact = 'Please select a survey contact.';
  }

  if (!formData.respondents) {
    errors.respondents = 'Please enter the number of respondents.';
  }

  if (!formData.title) {
    errors.title = 'Please enter a title.';
  }

  return errors;
}
