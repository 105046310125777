import { orderBy } from 'lodash-es';
import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchTeamMembers,
  inviteTeamMember,
  removeTeamMember,
} from 'services/backend/team';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { useCurrentOrganization } from 'hooks/backend/organizations';

export const teamQueries = {
  all: () => ['team'],
  list: (opts: { organizationId: number | undefined }) =>
    queryOptions({
      enabled: !!opts.organizationId,
      queryFn: () => {
        if (!opts.organizationId) {
          throw new Error(
            'Could not determine organization to load team members. Please log out and log back in again.',
          );
        }

        return fetchTeamMembers({ organizationId: opts.organizationId });
      },
      queryKey: [...teamQueries.all(), opts.organizationId],
      staleTime: minutesToMilliseconds(5),
    }),
};

export function useInviteTeamMember(
  opts?: ReactQueryFunctionCallbacks<typeof inviteTeamMember>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: inviteTeamMember,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: teamQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useOrderedTeamMembers({
  enabled = true,
  organizationId = undefined,
}: {
  enabled?: boolean;
  organizationId?: number | null;
} = {}) {
  const userOrgId = useCurrentOrganization()?.id;
  const organizationIdToUse = organizationId || userOrgId;

  const {
    data,
    error: loadTeamMembersError,
    isError: hasLoadTeamMembersError,
    isLoading: isLoadingTeamMembers,
  } = useQuery({
    ...teamQueries.list({ organizationId: organizationIdToUse }),
    enabled: enabled && !!organizationIdToUse,
  });
  const teamMembers = data?.results ?? [];
  const orderedTeamMembers = orderBy(
    teamMembers,
    [
      ({ firstName }) => firstName?.toLowerCase() || '',
      ({ lastName }) => lastName?.toLowerCase() || '',
    ],
    'asc',
  );

  return {
    hasLoadTeamMembersError,
    isLoadingTeamMembers,
    loadTeamMembersError,
    orderedTeamMembers,
  };
}

export function useRemoveTeamMember(
  opts?: ReactQueryFunctionCallbacks<typeof removeTeamMember>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: removeTeamMember,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: teamQueries.list({ organizationId: args[1].organizationId })
          .queryKey,
      });

      opts?.onSuccess?.(...args);
    },
  });
}
