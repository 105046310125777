import { ReactNode } from 'react';

const SurveyStepStickyHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex justify-between items-end mb-5 sticky top-0 bg-white z-20 pt-6 border-b border-gray-d-200 pb-2">
      {children}
    </div>
  );
};

export default SurveyStepStickyHeader;
