import { ElementRef, useRef, useState } from 'react';

import { showErrorMessage, showSuccessMessage } from 'util/notifications';
import { useDeleteSurvey } from 'hooks/backend/surveys';

import Button from '../common/forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import FormGroup from '../common/forms/FormGroup';
import Input from '../common/forms/Input';
import Modal, { ModalHeader } from '../common/Modal';

const DeleteSurveyVerificationModal = ({
  onCloseModal,
  onSurveyDeleted,
  surveyID,
  title,
}: {
  onCloseModal(): void;
  onSurveyDeleted(surveyID: number): void;
  surveyID: number;
  title: string;
}): JSX.Element => {
  const confirmationField = useRef<ElementRef<'input'>>(null);

  const [confirmationSurvey, setConfirmationSurvey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { isPending: isDeletingSurvey, mutate: deleteSurvey } = useDeleteSurvey(
    {
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: () => {
        showSuccessMessage('The survey was successfully deleted.');
        onSurveyDeleted(surveyID);
      },
    },
  );

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Are you sure?</ModalHeader>
      }
      initialFocus={confirmationField}
      onCloseModal={onCloseModal}
      position="top"
    >
      <p className="mb-4">
        You are about to delete survey{' '}
        <strong className="font-bold">{title}</strong>. Are you sure you wish to
        do this? Please type <strong className="font-bold">delete</strong> in
        the field below to confirm.
      </p>

      <form
        onSubmit={(event) => {
          event.preventDefault();

          if (confirmationSurvey.trim().toLowerCase() === 'delete') {
            deleteSurvey({ surveyId: surveyID });
          } else {
            setErrorMessage(
              'Please type the word "delete" to confirm deletion of the survey.',
            );
          }
        }}
      >
        <FormGroup error={errorMessage}>
          <Input
            ref={confirmationField}
            onChange={(event) => {
              setConfirmationSurvey(event.target.value);
            }}
            size="lg"
            type="text"
            value={confirmationSurvey}
          />
        </FormGroup>

        <div className="mt-8 flex gap-3 flex-row-reverse">
          <ButtonLoading
            grow
            hierarchy="destructive"
            isLoading={isDeletingSurvey}
            size="lg"
            type="submit"
          >
            Delete
          </ButtonLoading>
          <Button
            grow
            hierarchy="secondary-gray"
            onClick={onCloseModal}
            size="lg"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteSurveyVerificationModal;
