import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeOptions = ({ isScale = false }: { isScale?: boolean }) => {
  return (
    <FormCheckbox
      checkboxLabel={isScale ? 'Randomize Scales' : 'Randomize Options'}
      name="features.randomizeOptions"
    />
  );
};

export default RandomizeOptions;
