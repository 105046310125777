import axios from 'axios';

import { getAPIUrl } from './baseAPI';

export const VALIDATE_RESPONDENT_IDS = () => ({
  path: `/respondents:validateIds`,
  version: 'v2' as const,
});

export async function validateRespondentIds({
  data,
}: {
  data: { respondentIds: number[] };
}) {
  return (await axios.post<void>(getAPIUrl(VALIDATE_RESPONDENT_IDS()), data))
    .data;
}
