import { clsx } from 'clsx';
import { useField } from 'formik';

import { getEmptyQuota, hasConfiguredQuotas } from '../../../util/questions';
import { QuestionFormData } from '../../../types/forms';

import { useModal } from '../../../hooks/modals';
import ExclamationCircleIcon from '../../common/icons/ExclamationCircleIcon';
import FormCheckbox from '../../common/forms/FormCheckbox';
import Icon from 'components/common/Icon';
import IconBackground from '../../common/icons/IconBackground';
import QuotasEditModal from '../QuotasEditModal';

type QuotaFeature = QuestionFormData['features']['quotas'];
type QuotaValues = QuotaFeature['values'];

const Quotas = () => {
  const [{ value: quotas }, quotasMeta, quotasHelpers] = useField<QuotaValues>(
    'features.quotas.values',
  );
  const [{ value: quotasEnabled }] = useField<QuotaFeature['enabled']>(
    'features.quotas.enabled',
  );

  const {
    isOpen: isQuotasModalOpen,
    onCloseModal,
    setIsOpen: setIsQuotasModalOpen,
  } = useModal();

  const hasQuotas = hasConfiguredQuotas(quotas);
  const hasQuotasError = !!quotasMeta.error;

  function openQuotasModal() {
    if (quotas.length === 0) {
      quotasHelpers.setValue([getEmptyQuota()]);
    }

    setIsQuotasModalOpen(true);
  }

  return (
    <div>
      <FormCheckbox
        checkboxLabel={
          <div className="flex items-center">
            <span
              className={clsx({
                'text-red font-bold': hasQuotasError,
              })}
            >
              Quotas
            </span>
            {quotasEnabled && (hasQuotas || hasQuotasError) && (
              <div className="flex items-center ml-2 space-x-2">
                <div>
                  <IconBackground
                    onClick={(event) => {
                      // This event manipulation is to prevent the unchecking of the checkbox when this
                      // icon is clicked.
                      event.preventDefault();
                      event.stopPropagation();

                      openQuotasModal();
                    }}
                    title="Edit"
                  >
                    <div className="w-3 h-3">
                      <Icon id="pencil" />
                    </div>
                  </IconBackground>
                </div>
                {hasQuotasError && (
                  <div className="w-6 h-6 text-red">
                    <ExclamationCircleIcon />
                  </div>
                )}
              </div>
            )}
          </div>
        }
        name="features.quotas.enabled"
        onChange={(value) => {
          if (value) {
            openQuotasModal();
          }
        }}
      />
      {quotasEnabled && (
        <>
          {hasQuotasError && (
            <p className="mt-2 text-sm">
              The quotas are misconfigured. Please adjust or remove.
            </p>
          )}
          {hasQuotas && (
            <div className="mt-2 pl-4">
              <QuotasDisplay quotas={quotas} />
            </div>
          )}
          {isQuotasModalOpen && <QuotasEditModal onCloseModal={onCloseModal} />}
        </>
      )}
    </div>
  );
};

export default Quotas;

const QuotasDisplay = ({ quotas }: { quotas: QuotaValues }): JSX.Element => {
  return (
    <ul className="list-disc pl-8 text-sm">
      {quotas.map((quota, i) => {
        const modifierValue = quota.logicalModifier?.value;

        return (
          <li key={i}>
            <strong className="font-semibold">
              {quota.logicalModifier?.label}
            </strong>{' '}
            {modifierValue === 'at_least' || modifierValue === 'at_most'
              ? quota.numberNeeded
              : ''}{' '}
            responses for options
            {quota.isQualityDisqualification
              ? ' (quality disqualification)'
              : ''}
            :
            <ul className="list-circle pl-8">
              {quota.options.map((option, i) => {
                return <li key={i}>{option.label}</li>;
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
