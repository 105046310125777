import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { queryStringify } from 'util/api';
import { QuestionQuotaResult } from 'types/domainModels';

export const FETCH_QUESTION_QUOTAS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/question-quota-results`,
  version: 'v1' as const,
});

export async function fetchQuestionQuotas({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds: number[];
}) {
  return (
    await axios.get<QuestionQuotaResult[]>(
      getAPIUrl(FETCH_QUESTION_QUOTAS({ surveyId })),
      {
        params: { waveIds: waveIds.length > 0 ? waveIds : undefined },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}

export const REMOVE_QUESTION_QUOTAS = ({
  questionId,
}: {
  questionId: number;
}) => ({
  path: `/questions/${questionId}/quotas`,
  version: 'v1' as const,
});

export async function removeQuestionQuotas({
  questionId,
  waveIds,
}: {
  questionId: number;
  waveIds: number[];
}) {
  return (
    await axios.delete<void>(
      getAPIUrl(REMOVE_QUESTION_QUOTAS({ questionId })),
      {
        params: { waveIds: waveIds.length > 0 ? waveIds : undefined },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}
