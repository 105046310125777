import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { UpdatePasswordSchema } from 'util/auth';
import { useSetPassword } from 'hooks/backend/users';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Card from '../common/Card';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroupNew';
import FormLabel from 'components/common/forms/FormLabel';
import Input from 'components/common/forms/Input';
import UnauthenticatedLayout from '../layout/UnauthenticatedLayout';

interface ResetPasswordFormData {
  password: string;
  retypepassword: string;
}

const ResetPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { token: activationCode = '' } = useParams<{ token: string }>();

  const { isPending, mutate: setPassword } = useSetPassword({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: () => {
      showSuccessMessage('Successfully changed password!');
      navigate('/login');
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResetPasswordFormData>({
    defaultValues: { password: '', retypepassword: '' },
    resolver: yupResolver(UpdatePasswordSchema),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormData> = (data) => {
    return setPassword({ activationCode, data });
  };

  return (
    <UnauthenticatedLayout introText="Please reset your password using the form below.">
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <FormGroup>
              <FormLabel labelFor="password">New Password</FormLabel>
              <Input
                {...register('password')}
                id="password"
                size="lg"
                type="password"
              />
              {errors.password?.message && (
                <FormFieldError error={errors.password.message} />
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel labelFor="retypepassword">Confirm Password</FormLabel>
              <Input
                {...register('retypepassword')}
                id="retypepassword"
                size="lg"
                type="password"
              />
              {errors.retypepassword?.message && (
                <FormFieldError error={errors.retypepassword.message} />
              )}
            </FormGroup>
          </div>
          <div className="flex justify-end mt-6">
            <ButtonLoading
              grow
              hierarchy="primary"
              isLoading={isPending}
              size="lg"
              type="submit"
            >
              Set New Password
            </ButtonLoading>
          </div>
        </form>
      </Card>
    </UnauthenticatedLayout>
  );
};

export default ResetPasswordPage;
