import { getEligibleQuestions } from '../../../util/displayLogic';
import {
  getPreviousQuestions,
  getQuestionNumber,
} from '../../../util/questions';
import { getQuestionOptions } from '../../../util/formOptions';
import { Question } from '../../../types/domainModels';

import DisplayLogicCheckbox from '../DisplayLogicCheckbox';

const DisplayLogic = ({
  question,
  questions,
}: {
  question: Question | undefined;
  questions: Question[];
}) => {
  const isDisabledFirstQuestion = !question?.isDemographic
    ? false
    : getQuestionNumber({ question, questions }) < 2;

  return (
    <DisplayLogicCheckbox
      disabledTooltip={
        isDisabledFirstQuestion ? (
          <p className="font-bold">
            Disabled: Cannot add display logic to the first question.
          </p>
        ) : undefined
      }
      fieldPrefix="features.displayLogic"
      isDisabled={isDisabledFirstQuestion}
      isWithinMonadicLoop={!!question?.monadicId}
      questionOptions={getQuestionOptions({
        questions: getPreviousQuestions({
          question,
          questions: getEligibleQuestions({ questions }),
        }),
      })}
    />
  );
};

export default DisplayLogic;
