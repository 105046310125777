import { getReferencesForResource } from 'util/references';
import {
  Question,
  QuestionBlock,
  SurveyVariable,
} from '../../types/domainModels';

import Button from '../common/forms/Button';
import Modal, { ModalHeader } from '../common/Modal';

const PreventDeleteResourceModal = ({
  onCloseModal,
  questionBlocks,
  questions,
  resource,
  resourceTitle,
  variables,
}: {
  onCloseModal(): void;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  resource: {
    id: number;
    type: 'concept' | 'option' | 'matrixOption' | 'question';
  };
  resourceTitle: string;
  variables: SurveyVariable[];
}) => {
  const { questionBlockReferences, questionReferences, variableReferences } =
    getReferencesForResource({
      questionBlocks,
      questions,
      resource,
      variables,
    });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Remove {resourceTitle}
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <div className="text-sm">
        <p>
          This {resourceTitle.toLowerCase()} cannot be deleted or disabled
          because it is used in configuration for other questions or variables.
          Please adjust the following questions before continuing.
        </p>
        <div className="divide-y divide-gray-d-200">
          {questionReferences.length > 0 && (
            <div className="py-4">
              <h2 className="mb-1 font-semibold">Questions</h2>
              <ul className="list-disc pl-8">
                {questionReferences.map(({ title, references }) => {
                  return (
                    <li key={title}>
                      {title}
                      <ul className="list-disc pl-8">
                        {references.map((reference) => {
                          if (reference.type === 'carryForward') {
                            return <li key={reference.type}>Carry Forward</li>;
                          } else if (reference.type === 'displayLogic') {
                            return <li key={reference.type}>Display Logic</li>;
                          } else if (reference.type === 'pipeConcept') {
                            return <li key={reference.type}>Pipe Concept</li>;
                          } else if (reference.type === 'piping') {
                            return <li key={reference.type}>Answer Piping</li>;
                          } else if (reference.type === 'displayLogicOptions') {
                            return (
                              <li key={reference.type}>
                                Option Display Logic
                                <ul className="list-circle pl-8">
                                  {reference.resourceTitles.map((option) => {
                                    return <li key={option}>{option}</li>;
                                  })}
                                </ul>
                              </li>
                            );
                          } else if (
                            reference.type === 'displayLogicConcepts'
                          ) {
                            return (
                              <li key={reference.type}>
                                Concept Display Logic
                                <ul className="list-circle pl-8">
                                  {reference.resourceTitles.map((concept) => {
                                    return <li key={concept}>{concept}</li>;
                                  })}
                                </ul>
                              </li>
                            );
                          }

                          return null;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {questionBlockReferences.length > 0 && (
            <div className="py-4">
              <h2 className="mb-1 font-semibold">Question Blocks</h2>
              <ul className="list-disc pl-8">
                {questionBlockReferences.map(({ title, references }) => {
                  return (
                    <li key={title}>
                      {title}
                      <ul className="list-disc pl-8">
                        {references.map((reference) => {
                          if (reference.type === 'displayLogicBlocks') {
                            return 'Display Logic';
                          }

                          return null;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {variableReferences.length > 0 && (
            <div className="pt-4">
              <h2 className="mb-1 font-semibold">Variables</h2>
              <ul className="list-disc pl-8">
                {variableReferences.map(({ title, references }) => {
                  return (
                    <li key={title}>
                      {title}
                      <ul className="list-disc pl-8">
                        {references.map((reference) => {
                          if (reference.type === 'variableSegments') {
                            return reference.resourceTitles.map((segment) => {
                              return <li key={segment}>{segment}</li>;
                            });
                          }

                          return null;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-end mt-8">
          <Button
            hierarchy="primary"
            onClick={onCloseModal}
            size="md"
            type="button"
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreventDeleteResourceModal;
