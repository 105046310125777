import { useState } from 'react';

import { Survey } from 'types/domainModels';
import { useDownloadQuestionnaire } from 'hooks/backend/exports';

import ButtonLoading from './forms/ButtonLoading';
import Checkbox from './forms/Checkbox';

const QuestionnaireDownload = ({ survey }: { survey: Survey }) => {
  const [includeInactiveQuestions, setIncludeInactiveQuestions] =
    useState(false);

  const {
    isPending: isDownloadingQuestionnaire,
    mutate: downloadQuestionnaire,
  } = useDownloadQuestionnaire({
    surveyId: survey.id,
  });

  return (
    <div className="flex justify-between gap-6">
      <div>
        <p>
          MS Word export of survey questions, display logic, and other details.
        </p>

        <label className="mt-2 grid grid-cols-[max-content,1fr] gap-2 items-start">
          <div className="mt-1">
            <Checkbox
              checked={includeInactiveQuestions}
              name="include-removed-raw"
              onChange={(event) => {
                setIncludeInactiveQuestions(event.target.checked);
              }}
              tag="div"
            />
          </div>

          <div>
            <div>Include Inactive Questions</div>
            <div className="text-sm text-gray-d-600">
              Include questions currently not displayed to respondents.
            </div>
          </div>
        </label>
      </div>
      <div>
        <ButtonLoading
          hierarchy="secondary-gray"
          isLoading={isDownloadingQuestionnaire}
          onClick={() => {
            downloadQuestionnaire({ includeInactiveQuestions });
          }}
          size="md"
          type="button"
        >
          Export
        </ButtonLoading>
      </div>
    </div>
  );
};

export default QuestionnaireDownload;
