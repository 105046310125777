import { Outlet, useParams } from 'react-router-dom';

import SurveyHeader from 'components/surveyEdit/SurveyHeader';

const SurveyPage = () => {
  const { id } = useParams<{ id: string }>();
  const surveyId = Number(id);

  return (
    <div className="font-inter text-button text-gray-900 font-normal h-full">
      <div className="fixed top-0 left-0 right-0 h-16 border-b border-gray-300 z-30">
        <SurveyHeader surveyId={surveyId} />
      </div>

      <div className="pt-16 h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default SurveyPage;
