import { cloneDeep } from 'lodash-es';
import { useField } from 'formik';

import { OPTION_FEATURES } from '../../util/options';
import {
  Question,
  QuestionBlock,
  SurveyVariable,
} from '../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';

import Button from 'components/common/forms/Button';
import FormInput from '../common/forms/FormInput';
import MediaUploadWithCrop from '../common/MediaUploadWithCrop';
import { OptionActions } from './QuestionOption';
import OptionFeatureLabel from './OptionFeatureLabel';

const QuestionConcept = ({
  index,
  onClickRemove,
  question,
  questionBlocks,
  questions,
  variables,
}: {
  index: number;
  onClickRemove?: () => void;
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const fieldPrefix = `concepts.${index}`;
  const [{ value: concept }] = useField<QuestionFormOption>(`${fieldPrefix}`);

  const [{ value: optionFeatures }, , optionFeatureHelpers] = useField<
    QuestionFormOption['features']
  >(`${fieldPrefix}.features`);
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');

  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${fieldPrefix}.weight`,
  );
  const [{ value: isActive }, , activeHelper] = useField(
    `concepts.${index}.features.isActive`,
  );
  const [preservedField, , preservationHelpers] = useField<boolean>(
    `concepts.${index}.preserved`,
  );

  return (
    <div className="p-2">
      <div className="flex items-center w-full space-x-4">
        <ConceptImage fieldPrefix={fieldPrefix} />

        <div className="flex-grow">
          <FormInput
            name={`${fieldPrefix}.title`}
            placeholder="Concept Name..."
            size="md"
            type="text"
          />

          {/* Display Logic */}
          <div className="space-x-2">
            {optionFeatures.displayLogic &&
              optionFeatures.displayLogic.enabled && (
                <OptionFeatureLabel
                  label="Display Logic"
                  onClickRemove={() => {
                    const newOptionFeatures = cloneDeep(optionFeatures);
                    newOptionFeatures.displayLogic.enabled = false;

                    optionFeatureHelpers.setValue(newOptionFeatures);
                  }}
                />
              )}
            {!isActive && <OptionFeatureLabel label="Inactive" />}
          </div>

          {/* Preserved */}
          <div className="space-x-2">
            {preservedField.value && (
              <OptionFeatureLabel
                label="Preserved"
                onClickRemove={() => {
                  preservationHelpers.setValue(false);
                }}
              />
            )}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <OptionActions
            activeHelper={activeHelper}
            availableOptionFeatures={OPTION_FEATURES.filter(
              // This will result in an arrary of length 1
              (feature) => feature.apiName === 'isActive',
            )}
            index={index}
            namePrefix="concepts"
            onClickRemove={onClickRemove}
            optionId={concept.id}
            question={question}
            questionBlocks={questionBlocks}
            questionType={questionType}
            questions={questions}
            variables={variables}
            weightHelper={weightHelper}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionConcept;

const ConceptImage = ({
  fieldPrefix,
}: {
  fieldPrefix: string;
}): JSX.Element => {
  const [{ value: media }, , mediaHelpers] = useField<
    QuestionFormOption['image']
  >(`${fieldPrefix}.image`);

  return (
    <MediaUploadWithCrop
      media={media}
      onCrop={(preview) => {
        mediaHelpers.setValue({ data: null, preview });
      }}
      onPreviewLoaded={(preview) => {
        mediaHelpers.setValue({ data: null, preview });
      }}
      onUploadError={() => {
        mediaHelpers.setError('Failed to upload image. Please try again.');
        mediaHelpers.setTouched(true);
      }}
      onUploaded={(dataUrl) => {
        mediaHelpers.setValue({
          data: dataUrl,
          preview: media.preview,
        });
      }}
      trigger={
        <Button hierarchy="secondary-gray" size="xs">
          Upload
        </Button>
      }
    />
  );
};
