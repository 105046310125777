import { clsx } from 'clsx';
import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import ChatIcon from '../common/icons/ChatIcon';
import Icon from '../common/Icon';

let lastSidebarScroll: number | undefined;

const Context = createContext<{ onToggleSidebar(): void } | undefined>(
  undefined,
);

const CollapsibleSidebar = ({ children }: { children: ReactNode }) => {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const previousSidebar = sidebarRef.current;
    if (lastSidebarScroll) {
      sidebarRef.current?.scrollTo(0, lastSidebarScroll);
    }

    return () => {
      lastSidebarScroll = previousSidebar?.scrollTop;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        onToggleSidebar: () => {
          setIsRightSidebarOpen((isOpen) => !isOpen);
        },
      }}
    >
      <div
        className={clsx(
          'bg-gray-d-50 h-full w-[325px] border-l border-gray-300 shrink-0 xl:fixed xl:top-[63px] xl:right-0 transition-all xl:z-20',
          {
            'xl:shadow-lg': isRightSidebarOpen,
            'w-[50px]': !isRightSidebarOpen,
          },
        )}
      >
        {isRightSidebarOpen ? (
          children
        ) : (
          <>
            <button
              className="min-h-16 flex items-center justify-center py-3 p-2 w-full text-gray-d-600"
              onClick={() => {
                setIsRightSidebarOpen((isOpen) => !isOpen);
              }}
              type="button"
            >
              <div className="w-5 h-5">
                <ChatIcon />
              </div>
            </button>
          </>
        )}
      </div>

      {/*
       * On xl screen sizes, we add a placeholder to prevent layout shifts because
       * the sidebar becomes fixed so it can float over the main content.
       */}
      <div className="xl:w-[50px] h-full hidden xl:block" />
    </Context.Provider>
  );
};

export default CollapsibleSidebar;

export const CollapsibleSidebarHeader = ({
  children,
}: {
  children: ReactNode;
}) => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'CollapsibleSidebarHeader must be used within a CollapsibleSidebar',
    );
  }

  return (
    <div className="relative pl-6 pr-12 border-b border-gray-300 flex">
      {children}

      <button
        className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-d-600"
        onClick={() => {
          context.onToggleSidebar();
        }}
        type="button"
      >
        <div className="w-5 h-5">
          <Icon id="layout-right" />
        </div>
      </button>
    </div>
  );
};

export const CollapsibleSidebarHeaderButton = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <button
      className="flex items-center h-full py-4 text-green border-b-2 border-green space-x-2"
      type="button"
    >
      {children}
    </button>
  );
};
