import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { Quote, RateTierService } from 'types/domainModels';

export const GET_QUOTE = () => ({
  path: '/prices/quote',
  version: 'v1' as const,
});

export async function getQuote({
  data,
}: {
  data: {
    incidence: number;
    questionCount: number;
    respondentsCount: number;
    services: RateTierService[];
  };
}) {
  return (await axios.post<Quote>(getAPIUrl(GET_QUOTE()), data)).data;
}
