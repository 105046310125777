import { compact } from 'lodash-es';

import { apiDataToFormData } from './questions';
import { apiDisplayLogicToFormDisplayLogic } from './questionBlocks';
import {
  hasDisplayLogicReferencesForResource,
  hasSurveyVariableReferencesForResource,
} from './displayLogic';
import { hasPipingReferencesForResource } from './piping';
import {
  Question,
  QUESTION_TYPE,
  QuestionBlock,
  SurveyVariable,
} from '../types/domainModels';

export interface QuestionReference {
  title: string;
  references: QuestionReferenceFeature[];
}

type QuestionReferenceFeature =
  | {
      type: 'carryForward' | 'displayLogic' | 'pipeConcept' | 'piping';
    }
  | {
      resourceTitles: string[];
      type: 'displayLogicConcepts' | 'displayLogicOptions';
    };

export interface QuestionBlockReference {
  title: string;
  references: QuestionBlockReferenceFeature[];
}

type QuestionBlockReferenceFeature = {
  type: 'displayLogicBlocks';
};

export interface VariableReference {
  title: string;
  references: VariableReferenceFeature[];
}

type VariableReferenceFeature = {
  resourceTitles: string[];
  type: 'variableSegments';
};

export function getReferencesForResource({
  resource,
  questionBlocks,
  questions,
  variables,
}: {
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}) {
  return {
    questionBlockReferences: getQuestionBlockReferencesForResource({
      questionBlocks,
      questions,
      resource,
    }),
    questionReferences: getQuestionReferencesForResource({
      questions,
      resource,
    }),
    variableReferences: getVariableReferencesForResource({
      resource,
      variables,
    }),
  };
}

export function hasReferencesForResource({
  resource,
  questionBlocks,
  questions,
  variables,
}: {
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}) {
  const { questionBlockReferences, questionReferences, variableReferences } =
    getReferencesForResource({
      resource,
      questionBlocks,
      questions,
      variables,
    });

  return (
    questionBlockReferences.length > 0 ||
    questionReferences.length > 0 ||
    variableReferences.length > 0
  );
}

function getVariableReferencesForResource({
  resource,
  variables,
}: {
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
  variables: SurveyVariable[];
}) {
  const variableReferences: VariableReference[] = [];

  variables.forEach((variable) => {
    const { segments } = variable;

    const references: VariableReference['references'] = [];

    const variableSegments = compact(
      segments.map((segment) => {
        const isDependentOnResource = hasSurveyVariableReferencesForResource({
          resource,
          segment,
        });

        if (isDependentOnResource) {
          return segment.title;
        }

        return null;
      }),
    );
    if (variableSegments.length > 0) {
      references.push({
        resourceTitles: variableSegments,
        type: 'variableSegments',
      });
    }

    if (references.length > 0) {
      variableReferences.push({ title: variable.title, references });
    }
  });

  return variableReferences;
}

function getQuestionBlockReferencesForResource({
  questionBlocks,
  questions,
  resource,
}: {
  questionBlocks: QuestionBlock[];
  questions: Question[];
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
}) {
  const childBlocks = questionBlocks[0]?.questionBlocks ?? [];

  const questionBlockReferences: QuestionBlockReference[] = [];

  for (const block of childBlocks) {
    const references: QuestionBlockReference['references'] = [];

    const displayLogic = apiDisplayLogicToFormDisplayLogic({
      block,
      questions,
    });
    if (
      hasDisplayLogicReferencesForResource({
        displayLogic,
        resource,
      })
    ) {
      references.push({ type: 'displayLogicBlocks' });
    }

    if (references.length > 0) {
      questionBlockReferences.push({ title: block.title, references });
    }
  }

  return questionBlockReferences;
}

function getQuestionReferencesForResource({
  questions,
  resource,
}: {
  questions: Question[];
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
}) {
  const questionReferences: QuestionReference[] = [];

  // Convert questions to form data format because it's easier to search through the data
  // in that format.
  const questionsFormData = questions.map((question) => {
    return apiDataToFormData({ question, questions });
  });

  questionsFormData.forEach((formData, i) => {
    const currentQuestion = questions[i];
    const questionTitle = `${currentQuestion.sort}) ${formData.title}`;
    const optionsToCheck =
      formData.questionType?.value === QUESTION_TYPE.MATRIX
        ? formData.statements
        : formData.options;
    const conceptsToCheck = formData.concepts;

    const references: QuestionReference['references'] = [];

    if (
      resource.type === 'option' &&
      optionsToCheck.some((option) => option.carryOverParentId === resource.id)
    ) {
      references.push({ type: 'carryForward' });
    } else if (
      resource.type === 'matrixOption' &&
      formData.features?.carryForward.matrixOption?.value.id === resource.id
    ) {
      references.push({ type: 'carryForward' });
    } else if (
      resource.type === 'question' &&
      formData.features.carryForward.question?.value.id === resource.id
    ) {
      references.push({ type: 'carryForward' });
    }

    if (
      hasDisplayLogicReferencesForResource({
        displayLogic: formData.features.displayLogic.values,
        resource,
      })
    ) {
      references.push({ type: 'displayLogic' });
    }

    const displayLogicConcepts = compact(
      conceptsToCheck.map((concept) => {
        const displayLogicReferences = hasDisplayLogicReferencesForResource({
          displayLogic: concept.features.displayLogic.values,
          resource,
        });

        if (displayLogicReferences) {
          return `${concept.title}`;
        }
      }),
    );
    if (displayLogicConcepts.length > 0) {
      references.push({
        resourceTitles: displayLogicConcepts,
        type: 'displayLogicConcepts',
      });
    }

    const displayLogicOptions = compact(
      optionsToCheck.map((option, j) => {
        const currentOptionSort = currentQuestion.options[j].sort;
        const displayLogicReferences = hasDisplayLogicReferencesForResource({
          displayLogic: option.features.displayLogic.values,
          resource,
        });

        if (displayLogicReferences) {
          return `${currentOptionSort}) ${option.title}`;
        }

        return null;
      }),
    );
    if (displayLogicOptions.length > 0) {
      references.push({
        resourceTitles: displayLogicOptions,
        type: 'displayLogicOptions',
      });
    }

    if (hasPipingReferencesForResource({ resource, title: formData.title })) {
      references.push({ type: 'piping' });
    }

    if (
      resource.type === 'question' &&
      formData.features.pipeConcept.question?.value.id === resource.id
    ) {
      references.push({ type: 'pipeConcept' });
    }

    if (references.length > 0) {
      questionReferences.push({ title: questionTitle, references });
    }
  });

  return questionReferences;
}
