import { clsx } from 'clsx';
import { ReactNode } from 'react';

import Tooltip from '../common/Tooltip';

const CHART_COLORS = [
  '#00372D', // forest
  '#006451',
  '#0E8765',
  '#23B07E',
  '#3DCB90',
  '#57D99E',
  '#71E3AD',
  '#8CEABE',
  '#A8F0D1',
  '#C4F5E3',
  '#E1FAF3',
];

const CHART_VERTICAL_LINES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const HorizontalBarChart = ({
  children,
  labels,
}: {
  children: ReactNode;
  labels?: ReactNode;
}) => {
  return (
    <div>
      <div>{labels}</div>
      <div className="relative pb-8 m-4 mt-0">
        <div
          className="relative grid gap-4 pb-4 items-center overflow-auto z-10"
          style={{
            height: '315px',
          }}
        >
          {children}
        </div>
        <HorizontalChartPercentageLabels />
        <HorizontalChartGridLines />
      </div>
    </div>
  );
};

export default HorizontalBarChart;

export const ChartHeaderLabels = ({ labels }: { labels: string[] }) => {
  return (
    <div className="flex justify-between border-b border-light-grey px-4 pb-2">
      <div
        className="flex-shrink-0 flex flex-wrap justify-end max-w-xs space-x-2 text-xs overflow-auto"
        style={{ maxHeight: 100 }}
      >
        {labels.map((label, labelIdx) => (
          <div key={`cohort-legend-${labelIdx}`} className="flex items-center">
            <div
              className="flex-shrink-0 w-3 h-3 mr-2 rounded-full"
              style={{
                backgroundColor: CHART_COLORS[labelIdx % CHART_COLORS.length],
              }}
            />
            <div>{label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HorizontalChartGridLines = () => {
  return (
    <div className="absolute top-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full h-full">
      <div className="border-r-2 border-light-grey" />
      {CHART_VERTICAL_LINES.map((_stretch, stretchIdx) => {
        if (stretchIdx > 0) {
          return (
            <div
              key={stretchIdx}
              className="h-full border-r border-light-grey"
            />
          );
        }
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};

export const HorizontalBars = ({
  children,
  lastColumn,
  title,
}: {
  children: ReactNode;
  lastColumn?: string;
  title: string;
}) => {
  return (
    <div>
      <div className="my-2 text-green text-sm">{title}</div>

      <div className="flex items-center justify-center">
        <div className="flex items-center w-full">
          <div
            className="flex items-center"
            style={{
              // This matches the width of the first column in the horizontal bars grid
              // (see Tailwind configuration for "horizontal-bar-chart-bg").
              width: '85px',
              fontSize: '10px',
            }}
          >
            TOTAL SAMPLE
          </div>

          <div className="flex-grow flex rounded-full overflow-hidden">
            {children}
          </div>

          <div
            style={{
              // This matches the width of the last column in the horizontal bars grid
              // (see Tailwind configuration for "horizontal-bar-chart-bg").
              width: '60px',
              paddingLeft: '3px',
              fontSize: '10px',
            }}
          >
            {lastColumn}
          </div>
        </div>
      </div>
    </div>
  );
};

export const HorizontalBarPortion = ({
  colorIndex,
  count,
  index,
  numBars,
  percentage,
  title,
}: {
  colorIndex: number;
  count: number;
  index: number;
  numBars: number;
  percentage: number;
  title: string;
}) => {
  return (
    <Tooltip
      trigger={
        <div
          key={index}
          className={clsx('relative h-5', {
            'rounded-l-full': index === 0,
            'rounded-r-full': index === numBars,
          })}
          style={{
            flex: percentage,
            backgroundColor: CHART_COLORS[colorIndex % CHART_COLORS.length],
          }}
        />
      }
    >
      <div className="flex flex-col justify-center">
        <div className="mb-2 text-sm text-center">{title}</div>

        <div className="flex w-full">
          <div className="w-1/2 mr-2 pr-2 border-r border-light-grey text-center">
            <span className="text-primary-d-600 text-sm">{count}</span>
            <div className="text-xs text-dark-grey">Responses</div>
          </div>
          <div className="w-1/2 text-center">
            <span className="text-primary-d-600 text-sm">
              {`${Number(percentage * 100).toFixed(0)}%`}
            </span>
            <div className="text-xs text-dark-grey">Percentage</div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

const HorizontalChartPercentageLabels = () => {
  return (
    <div
      className="absolute bottom-0 left-0 grid grid-cols-horizontal-bar-chart-bg w-full border-t border-light-grey bg-white"
      style={{
        zIndex: 1,
      }}
    >
      <div
        className="border-r-2 border-light-grey"
        style={{
          height: '30px',
        }}
      />
      {CHART_VERTICAL_LINES.map((_option, optionIdx) => {
        if (optionIdx === 0) {
          return '';
        }

        return (
          <div
            key={optionIdx}
            className="flex items-center justify-end border-r border-light-grey text-dark-grey text-tiny"
          >
            {`${optionIdx * 10}%`}
          </div>
        );
      })}
      <div className="border-l border-light-grey" />
    </div>
  );
};
