import { clsx } from 'clsx';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldArray, useField } from 'formik';

import {
  apiOptionToFormOption,
  isOptionPopulated,
} from '../../../util/questions';
import {
  Question,
  QuestionBlock,
  SurveyVariable,
} from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';
import { SCALE_TYPES, SCALE_UNITS } from '../../../constants/scaleComponents';

import AddButton from '../../common/forms/AddButton';
import CarryForward from '../questionFeatures/CarryForward';
import DisplayXOfY from '../questionFeatures/DisplayXOfY';
import ReverseOptions from '../questionFeatures/ReverseOptions';
import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import DisplayOptionTitle from '../questionFeatures/DisplayOptionTitle';
import DragIcon from '../../common/icons/DragIcon';
import { FileUploadBulk } from 'components/common/forms/FileUpload';
import FormInput from '../../common/forms/FormInput';
import FormSearchSelectInput from '../../common/forms/FormSearchSelectInput';
import PasteOptionsPopover from '../PasteOptionsPopover';
import PipeConcept from '../questionFeatures/PipeConcept';
import QuestionOption from '../QuestionOption';
import RandomizeOptions from '../questionFeatures/RandomizeOptions';
import RequiredSum from '../questionFeatures/RequiredSum';
import ViewConcept from '../questionFeatures/ViewConcept';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';

const ScaleQuestion = ({
  concepts = [],
  pipeConcept = false,
  question,
  questionBlocks,
  questions,
  variables,
}: {
  concepts?: QuestionFormOption[];
  pipeConcept?: boolean;
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}): JSX.Element => {
  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          disabled={pipeConcept}
          question={question}
          questionBlocks={questionBlocks}
          questions={questions}
          upperLimit={1}
        />
      </SurveyEditRow>

      <div className="py-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Scales" />
        <Scales
          question={question}
          questionBlocks={questionBlocks}
          questions={questions}
          variables={variables}
        />
      </div>

      <div className="py-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Option Features" />
        <div className="mx-4 mt-4 space-y-4">
          <RandomizeOptions isScale />
          <DisplayXOfY isScale />
          <RequiredSum />
          <DisplayOptionTitle isScale />
          <CarryForward question={question} questions={questions} />
          <ReverseOptions isScale={true} />
        </div>
      </div>

      <div className="py-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <PipeConcept
            concepts={concepts}
            question={question}
            questions={questions}
          />
          <ViewConcept />
          <DisplayLogic question={question} questions={questions} />
        </div>
      </div>
    </>
  );
};

export default ScaleQuestion;

const Scales = ({
  question,
  questionBlocks,
  questions,
  variables,
}: {
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: options }, , optionsHelpers] =
    useField<QuestionFormData['options']>('options');

  return (
    <FieldArray
      name="options"
      render={(arrayHelpers) => {
        return (
          <>
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                if (!destination || source.index === destination.index) {
                  return;
                }

                arrayHelpers.move(source.index, destination.index);
              }}
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-6"
                  >
                    {options.map((_option, index) => {
                      return (
                        <DraggableScale
                          key={index}
                          index={index}
                          isImage={optionType?.value === 'image'}
                          onClickRemove={() => {
                            if (options.length > 1) {
                              arrayHelpers.remove(index);
                            } else {
                              arrayHelpers.replace(
                                index,
                                apiOptionToFormOption(),
                              );
                            }
                          }}
                          question={question}
                          questionBlocks={questionBlocks}
                          questions={questions}
                          variables={variables}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="flex mt-4 space-x-4">
              {optionType?.value === 'image' && (
                <FileUploadBulk
                  onLoadFiles={(filesData) => {
                    const newOptions: QuestionFormOption[] = [];
                    for (const option of options) {
                      if (isOptionPopulated(option)) {
                        newOptions.push(option);
                      }
                    }

                    for (const fileData of filesData) {
                      const option = apiOptionToFormOption();
                      option.image.preview = fileData;
                      newOptions.push(option);
                    }

                    optionsHelpers.setValue(newOptions);
                  }}
                  trigger={<AddButton label="Bulk Upload" />}
                />
              )}
              <AddButton
                label="Add Scale"
                onClick={() => {
                  const previousScale = options[options.length - 1];

                  const newScale = apiOptionToFormOption();
                  if (previousScale) {
                    newScale.scale = {
                      ...previousScale.scale,
                    };
                  }

                  arrayHelpers.push(newScale);
                }}
              />
              <PasteOptionsPopover
                onAddOptions={(optionsToAdd, { shouldReplace }) => {
                  const newOptions = optionsToAdd.map((option) => {
                    return apiOptionToFormOption({
                      option: { title: option },
                    });
                  });

                  optionsHelpers.setValue(
                    shouldReplace ? newOptions : [...options, ...newOptions],
                  );
                }}
              />
            </div>
          </>
        );
      }}
    />
  );
};

const DraggableScale = ({
  index,
  isImage,
  onClickRemove,
  question,
  questionBlocks,
  questions,
  variables,
}: {
  index: number;
  isImage: boolean;
  onClickRemove(): void;
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  variables: SurveyVariable[];
}) => {
  const namePrefix = `options.${index}.scale`;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <Draggable draggableId={`scale-${index}`} index={index}>
      {(provided, snapshot) => {
        const isDragIconVisible = isHovering || snapshot.isDragging;

        return (
          <div
            ref={provided.innerRef}
            className="relative pl-4"
            {...provided.draggableProps}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <div
              {...provided.dragHandleProps}
              className="flex absolute top-1/2 left-0 flex-col items-center justify-center transform -translate-y-1/2 -translate-x-full h-full"
              tabIndex={-1}
            >
              <div
                className={clsx('w-4 h-4 text-dark-grey', {
                  visible: isDragIconVisible,
                  invisible: !isDragIconVisible,
                })}
              >
                <DragIcon />
              </div>
            </div>
            <div className="absolute top-0 left-0 w-0.5 h-full bg-dark-grey" />
            <div className="space-y-4">
              <QuestionOption
                index={index}
                isImage={isImage}
                onClickRemove={onClickRemove}
                question={question}
                questionBlocks={questionBlocks}
                questions={questions}
                variables={variables}
              />
              <div className="grid grid-cols-2 gap-4">
                <FormSearchSelectInput
                  label="Type"
                  name={`${namePrefix}.type`}
                  options={SCALE_TYPES}
                />
                <FormSearchSelectInput
                  label="Unit"
                  name={`${namePrefix}.unit`}
                  options={SCALE_UNITS}
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <FormInput
                  label="Low Label"
                  name={`${namePrefix}.labelLow`}
                  size="md"
                  type="text"
                />
                <FormInput
                  label="Middle Label"
                  name={`${namePrefix}.labelMiddle`}
                  size="md"
                  type="text"
                />
                <FormInput
                  label="High Label"
                  name={`${namePrefix}.labelHigh`}
                  size="md"
                  type="text"
                />
                <FormInput
                  inputMode="numeric"
                  label="Minimum"
                  name={`${namePrefix}.rangeMin`}
                  size="md"
                  type="text"
                />
                <FormInput
                  inputMode="numeric"
                  label="Step"
                  name={`${namePrefix}.rangeStep`}
                  size="md"
                  type="text"
                />
                <FormInput
                  inputMode="numeric"
                  label="Maximum"
                  name={`${namePrefix}.rangeMax`}
                  size="md"
                  type="text"
                />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
