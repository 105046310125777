import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchQuestionQuotas,
  removeQuestionQuotas,
} from 'services/backend/questionQuotas';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const questionQuotaQueries = {
  all: (opts: { surveyId: number }) => [
    'surveys',
    opts.surveyId,
    'question-quotas',
  ],
  forSurvey: (opts: { surveyId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchQuestionQuotas({
          surveyId: opts.surveyId,
          waveIds: opts.waveIds,
        }),
      queryKey: [
        ...questionQuotaQueries.all({ surveyId: opts.surveyId }),
        opts.waveIds,
      ],
    }),
};

export function useRemoveQuestionQuotas(
  opts?: ReactQueryFunctionCallbacks<typeof removeQuestionQuotas>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: removeQuestionQuotas,
    onSuccess: (...args) => {
      queryClient.invalidateQueries();

      opts?.onSuccess?.(...args);
    },
  });
}
