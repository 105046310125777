import { getEnvVar } from './env';
import { PanelProvider, Survey } from '../types/domainModels';

const PANEL_PROVIDER_QUERY_PARAMS: Record<PanelProvider, string> = {
  LUCID:
    '?provider=lucid&lucidSurveyId=[%RSFN%]&RID=[%RID%]&respondentIncentive=[%COST%]',
  MFOUR: '?provider=mfour&RID=[%RID%]',
  MINDFORCE: '?provider=mindforce&RID=[%RID%]',
  PURE_SPECTRUM:
    '?provider=purespectrum&RID=[%transaction_id%]&ps_hash=[%ps_hash%]',
  REP_DATA: '?provider=repdata&RID=[%RID%]',
  ROI: '?provider=ROI&RID=[%RID%]',
  SYMMETRIC: '?provider=symmetric&RID=[%RID%]',
};

const RESPONDENT_HOST = getEnvVar('RESPONDENT_HOST');

export function getLiveLink({
  isAdmin,
  survey,
}: {
  isAdmin: boolean;
  survey: Survey;
}) {
  // Admins:
  //   - Can see live links for all non-Lucid surveys (BYOA + other sample providers).
  // Non-admins:
  //   - Can see live links for only BYOA surveys.
  if (isLucidSurvey(survey) || (!isAdmin && !survey.isBringYourOwnAudience)) {
    return;
  }

  const url = getSurveyResponseURL(survey);

  let liveLink = url;
  if (survey.panelProvider) {
    liveLink = `${url}${PANEL_PROVIDER_QUERY_PARAMS[survey.panelProvider]}`;
  }

  return liveLink;
}

function getSurveyResponseURL(survey: Survey) {
  return `${RESPONDENT_HOST}/surveys/${survey.hash}`;
}

export function getTestLinks({
  startingQuestionID = undefined,
  survey,
}: {
  startingQuestionID?: number;
  survey: Survey;
}) {
  const url = getSurveyResponseURL(survey);

  const previewParams = new URLSearchParams([
    ['mode', 'testing'],
    ['testView', 'collapsed'],
  ]);
  const testParams = new URLSearchParams([['mode', 'testing']]);

  if (startingQuestionID) {
    previewParams.append('startingQuestionID', startingQuestionID.toString());
    testParams.append('startingQuestionID', startingQuestionID.toString());
  }

  return {
    previewLink: `${url}?${previewParams}`,
    testLink: `${url}?${testParams}`,
  };
}

export function isLucidSurvey(survey: {
  isBringYourOwnAudience: boolean;
  panelProvider: PanelProvider | null;
}) {
  return (
    !survey.isBringYourOwnAudience &&
    (!survey.panelProvider || survey.panelProvider === 'LUCID')
  );
}
