import { Form, Formik, FormikErrors } from 'formik';
import { some } from 'lodash-es';

import { apiDataToFormData } from '../../util/questions';
import { getOrgStatus } from '../../util/users';
import { Question, TeamMember } from '../../types/domainModels';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useCreateQuestionTemplate } from 'hooks/backend/questions';
import { useModal } from '../../hooks/modals';
import { useOrderedTeamMembers } from '../../hooks/backend/team';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import FormTextarea from '../common/forms/FormTextarea';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';

interface CreateTemplateFormData {
  owner: ReactSelectValue<TeamMember> | null;
  templateDescription: string;
  templateTitle: string;
}

/**
 * Non-templatable features are ones that depend on other questions.
 */
function hasNonTemplatableFeatures({
  question,
  questions,
}: {
  question: Question;
  questions: Question[];
}): boolean {
  const questionFormData = apiDataToFormData({ question, questions });

  const hasIncompatibleQuestionFeatures =
    questionFormData.features.carryForward.enabled ||
    questionFormData.features.displayLogic.enabled ||
    questionFormData.features.pipeConcept.enabled;
  const hasIncompatibleOptionFeatures = some(
    [
      ...questionFormData.concepts,
      ...questionFormData.statements,
      ...questionFormData.options,
    ],
    (formOption) => {
      return (
        !!formOption.carryOverParentId ||
        formOption.features.displayLogic.enabled
      );
    },
  );

  return hasIncompatibleQuestionFeatures || hasIncompatibleOptionFeatures;
}

function validateData(
  formData: CreateTemplateFormData,
  { hasChangedOrg = false } = {},
): FormikErrors<CreateTemplateFormData> {
  const errors: FormikErrors<CreateTemplateFormData> = {};

  if (!formData.templateTitle) {
    errors.templateTitle = 'Please provide a title.';
  }

  if (hasChangedOrg && !formData.owner) {
    errors.owner = 'Please select a template owner.';
  }

  return errors;
}

const CreateQuestionTemplate = ({
  question,
  questions,
}: {
  question: Question;
  questions: Question[];
}) => {
  const {
    isOpen: isCreateTemplateModalOpen,
    onCloseModal: onCloseCreateTemplateModal,
    setIsOpen: setIsCreateTemplateModalOpen,
  } = useModal();

  return (
    <>
      <IconBackground
        onClick={() => {
          setIsCreateTemplateModalOpen(true);
        }}
        tooltip="Create Question Template"
      >
        <div className="w-4 h-4">
          <Icon id="file-plus-03" />
        </div>
      </IconBackground>
      {isCreateTemplateModalOpen && (
        <CreateQuestionTemplateModal
          onCloseModal={onCloseCreateTemplateModal}
          question={question}
          questions={questions}
        />
      )}
    </>
  );
};

export default CreateQuestionTemplate;

const CreateQuestionTemplateModal = ({
  onCloseModal,
  question,
  questions,
}: {
  onCloseModal(): void;
  question: Question;
  questions: Question[];
}): JSX.Element => {
  const { hasChangedOrg } = getOrgStatus();

  const { isPending: isCreatingTemplate, mutateAsync: createQuestionTemplate } =
    useCreateQuestionTemplate({
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: () => {
        onCloseModal();

        showSuccessMessage('Question template created successfully.');
      },
    });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Create Question Template
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <div className="mb-4 space-y-2">
        <p>
          Use the form below to create a template from the existing question:
          <br />
          <span className="font-semibold">{question.title}</span>
        </p>
        {hasNonTemplatableFeatures({ question, questions }) && (
          <p className="mt-2 italic">
            Warning: This question has features that cannot be templatized (e.g.
            display logic, pipe concept, carry forward). The question can still
            be templatized but these features will be ignored.
          </p>
        )}
      </div>

      <Formik<CreateTemplateFormData>
        initialValues={{
          owner: null,
          templateDescription: '',
          templateTitle: '',
        }}
        onSubmit={(formData) => {
          return createQuestionTemplate({
            data: { ...formData, ownerId: formData.owner?.value?.id },
            questionId: question.id,
          });
        }}
        validate={(formData) => {
          return validateData(formData, { hasChangedOrg });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <CreateTemplateForm
          hasChangedOrg={hasChangedOrg}
          isCreatingTemplate={isCreatingTemplate}
          onClickCancel={onCloseModal}
        />
      </Formik>
    </Modal>
  );
};

const CreateTemplateForm = ({
  hasChangedOrg,
  isCreatingTemplate,
  onClickCancel,
}: {
  hasChangedOrg: boolean;
  isCreatingTemplate: boolean;
  onClickCancel(): void;
}): JSX.Element => {
  const { orderedTeamMembers } = useOrderedTeamMembers({
    enabled: hasChangedOrg,
  });
  const teamMembers = orderedTeamMembers.map((teamMember) => {
    return {
      label: `${teamMember.firstName} ${teamMember.lastName}`,
      value: teamMember,
    };
  });

  return (
    <Form>
      <div className="space-y-4">
        <FormInput
          id="templateTitle"
          label="Question Template Title"
          labelFor="templateTitle"
          name="templateTitle"
          size="lg"
          type="text"
        />
        <FormTextarea
          id="templateDescription"
          label="Description"
          labelFor="templateDescription"
          name="templateDescription"
          size="lg"
        />
        {hasChangedOrg && (
          <FormSearchSelectInput
            inputId="owner"
            label="Template Owner"
            labelFor="owner"
            name="owner"
            options={teamMembers}
          />
        )}
      </div>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isCreatingTemplate}
          size="lg"
          type="submit"
        >
          Create Template
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickCancel}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
