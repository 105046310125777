import { ErrorMessage } from '@hookform/error-message';
import { isEmpty, orderBy } from 'lodash-es';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { getCurrentWaveId } from 'util/surveyWaves';
import { getNestedErrorMessagesRHF } from 'util/forms';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { SurveyWave } from '../../types/domainModels';
import { useModal } from 'hooks/modals';
import { useSaveWave } from 'hooks/backend/surveys';

import ButtonLoading from '../common/forms/ButtonLoading';
import DiscardWaveModal from 'components/common/DiscardWaveModal';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormLabel from 'components/common/forms/FormLabel';
import Icon from 'components/common/Icon';
import IconBackground from 'components/common/icons/IconBackground';
import Input from 'components/common/forms/Input';
import PutSurveyIntoDraftDialog from 'components/common/PutSurveyIntoDraftDialog';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import Textarea from 'components/common/forms/Textarea';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

interface SaveWaveData {
  description: string;
  respondents: number | '';
  title: string;
}

const SaveWaveDataSchema = z.object({
  description: z.string(),
  respondents: z.coerce.number().min(1, 'Please enter at least 1 respondent.'),
  title: z.string().min(1, 'Please enter a title.'),
});
type SaveWaveDataValidated = z.infer<typeof SaveWaveDataSchema>;

const WavesStep = ({
  isShowingUnsavedChanges,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onWaveDirtyChanged,
  onWaveSaved,
  survey,
  waveId,
  waves,
}: {
  isShowingUnsavedChanges: boolean;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onWaveDirtyChanged(isDirty: boolean): void;
  onWaveSaved(newWave: SurveyWave): void;
  survey: { id: number; statusId: number };
  waveId: number | undefined;
  waves: SurveyWave[];
}) => {
  const [submitStep, setSubmitStep] = useState<
    'editing' | 'needsConfirmation' | 'readyToSubmit'
  >('editing');

  const currentWaveId = getCurrentWaveId(waves);
  const existingWave = waveId
    ? waves.find((wave) => wave.id === waveId)
    : undefined;

  const { isPending: isSavingWave, mutate: saveWave } = useSaveWave({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: ({ wave }) => {
      if (existingWave) {
        showSuccessMessage('The wave details were edited successfully.');
      } else {
        showSuccessMessage('A new wave was created successfully.');
      }

      setSubmitStep('editing');
      reset(apiDataToFormData(wave));
      onWaveSaved(wave);
    },
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<SaveWaveData, unknown, SaveWaveDataValidated>({
    defaultValues: {
      description: '',
      title: '',
      respondents: '',
    },
    resolver: zodResolver(SaveWaveDataSchema),
    values: existingWave ? apiDataToFormData(existingWave) : undefined,
  });

  const onSubmit: SubmitHandler<SaveWaveDataValidated> = (data) => {
    const isAdjustingRespondentsOnLiveSurvey =
      survey.statusId === SURVEY_STATUSES.LIVE.id &&
      submitStep === 'editing' &&
      existingWave &&
      data.respondents !== existingWave.target;

    // Adjusting the number of respondents on a live survey puts the survey back into draft
    // because the number of respondents affects the cost of the survey so we require a user
    // to relaunch.
    if (isAdjustingRespondentsOnLiveSurvey) {
      setSubmitStep('needsConfirmation');
      return;
    }

    const apiData = {
      description: data.description,
      target: data.respondents,
      title: data.title,
    };

    return existingWave
      ? saveWave({ data: apiData, waveId: existingWave.id })
      : saveWave({ data: apiData, surveyId: survey.id });
  };

  useEffect(() => {
    onWaveDirtyChanged(isDirty);
  }, [isDirty, onWaveDirtyChanged]);

  const gridClassess = 'grid grid-cols-[3fr_4fr]';

  return (
    <form id="wave-form" onSubmit={handleSubmit(onSubmit)}>
      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isSavingWave}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={async () => {
            await handleSubmit(onSubmit, () => {
              onHasError();
            })();
          }}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      {submitStep === 'needsConfirmation' ? (
        <PutSurveyIntoDraftDialog
          formId="wave-form"
          isSaving={isSavingWave}
          onCloseModal={() => {
            setSubmitStep('editing');
          }}
        />
      ) : null}

      <div className="space-y-4">
        <SurveyStepStickyHeader>
          <h2 className="text-base font-semibold">Wave Details</h2>

          <div className="flex items-center gap-4">
            {existingWave ? (
              <DeleteWave
                existingWave={existingWave}
                surveyId={survey.id}
                waves={waves}
              />
            ) : null}

            <ButtonLoading
              disabled={!isDirty}
              hierarchy="primary"
              isLoading={isSavingWave}
              size="sm"
              type="submit"
            >
              {existingWave ? 'Save Changes' : 'Create Wave'}
            </ButtonLoading>
          </div>
        </SurveyStepStickyHeader>

        {!isEmpty(errors) && (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessagesRHF(errors)} />
          </div>
        )}

        <div className={gridClassess}>
          <FormLabel labelFor="title">Name</FormLabel>
          <Input {...register('title')} id="title" size="md" type="text" />
          <div className="col-start-2">
            <ErrorMessage
              errors={errors}
              name="title"
              render={({ message }) => <FormFieldError error={message} />}
            />
          </div>
        </div>
        <div className={gridClassess}>
          <FormLabel labelFor="respondents">Respondents</FormLabel>
          <Input
            {...register('respondents')}
            // Make sure we have a waveId - if we don't, the user is adding a new wave
            // and this should not be disabled.
            disabled={!!(waveId && waveId !== currentWaveId)}
            id="respondents"
            min={existingWave ? existingWave.completes : 1}
            size="md"
            type="number"
          />
          <div className="col-start-2">
            <ErrorMessage
              errors={errors}
              name="respondents"
              render={({ message }) => <FormFieldError error={message} />}
            />
          </div>
        </div>

        <div className={gridClassess}>
          <FormLabel labelFor="description">Description</FormLabel>
          <Textarea {...register('description')} id="description" size="lg" />
        </div>
      </div>
    </form>
  );
};

export default WavesStep;

const DeleteWave = ({
  existingWave,
  surveyId,
  waves,
}: {
  existingWave: SurveyWave;
  surveyId: number;
  waves: SurveyWave[];
}) => {
  const orderedWaves = orderBy(waves, (w) => w.waveValue, 'asc');
  const mostRecentWave = orderedWaves[orderedWaves.length - 1];

  const {
    isOpen: isDiscardWaveModalOpen,
    onCloseModal: onCloseDiscardWaveModal,
    setIsOpen: setDiscardWaveModalOpen,
  } = useModal();

  return (
    <>
      {waves.length > 1 && existingWave.id === mostRecentWave.id ? (
        <IconBackground
          onClick={() => {
            setDiscardWaveModalOpen(true);
          }}
        >
          <div className="w-4 h-4">
            <Icon id="trash" />
          </div>
        </IconBackground>
      ) : null}

      {isDiscardWaveModalOpen && (
        <DiscardWaveModal
          onCloseModal={onCloseDiscardWaveModal}
          surveyId={surveyId}
          wave={existingWave}
        />
      )}
    </>
  );
};

function apiDataToFormData(wave: SurveyWave): SaveWaveData {
  return {
    description: wave.description ?? '',
    respondents: wave.target,
    title: wave.title,
  };
}
