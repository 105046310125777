import { useMutation } from '@tanstack/react-query';

import {
  onboardingSetPassword,
  onboardingWelcome,
} from 'services/backend/onboarding';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export function useOnboardingSetPassword(
  opts: ReactQueryFunctionCallbacks<typeof onboardingSetPassword>,
) {
  return useMutation({ ...opts, mutationFn: onboardingSetPassword });
}

export function useOnboardingWelcome(
  opts: ReactQueryFunctionCallbacks<typeof onboardingWelcome>,
) {
  return useMutation({ ...opts, mutationFn: onboardingWelcome });
}
