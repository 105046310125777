import { useOutletContext } from 'react-router-dom';

import RemoveRespondents from './RemoveRespondents';
import SidebarNavAnalytics from './SidebarNavAnalytics';
import { SurveyLaunchedContext } from './SurveyLaunchedPage';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';

const ManageResponses = () => {
  const { surveyId } = useOutletContext<SurveyLaunchedContext>();

  return (
    <SurveyWithSidebar sidebarNav={<SidebarNavAnalytics surveyId={surveyId} />}>
      <RemoveRespondents surveyId={surveyId} />
    </SurveyWithSidebar>
  );
};

export default ManageResponses;
