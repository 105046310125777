import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  skipToken,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchUser,
  login,
  resetPassword,
  setPassword,
  updateUser,
} from 'services/backend/users';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const userQueries = {
  get: (userId: number | undefined) =>
    queryOptions({
      queryFn: userId ? () => fetchUser({ userId }) : skipToken,
      queryKey: ['users', userId],
      staleTime: minutesToMilliseconds(10),
    }),
};

export function useLogin(opts: ReactQueryFunctionCallbacks<typeof login>) {
  return useMutation({
    ...opts,
    meta: { sentryIgnoreErrorCodes: [401] },
    mutationFn: login,
  });
}

export function useResetPassword(
  opts: ReactQueryFunctionCallbacks<typeof resetPassword>,
) {
  return useMutation({ ...opts, mutationFn: resetPassword });
}

export function useSetPassword(
  opts: ReactQueryFunctionCallbacks<typeof setPassword>,
) {
  return useMutation({ ...opts, mutationFn: setPassword });
}

export function useUpdateUser(
  opts?: ReactQueryFunctionCallbacks<typeof updateUser>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: updateUser,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: userQueries.get(args[0].id).queryKey,
      });

      opts?.onSuccess?.(...args);
    },
  });
}
