import { reduce, isObject, isArray, isUndefined, isBoolean } from 'lodash-es';
import { APIError } from '../types/internal';

export type QueryObj = {
  [fieldName: string]:
    | { operator: string; value: string | number | boolean | null | undefined }
    | string[]
    | string
    | number
    | number[]
    | boolean
    | null
    | undefined;
};

export type StatusCode = 200 | 204 | 400 | 401 | 403 | 404 | 409 | 422 | 500;

/**
 * Makes an error object for an API request that failed.
 */
export function makeApiResponseError({
  json,
  statusCode,
}: {
  json: { message?: string } | undefined;
  statusCode: StatusCode;
}): APIError {
  const error = new Error(json?.message || 'Unspecified error.') as APIError;
  error.statusCode = statusCode;
  error.data = json || {};

  return error;
}

/**
 * Stringifies the provided object for use in a query string.
 */
export function queryStringify(obj: QueryObj): string {
  return reduce(
    obj,
    (result: string[], value, key) => {
      // If value is an object, its expected to have "operator" and "value" properties so we
      // can construct a query param of the form key[operator]=value.
      if (isObject(value) && !isArray(value)) {
        const { operator, value: queryParamValue } = value;
        if (isUndefined(operator) || isUndefined(queryParamValue)) {
          throw new Error(
            `Object query param ${key} must have "operator" and "value" properties.`,
          );
        }

        if (queryParamValue !== null) {
          result.push(
            `${key}[${value.operator}]=${encodeURIComponent(queryParamValue)}`,
          );
        }
      } else if (isArray(value)) {
        value.forEach((queryValue) => {
          result.push(`${key}=${encodeURIComponent(queryValue)}`);
        });
      } else if (value || value === 0 || isBoolean(value)) {
        result.push(`${key}=${encodeURIComponent(value)}`);
      }

      return result;
    },
    [],
  ).join('&');
}
