import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { clsx } from 'clsx';

type ButtonHierarchy = 'primary' | 'secondary-gray' | 'destructive';
type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
type IconPlacement = 'leading' | 'trailing';

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
  grow?: boolean;
  hierarchy?: ButtonHierarchy;
  icon?: ReactNode;
  iconPlacement?: IconPlacement;
  justification?: 'justify-between' | 'justify-center';
  size: ButtonSize;
};

const COLOR_CLASS_MAP: Record<ButtonHierarchy, string> = {
  destructive:
    'bg-error-d-600 border border-error-d-600 text-white disabled:bg-gray-d-400 disabled:border-gray-d-400',
  primary:
    'bg-primary-d-600 border border-primary-d-600 text-white disabled:bg-gray-d-400 disabled:border-gray-d-400',
  'secondary-gray':
    'bg-white border border-gray-d-300 text-gray-d-700 disabled:bg-gray-d-400 disabled:border-gray-d-400 disabled:text-white',
};

const SIZE_MAP: Record<ButtonSize, string> = {
  xs: 'px-3 py-2 text-xs font-semibold',
  sm: 'px-3.5 py-2 text-sm font-semibold',
  md: 'px-4 py-2.5 text-sm font-semibold',
  lg: 'px-4.5 py-2.5 text-md font-semibold',
};

const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  {
    children,
    grow = false,
    hierarchy = 'primary',
    icon = undefined,
    iconPlacement = undefined,
    justification = 'justify-center',
    size,
    type = 'button',
    ...rest
  },
  ref,
) {
  const iconToRender = icon ? (
    <div
      className={clsx('flex items-center justify-center', {
        'w-4 h-4': size === 'xs',
        'w-5 h-5': size !== 'xs',
      })}
    >
      {icon}
    </div>
  ) : null;

  return (
    <button
      {...rest}
      ref={ref}
      className={clsx(
        'flex items-center shadow-sm whitespace-nowrap rounded-lg gap-2 max-w-full overflow-hidden',
        justification,
        COLOR_CLASS_MAP[hierarchy],
        SIZE_MAP[size],
        { 'grow flex-1': grow },
      )}
      type={type}
    >
      {iconPlacement === 'leading' && iconToRender}
      {children}
      {iconPlacement === 'trailing' && iconToRender}
    </button>
  );
});

export default Button;
