import { capitalize } from 'lodash-es';

import { ELIGIBLE_STATUSES_FOR_CHANGE } from 'constants/surveyStatuses';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { SurveyStatusName } from '../../types/domainModels';
import { useUpdateSurveyStatus } from 'hooks/backend/surveys';

import Badge from './Badge';
import CompleteBadge from './CompleteBadge';
import Dropdown, { DropdownButton, DropdownItem } from './Dropdown';
import Icon from './Icon';

const SurveyStatusBadge = ({
  onSurveyStatusChanged,
  survey,
}: {
  onSurveyStatusChanged?(id: number): void;
  survey: {
    id: number;
    status: {
      name: SurveyStatusName;
    };
  };
}) => {
  const { mutate: updateSurveyStatus } = useUpdateSurveyStatus({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: () => {
      showSuccessMessage('Survey status updated successfully.');
      onSurveyStatusChanged?.(survey.id);
    },
  });

  const eligibleStatuses =
    ELIGIBLE_STATUSES_FOR_CHANGE[survey.status.name] ?? [];

  return (
    <Dropdown
      button={
        <DropdownButton as="button">
          {survey.status.name === 'approved' ? (
            <Badge
              color="orange"
              icon={<Icon id="lightning-01" />}
              iconPlacement="leading"
              size="md"
            >
              In Field
            </Badge>
          ) : survey.status.name === 'draft' ? (
            <Badge
              color="gray"
              icon={<Icon id="pencil" />}
              iconPlacement="leading"
              size="md"
            >
              Draft
            </Badge>
          ) : (
            <CompleteBadge />
          )}
        </DropdownButton>
      }
    >
      <div className="p-1 text-dark-grey text-xs">Change status to:</div>
      {eligibleStatuses.map((status) => {
        return (
          <DropdownItem
            key={status.id}
            as="button"
            onClick={() => {
              updateSurveyStatus({
                data: { statusId: status.id },
                surveyId: survey.id,
              });
            }}
            type="button"
          >
            {capitalize(status.name)}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};

export default SurveyStatusBadge;
