import { isAxiosError } from 'axios';

export function getErrorMessage(err: Error) {
  let message = 'An unknown error occurred. Please try again.';

  if (isAxiosError<{ message: string }>(err) && err.response?.data?.message) {
    message = err.response?.data?.message;
  } else if (err.message) {
    message = err.message;
  }

  return message;
}
