import { useField } from 'formik';

import { QuestionFormData } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';

const DisplayOptionTitle = ({ isScale = false }: { isScale?: boolean }) => {
  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');

  if (!isScale && optionType?.value !== 'image') {
    return null;
  }

  return (
    <>
      <FormCheckbox
        checkboxLabel={isScale ? 'Display Scale Title' : 'Display Option Title'}
        name="features.displayOptionTitle"
        tooltip={`${isScale ? 'Scales' : 'Options with images'} can optionally have titles. This feature enables displaying the entered title to the respondent.`}
      />
    </>
  );
};

export default DisplayOptionTitle;
