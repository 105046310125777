import { OptionType } from 'types/internal';
import { QUESTION_TYPE } from '../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../types/forms';

export interface OptionFeatureConfig {
  apiName: string;
  available(opts: {
    features: QuestionFormData['features'];
    isMatrixOption: boolean;
    isStatement: boolean;
    optionType: OptionType | undefined;
    questionType: QuestionFormData['questionType'];
  }): boolean;
  disabled(opts: {
    isMatrixOption: boolean;
    optionFeatures: QuestionFormOption['features'];
    options: QuestionFormData['options'];
  }): boolean;
  featureName: keyof QuestionFormOption['features'];
  // This will flip the display of the feature so a label is only shown if the
  // feature is disabled. For example, it's more useful to show a label that an
  // option is inactive instead of always showing that they're active (which is the default).
  inverseDisplay?: boolean;
  label: string;
  labelDisplay?: string;
}

const isAnchoredAvailable: OptionFeatureConfig['available'] = ({
  features,
  isMatrixOption,
  isStatement,
}) => {
  return (
    (isStatement && features.randomizeStatements) ||
    (isMatrixOption && features.randomizeMatrixOptions) ||
    features.randomizeOptions
  );
};

const isExclusiveAvailable: OptionFeatureConfig['available'] = ({
  features,
  isMatrixOption,
  isStatement,
  questionType,
}) => {
  if (isMatrixOption) {
    return features.multipleOptionSelections;
  }

  return (
    !isStatement &&
    questionType?.value !== QUESTION_TYPE.SCALE &&
    (questionType?.value === QUESTION_TYPE.RANKING ||
      features.multipleResponse.enabled)
  );
};

const isFreeTextDisabled: OptionFeatureConfig['disabled'] = ({
  isMatrixOption,
  optionFeatures,
  options,
}) => {
  if (isMatrixOption) {
    return (
      !optionFeatures.freeText &&
      options?.some((option) => option.features.freeText)
    );
  }

  return false;
};

const isFreeTextAvailable: OptionFeatureConfig['available'] = ({
  features,
  isMatrixOption,
  isStatement,
  questionType,
}) => {
  return (
    questionType?.value === QUESTION_TYPE.MULTIPLE_CHOICE ||
    (questionType?.value === QUESTION_TYPE.MATRIX &&
      !isStatement &&
      isMatrixOption &&
      !features.openMatrix.enabled)
  );
};

const isPreservedAvailable: OptionFeatureConfig['available'] = ({
  features,
  isMatrixOption,
}) => {
  return !isMatrixOption && features.displayXOfY.enabled;
};

const isRequiredViewAvailable: OptionFeatureConfig['available'] = ({
  features,
  optionType,
  questionType,
}) => {
  return (
    questionType?.value !== QUESTION_TYPE.MATRIX &&
    optionType === 'image' &&
    features.viewAllImages
  );
};

export const OPTION_FEATURES: OptionFeatureConfig[] = [
  {
    apiName: 'anchored',
    available: isAnchoredAvailable,
    disabled: () => false,
    featureName: 'anchored',
    label: 'Anchor',
  },
  {
    apiName: 'exclusive',
    available: isExclusiveAvailable,
    disabled: () => false,
    featureName: 'exclusive',
    label: 'Exclusive',
  },
  {
    apiName: 'isFreeTextOption',
    available: isFreeTextAvailable,
    disabled: isFreeTextDisabled,
    featureName: 'freeText',
    label: 'Free Text',
  },
  {
    apiName: 'isActive',
    available: () => true,
    disabled: () => false,
    featureName: 'isActive',
    inverseDisplay: true,
    label: 'Is Active',
    labelDisplay: 'Inactive',
  },
  {
    apiName: 'preserved',
    available: isPreservedAvailable,
    disabled: () => false,
    featureName: 'preserved',
    label: 'Preserve',
  },
  {
    apiName: 'viewRequired',
    available: isRequiredViewAvailable,
    disabled: () => false,
    featureName: 'requireView',
    label: 'Require View',
  },
];
