import { useNavigate, useParams } from 'react-router-dom';

import { showErrorMessage } from '../../util/notifications';
import { useOnboardingWelcome } from 'hooks/backend/onboarding';

import ButtonLoading from '../common/forms/ButtonLoading';
import Card from '../common/Card';
import Dots from './Dots';
import pencilImg from '../../assets/img/icon-pencil.png';
import UnauthenticatedLayout from '../layout/UnauthenticatedLayout';

const Welcome = (): JSX.Element => {
  const navigate = useNavigate();
  const { token: onboardingToken = '' } = useParams<{ token: string }>();

  const { isPending, mutate: getStarted } = useOnboardingWelcome({
    onError: (err) => {
      showErrorMessage({ err });
    },
    onSuccess: () => {
      navigate(`/onboarding/password/${onboardingToken}`);
    },
  });

  return (
    <UnauthenticatedLayout introText="Welcome to Glass! Let's get your account set up.">
      <Card>
        <div className="flex justify-center mb-4">
          <img alt="pencil" src={pencilImg} />
        </div>
        <div className="flex">
          <ButtonLoading
            grow
            hierarchy="primary"
            isLoading={isPending}
            onClick={() => {
              getStarted({ token: onboardingToken });
            }}
            size="lg"
            type="button"
          >
            Let's get started
          </ButtonLoading>
        </div>
      </Card>
      <div className="flex justify-center mt-4">
        <Dots step={1} total={2} />
      </div>
    </UnauthenticatedLayout>
  );
};

export default Welcome;
