import { FieldArray, Form, Formik, useField, useFormikContext } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { sortBy } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

import {
  apiDataToFormData,
  AUDIENCE_SOURCE_OPTIONS,
  AudienceTypeOptions as AudienceTypeOptionsType,
  DEMOGRAPHIC_QUOTA_TYPE,
  DemographicConstraint,
  DemographicFormData,
  DemographicFormDataValidated,
  getEmptyDemographicQuota,
  getEmptyQuota,
  getEmptyQuotaRangeConstraint,
  getLogicalModifierOptions,
  getValuesForAudienceTypeChange,
  isMultiSelectLogicalModifier,
  Quota as QuotaType,
  validateDemographicFormData,
} from '../../util/demographics';
import { getNestedErrorMessages } from 'util/forms';
import { getQuestionOption } from 'util/formOptions';
import { panelProviderQueries } from 'hooks/backend/panelProviders';
import { Question, Survey } from '../../types/domainModels';
import { ReactSelectValue } from 'types/forms';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import { useHasRole } from 'hooks/users';
import { useSaveAudience } from 'hooks/backend/audience';
import { useSubmitValidation } from '../../hooks/forms';

import AddButton from 'components/common/forms/AddButton';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import Checkbox from 'components/common/forms/Checkbox';
import clockPreviewImg from 'assets/img/clock-preview.png';
import FormCheckbox from 'components/common/forms/FormCheckbox';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from 'components/common/forms/FormGroup';
import FormInput from 'components/common/forms/FormInput';
import FormSearchSelectInput from 'components/common/forms/FormSearchSelectInput';
import FormSearchSelectInputV2 from '../common/forms/FormSearchSelectInputV2';
import Icon from 'components/common/Icon';
import IconBackground from 'components/common/icons/IconBackground';
import Modal from 'components/common/Modal';
import SliderToggleFormik from 'components/common/SliderToggleFormik';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabWithAlert,
} from 'components/common/Tabs';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';
import XButton from 'components/common/forms/XButton';

const AudienceStep = ({
  demographicQuestions,
  isShowingUnsavedChanges,
  onAudienceSaved,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  survey,
}: {
  demographicQuestions: Question[];
  isShowingUnsavedChanges: boolean;
  onAudienceSaved(): void;
  onClickStep?(step: SurveyFlowStep): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  sidebar?: ReactNode;
  survey: Survey;
}) => {
  const initialValues = apiDataToFormData({ demographicQuestions, survey });

  const { isPending: isSavingAudience, mutate: saveAudience } = useSaveAudience(
    {
      initialValues,
      onError: (err: Error) => {
        onHasError();
        showErrorMessage({ err });
      },
      onSuccess: () => {
        onAudienceSaved();
      },
      survey,
    },
  );

  return (
    <Formik<DemographicFormData>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(formData) => {
        saveAudience(formData as DemographicFormDataValidated);
      }}
      validate={validateDemographicFormData}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full">
        <AudienceForm
          demographicQuestions={demographicQuestions}
          isSavingAudience={isSavingAudience}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onDirtyChanged={onDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
        />
      </Form>
    </Formik>
  );
};

export default AudienceStep;

const AudienceForm = ({
  demographicQuestions,
  isSavingAudience,
  isShowingUnsavedChanges,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
}: {
  demographicQuestions: Question[];
  isSavingAudience: boolean;
  isShowingUnsavedChanges: boolean;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
}) => {
  const { dirty } = useFormikContext();

  const { errors, onClickSubmit, validateAndSubmit } =
    useSubmitValidation<DemographicFormData>({
      isSaving: isSavingAudience,
      onHasError,
    });

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  return (
    <div>
      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isSavingAudience}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={validateAndSubmit}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      <TabGroup>
        <SurveyStepStickyHeader>
          <div className="grow -mb-2">
            <TabList size="sm">
              <TabWithAlert hasAlert={!!errors?.audienceProfile}>
                Audience Profile
              </TabWithAlert>
              <Tab>Respondent Quality</Tab>
            </TabList>
          </div>

          <ButtonLoading
            disabled={!dirty}
            hierarchy="primary"
            isLoading={isSavingAudience}
            onClick={onClickSubmit}
            size="sm"
            // This can't currently be a submit button since we handle the form submission
            // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
            type="button"
          >
            Save Audience
          </ButtonLoading>
        </SurveyStepStickyHeader>

        {errors ? (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
          </div>
        ) : null}

        <TabPanels>
          <TabPanel>
            <AudienceProfile demographicQuestions={demographicQuestions} />
          </TabPanel>
          <TabPanel>
            <QualityChecksCard />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

const AudienceProfile = ({
  demographicQuestions,
}: {
  demographicQuestions: Question[];
}) => {
  const isAdmin = useHasRole('admin');

  const [{ value: audienceSource }] = useField<
    DemographicFormData['audienceProfile']['audienceSource']
  >('audienceProfile.audienceSource');
  const [{ value: panelProvider }] = useField<
    DemographicFormData['audienceProfile']['panelProvider']
  >('audienceProfile.panelProvider');

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <p className="text-gray-d-700">
          Use our integrated audience that reaches millions of potential
          respondents, or send the survey to your own audience.
        </p>
      </div>

      <div className="grid grid-cols-[300px_1fr]">
        <div>Select Source</div>
        <FormSearchSelectInputV2
          name="audienceProfile.audienceSource"
          options={AUDIENCE_SOURCE_OPTIONS}
        />
      </div>

      {audienceSource === 'sample-provider' && (
        <>
          {isAdmin && (
            <div className="grid grid-cols-[300px_1fr]">
              <div>Select Sample Provider</div>
              <FormGroup>
                <PartnerPanelProvider />
              </FormGroup>
            </div>
          )}

          {panelProvider === 'LUCID' && (
            <div className="grid grid-cols-[300px_1fr]">
              <div>Inbound Census Options</div>
              <FormGroup>
                <InboundCensusOptions />
              </FormGroup>
            </div>
          )}
        </>
      )}

      <div className="border-t border-gray-d-200 pt-6 space-y-6">
        <DefineAudienceCard />
        <BuildYourAudienceCard demographicQuestions={demographicQuestions} />
      </div>
    </div>
  );
};

const PartnerPanelProvider = () => {
  const { data: panelProviders = [] } = useQuery(panelProviderQueries.list());

  return (
    <FormSearchSelectInputV2
      name="audienceProfile.panelProvider"
      options={panelProviders}
    />
  );
};

const InboundCensusOptions = (): JSX.Element => {
  return (
    <div className="flex flex-col space-y-4">
      <FormCheckbox
        checkboxLabel="Region"
        name="audienceProfile.inboundCensus.useRegion"
      />
      <FormCheckbox
        checkboxLabel="Gender"
        name="audienceProfile.inboundCensus.useGender"
      />
      <FormCheckbox
        checkboxLabel="Hispanic"
        name="audienceProfile.inboundCensus.useEthnicity"
      />
      <FormCheckbox
        checkboxLabel="Race"
        name="audienceProfile.inboundCensus.useRace"
      />
      <FormCheckbox
        checkboxLabel="Age"
        name="audienceProfile.inboundCensus.useAge"
      />
    </div>
  );
};

const QualityToggle = ({
  subtitle,
  title,
  toggle,
}: {
  subtitle: string;
  title: string;
  toggle: ReactNode;
}) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <span className="text-gray-d-800 font-medium">{title}</span>
        {toggle}
      </div>
      <p className="text-gray-d-700">{subtitle}</p>
    </div>
  );
};

const QualityChecksCard = () => {
  const [showClockPreview, setShowClockPreview] = useState(false);

  return (
    <div>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <span className="text-gray-d-800 font-medium">Tactics</span>
          <p className="text-gray-d-700">
            Activate or de-activate tactics to detect and disqualify low-quality
            respondents. When activated, these methods will immediately
            disqualify respondents in your survey when they show behavior or
            signals indicative of low-quality respondents.
          </p>
        </div>
        <div className="space-y-3 divide-y divide-gray-d-200">
          <QualityToggle
            subtitle="Assesses respondent for common bot and spam tactics, such as IP masking."
            title="Bad Respondent Detection"
            toggle={
              <SliderToggleFormik name="respondentQuality.qualityChecks.bad_respondent" />
            }
          />
          <div className="pt-3">
            <QualityToggle
              subtitle="Assesses whether the respondent has already taken the survey."
              title="Duplicate Detection"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.duplicate" />
              }
            />
          </div>
          <div className="pt-3">
            <QualityToggle
              subtitle="Adds a question prior to the survey to detect low-quality respondents."
              title="Pre-survey Screening Questions"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.presurvey_questionnaire" />
              }
            />

            <button
              className="mt-1 flex items-center gap-2 text-primary-d-600 hover:underline"
              onClick={() => {
                setShowClockPreview(true);
              }}
              type="button"
            >
              View Screening Question
              <div className="w-4 h-4">
                <Icon id="link-external-02" />
              </div>
            </button>
          </div>
          <div className="pt-3">
            <QualityToggle
              subtitle="Detects whether respondents are pasting text into open-end or free-form text fields."
              title="Copy/Paste Detection"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.copy_paste" />
              }
            />
          </div>
        </div>
      </div>

      {showClockPreview && (
        <Modal
          onCloseModal={() => {
            setShowClockPreview(false);
          }}
          position="center"
        >
          <img className="w-full h-full" src={clockPreviewImg} />
        </Modal>
      )}
    </div>
  );
};

const DefineAudienceCard = () => {
  const { setValues, values } = useFormikContext<DemographicFormData>();

  return (
    <div className="grid grid-cols-[300px_1fr]">
      <div>Who do you want to talk to?</div>
      <FormGroup>
        <AudienceTypeOptions
          onChange={(value) => {
            setValues(
              getValuesForAudienceTypeChange({
                formData: values,
                newAudienceType: value,
              }),
            );
          }}
          value={values.audienceProfile.audienceType}
        />
      </FormGroup>
    </div>
  );
};

const BuildYourAudienceCard = ({
  demographicQuestions,
}: {
  demographicQuestions: Question[];
}): JSX.Element | null => {
  const [{ value: audienceType }] = useField<AudienceTypeOptionsType>(
    'audienceProfile.audienceType',
  );

  if (audienceType !== 'custom') {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-[300px_1fr]">
        <div>Audience Title</div>
        <FormInput
          id="title"
          labelFor="title"
          name="audienceProfile.title"
          placeholder="ex: Age Range"
          size="md"
          type="text"
        />
      </div>
      <div className="grid grid-cols-[300px_1fr]">
        <div>Audience Quotas</div>
        <div className="max-w-[500px]">
          <DemographicQuotas demographicQuestions={demographicQuestions} />
        </div>
      </div>
    </>
  );
};

const AudienceTypeOptions = ({
  onChange,
  value,
}: {
  onChange(value: AudienceTypeOptionsType): void;
  value: AudienceTypeOptionsType;
}): JSX.Element => {
  return (
    <div className="space-y-2 text-sm">
      <AudienceTypeOption
        currentValue={value}
        label="Natural Fallout"
        onChange={onChange}
        optionValue="natural"
      />
      <AudienceTypeOption
        currentValue={value}
        label="Custom Audience"
        onChange={onChange}
        optionValue="custom"
      />
    </div>
  );
};

const AudienceTypeOption = ({
  currentValue,
  label,
  onChange,
  optionValue,
}: {
  currentValue: AudienceTypeOptionsType;
  label: string;
  onChange(value: AudienceTypeOptionsType): void;
  optionValue: AudienceTypeOptionsType;
}): JSX.Element => {
  return (
    <div className="flex">
      <Checkbox
        checked={currentValue === optionValue}
        label={label}
        name="audienceProfile.audienceType"
        onChange={(event) => {
          if (event.currentTarget.checked) {
            onChange(optionValue);
          }
        }}
        radio={true}
      />
    </div>
  );
};

const DemographicQuotas = ({
  demographicQuestions,
}: {
  demographicQuestions: Question[];
}): JSX.Element => {
  const [demographicQuotas] = useField<
    DemographicFormData['audienceProfile']['demographicQuotas']
  >('audienceProfile.demographicQuotas');

  return (
    <FieldArray
      name="audienceProfile.demographicQuotas"
      render={(arrayHelpers) => {
        return (
          <div>
            {demographicQuotas.value.map((_quota, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col rounded-lg space-y-2 bg-gray-100 p-4 mb-6"
                >
                  <div className="text-xs font-medium text-gray-900 mb-2">
                    Audience Quota {index + 1}
                  </div>
                  <DemographicQuota
                    demographicQuestions={demographicQuestions}
                    index={index}
                    onRemoveCategory={() => {
                      if (demographicQuotas.value.length > 1) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.replace(index, getEmptyDemographicQuota());
                      }
                    }}
                  />
                </div>
              );
            })}

            <div className="flex">
              <AddButton
                label="Add Audience Quota"
                onClick={() => {
                  arrayHelpers.push(getEmptyDemographicQuota());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const DemographicQuota = ({
  demographicQuestions,
  index,
  onRemoveCategory,
}: {
  demographicQuestions: Question[];
  index: number;
  onRemoveCategory?: () => void;
}): JSX.Element => {
  const demographicFieldName = `audienceProfile.demographicQuotas.${index}.demographic`;
  const quotasFieldName = `audienceProfile.demographicQuotas.${index}.quotas`;
  const [{ value: demographic }] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['demographic']
    >(demographicFieldName);
  const [, , quotasHelpers] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['quotas']
    >(quotasFieldName);

  return (
    <>
      <div className="flex">
        <div className="w-80 mr-2">
          <FormSearchSelectInput
            borderColor="#D9D9D9"
            name={demographicFieldName}
            onChange={(newDemographic) => {
              if (
                !newDemographic ||
                newDemographic.value.id === demographic?.value.id
              ) {
                return;
              }

              quotasHelpers.setError('');
              quotasHelpers.setValue([getEmptyQuota(newDemographic.value)]);
            }}
            options={sortBy(demographicQuestions, (q) => q.sort).map(
              (question) => {
                return getQuestionOption({ question });
              },
            )}
            placeholder="Search for a demographic..."
          />
        </div>
        {onRemoveCategory && (
          <div className="mt-2">
            <IconBackground
              onClick={onRemoveCategory}
              size="small"
              title="Delete Quota"
            >
              <div className="w-3 h-3">
                <Icon id="trash" />
              </div>
            </IconBackground>
          </div>
        )}
      </div>
      {demographic && (
        <>
          <div className="my-2 text-sm italic">
            Your audience will be asked: "{demographic.value.title}"
          </div>
          <Quotas demographic={demographic} index={index} />
        </>
      )}
    </>
  );
};

const Quotas = ({
  demographic,
  index,
}: {
  demographic: ReactSelectValue<Question>;
  index: number;
}): JSX.Element => {
  const quotasFieldName = `audienceProfile.demographicQuotas.${index}.quotas`;
  const [{ value: quotas }, quotasMeta] =
    useField<
      DemographicFormData['audienceProfile']['demographicQuotas'][number]['quotas']
    >(quotasFieldName);

  return (
    <FieldArray
      name={quotasFieldName}
      render={(arrayHelpers) => {
        return (
          <div>
            <div>
              {quotas.map((_quota, index) => {
                return (
                  <Quota
                    key={index}
                    demographic={demographic}
                    index={index}
                    namePrefix={quotasFieldName}
                    onClickRemoveQuota={() => {
                      if (quotas.length > 1) {
                        arrayHelpers.remove(index);
                      } else {
                        arrayHelpers.replace(
                          index,
                          getEmptyQuota(demographic.value),
                        );
                      }
                    }}
                  />
                );
              })}
            </div>
            {/* This is a top-level error for all quotas like the percentages not adding to 100%. */}
            {typeof quotasMeta.error === 'string' && (
              <FormFieldError error={quotasMeta.error} />
            )}
            <div className="flex mt-2">
              <AddButton
                label="Add Another Quota"
                onClick={() => {
                  arrayHelpers.push(getEmptyQuota(demographic.value));
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const Quota = ({
  demographic,
  index,
  namePrefix,
  onClickRemoveQuota,
}: {
  demographic: ReactSelectValue<Question>;
  index: number;
  namePrefix: string;
  onClickRemoveQuota?: () => void;
}): JSX.Element => {
  const quotaFieldName = `${namePrefix}.${index}`;
  const options = getLogicalModifierOptions(demographic.value);

  return (
    <div className="flex flex-col p-2 space-y-4">
      <div className="text-xs font-medium text-gray-900 flex flex-row justify-between">
        <div>Quota {index + 1}</div>
        {onClickRemoveQuota && (
          <div
            className="font-normal text-red underline cursor-pointer"
            onClick={onClickRemoveQuota}
          >
            Delete this quota
          </div>
        )}
      </div>

      <div className="flex flex-row items-start w-full space-x-4">
        <div className="flex-1">
          <FormInput
            icon="%"
            iconPlacement="trailing"
            max={100}
            min={0}
            name={`${quotaFieldName}.percentage`}
            size="md"
            type="number"
          />
        </div>
        <div className="flex-1">
          <FormSearchSelectInput
            borderColor="#D9D9D9"
            name={`${quotaFieldName}.logicalModifier`}
            options={options}
            placeholder="Select condition"
          />
        </div>
      </div>

      <div className="flex-1">
        <QuotaConstraints
          demographic={demographic}
          namePrefix={quotaFieldName}
        />
      </div>
    </div>
  );
};

const QuotaConstraints = ({
  demographic,
  namePrefix,
}: {
  demographic: ReactSelectValue<Question>;
  namePrefix: string;
}): JSX.Element | null => {
  const constraintsFieldName = `${namePrefix}.constraints`;
  const [{ value: constraints }] =
    useField<DemographicConstraint[]>(constraintsFieldName);
  const [{ value: logicalModifier }] = useField<QuotaType['logicalModifier']>(
    `${namePrefix}.logicalModifier`,
  );

  if (demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.ENUM) {
    return (
      <FormSearchSelectInput
        borderColor="#D9D9D9"
        isMulti={
          logicalModifier
            ? isMultiSelectLogicalModifier(logicalModifier.value)
            : false
        }
        name={constraintsFieldName}
        options={sortBy(demographic.value.options, (o) => o.sort).map(
          (constraint) => {
            return {
              label: constraint.title,
              value: constraint,
            };
          },
        )}
      />
    );
  } else if (
    demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.RANGE ||
    demographic.value.questionTypeId === DEMOGRAPHIC_QUOTA_TYPE.OPEN_END
  ) {
    return (
      <FieldArray
        name={constraintsFieldName}
        render={(arrayHelpers) => {
          return (
            <div>
              <div className="space-y-2">
                {constraints.map((_constraint, index) => {
                  return (
                    <div key={index} className="flex space-x-2">
                      <div>
                        <FormInput
                          name={`${constraintsFieldName}.${index}.start`}
                          size="md"
                          type="number"
                        />
                      </div>
                      <span className="flex-shrink-0 mt-1 mx-4 text-sm">
                        and
                      </span>
                      <div>
                        <FormInput
                          name={`${constraintsFieldName}.${index}.end`}
                          size="md"
                          type="number"
                        />
                      </div>
                      {constraints.length > 1 && (
                        <div className="mt-2">
                          <XButton
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            title="Remove"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="flex mt-1">
                <AddButton
                  label={
                    logicalModifier?.value === 'should' ||
                    logicalModifier?.value === 'shouldnt'
                      ? 'or'
                      : 'and'
                  }
                  onClick={() => {
                    arrayHelpers.push(getEmptyQuotaRangeConstraint());
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    );
  }

  return null;
};
