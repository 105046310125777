import axios from 'axios';

import {
  EndMessage,
  PanelProvider,
  RemoveRespondentsReason,
  Survey,
  SurveyAudienceSlice,
  SurveyButtonText,
  SurveyComplete,
  SurveyIncidence,
  SurveyPayment,
  SurveyQualityCheck,
  SurveyTemplate,
  SurveyWave,
} from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';
import { queryStringify } from '../../util/api';

export interface CreateSurveyBody {
  audienceId?: number;
  incidenceTypeId: number;
  organizationId: number;
  ownerId?: number;
  participants: number;
  projectId: number | null;
  statusId?: number;
  title: string;
}

export interface UpdateInboundCensusBody {
  useAgeCensus: boolean;
  useEthnicityCensus: boolean;
  useGenderCensus: boolean;
  useRaceCensus: boolean;
  useRegionCensus: boolean;
}

export const CLONE_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}:clone`,
  version: 'v2' as const,
});

export async function cloneSurvey({ surveyId }: { surveyId: number }) {
  return (
    await axios.post<{ id: number }>(getAPIUrl(CLONE_SURVEY({ surveyId })))
  ).data;
}

export const CLONE_SURVEY_IN_LUCID = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/lucid-clone/${surveyId}`,
  version: 'v1' as const,
});

export async function cloneSurveyInLucid({ surveyId }: { surveyId: number }) {
  return (
    await axios.post<void>(getAPIUrl(CLONE_SURVEY_IN_LUCID({ surveyId })))
  ).data;
}

export const CREATE_SURVEY = () => ({
  path: '/surveys',
  version: 'v1' as const,
});

export async function createSurvey({ data }: { data: CreateSurveyBody }) {
  return (
    await axios.post<Survey>(getAPIUrl(CREATE_SURVEY()), {
      ...data,
      useScreener: true,
    })
  ).data;
}

export const CREATE_SURVEY_FROM_TEMPLATE = ({
  surveyId,
}: {
  surveyId: number;
}) => ({
  path: `/surveys/${surveyId}:cloneFromTemplate`,
  version: 'v2' as const,
});

export async function createSurveyFromTemplate({
  data,
  surveyId,
}: {
  data: {
    organizationId?: number;
    ownerId?: number;
    title: string;
  };
  surveyId: number;
}) {
  return (
    await axios.post<{ id: number }>(
      getAPIUrl(CREATE_SURVEY_FROM_TEMPLATE({ surveyId })),
      data,
    )
  ).data;
}

export const CREATE_SURVEY_TEMPLATE = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/templates`,
  version: 'v2' as const,
});

export async function createSurveyTemplate({
  data,
  surveyId,
}: {
  data: {
    description: string;
    organizationId?: number;
    ownerId?: number;
    title: string;
  };
  surveyId: number;
}) {
  return (
    await axios.post<{ id: number }>(
      getAPIUrl(CREATE_SURVEY_TEMPLATE({ surveyId })),
      data,
    )
  ).data;
}

export const CREATE_WAVE = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/wave`,
  version: 'v1' as const,
});

export async function createWave({
  data,
  surveyId,
}: {
  data: {
    description: string;
    target: number;
    title: string;
  };
  surveyId: number;
}) {
  return (
    await axios.post<{ wave: SurveyWave }>(
      getAPIUrl(CREATE_WAVE({ surveyId })),
      data,
    )
  ).data;
}

export const DELETE_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/set-deleted-status`,
  version: 'v1' as const,
});

export async function deleteSurvey({ surveyId }: { surveyId: number }) {
  return (await axios.patch<void>(getAPIUrl(DELETE_SURVEY({ surveyId })), {}))
    .data;
}

export const DELETE_WAVE = ({ waveId }: { waveId: number }) => ({
  path: `/surveys/wave/${waveId}`,
  version: 'v1' as const,
});

export async function deleteWave({ waveId }: { waveId: number }) {
  return (await axios.delete<void>(getAPIUrl(DELETE_WAVE({ waveId })))).data;
}

export const EDIT_WAVE = ({ waveId }: { waveId: number }) => ({
  path: `/surveys/wave/${waveId}`,
  version: 'v1' as const,
});

export async function editWave({
  data,
  waveId,
}: {
  data: {
    description: string;
    target: number;
    title: string;
  };
  waveId: number;
}) {
  return (
    await axios.patch<{ wave: SurveyWave }>(
      getAPIUrl(EDIT_WAVE({ waveId })),
      data,
    )
  ).data;
}

export const FETCH_INCIDENCE = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/incidence`,
  version: 'v2' as const,
});

export async function fetchIncidence({
  surveyId,
  waveId,
}: {
  surveyId: number;
  waveId: number;
}) {
  return (
    await axios.get<SurveyIncidence>(getAPIUrl(FETCH_INCIDENCE({ surveyId })), {
      params: { waveId },
    })
  ).data;
}

export const FETCH_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}`,
  version: 'v2' as const,
});

export async function fetchSurvey({ surveyId }: { surveyId: number }) {
  return (await axios.get<Survey>(getAPIUrl(FETCH_SURVEY({ surveyId })))).data;
}

export const FETCH_SURVEY_AUDIENCE_SLICES = ({
  surveyId,
}: {
  surveyId: number;
}) => ({
  path: `/surveys/audience-slices/${surveyId}`,
  version: 'v1' as const,
});

export async function fetchSurveyAudienceSlices({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds: number[];
}) {
  return (
    await axios.get<SurveyAudienceSlice[]>(
      getAPIUrl(FETCH_SURVEY_AUDIENCE_SLICES({ surveyId })),
      {
        params: { waveIds },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}

export const FETCH_SURVEY_COMPLETES = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/get-completes/${surveyId}`,
  version: 'v1' as const,
});

export async function fetchSurveyCompletes({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds: number[];
}) {
  return (
    await axios.get<SurveyComplete[]>(
      getAPIUrl(FETCH_SURVEY_COMPLETES({ surveyId })),
      {
        params: { waveIds },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}

export const FETCH_SURVEY_TEMPLATES = () => ({
  path: '/surveys/templates',
  version: 'v1' as const,
});

export async function fetchSurveyTemplates() {
  return (
    await axios.get<SurveyTemplate[]>(getAPIUrl(FETCH_SURVEY_TEMPLATES()))
  ).data;
}

export const FETCH_WAVES = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/waves`,
  version: 'v1' as const,
});

export async function fetchWaves({ surveyId }: { surveyId: number }) {
  return (
    await axios.get<{ waves: SurveyWave[] }>(
      getAPIUrl(FETCH_WAVES({ surveyId })),
    )
  ).data;
}

export const GET_SURVEY_COST = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/cost`,
  version: 'v2' as const,
});

export async function getSurveyCost({ surveyId }: { surveyId: number }) {
  return (
    await axios.get<{ cost: number }>(getAPIUrl(GET_SURVEY_COST({ surveyId })))
  ).data;
}

export const LAUNCH_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}:launch`,
  version: 'v2' as const,
});

export async function launchSurvey({
  data,
  surveyId,
}: {
  data: {
    amountOwed: number;
    payment?:
      | {
          email: string;
          stripeToken: string;
          type: 'creditCard';
        }
      | { purchaseOrder: string; type: 'invoice' };
  };
  surveyId: number;
}) {
  return (await axios.post<void>(getAPIUrl(LAUNCH_SURVEY({ surveyId })), data))
    .data;
}

export const LIST_PAYMENTS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/payments`,
  version: 'v2' as const,
});

export async function listPayments({ surveyId }: { surveyId: number }) {
  return (
    await axios.get<SurveyPayment[]>(getAPIUrl(LIST_PAYMENTS({ surveyId })))
  ).data;
}

export const REMOVE_RESPONDENTS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/respondents:remove`,
  version: 'v1' as const,
});

export type RemovalGroup = {
  reason: RemoveRespondentsReason;
  userIds: number[];
};

export async function removeRespondents({
  data,
  surveyId,
}: {
  data: { groups: RemovalGroup[] };
  surveyId: number;
}) {
  return (
    await axios.post<Record<string, unknown>>(
      getAPIUrl(REMOVE_RESPONDENTS({ surveyId })),
      data,
    )
  ).data;
}

export const UPDATE_INBOUND_CENSUS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/use-inbound-census/${surveyId}`,
  version: 'v1' as const,
});

export async function updateInboundCensus({
  data,
  surveyId,
}: {
  data: UpdateInboundCensusBody;
  surveyId: number;
}) {
  return (
    await axios.patch<void>(
      getAPIUrl(UPDATE_INBOUND_CENSUS({ surveyId })),
      data,
    )
  ).data;
}

export const RELAUNCH_EXTERNAL_SURVEY = ({
  surveyId,
}: {
  surveyId: number;
}) => ({
  path: `/surveys/${surveyId}/relaunch-external`,
  version: 'v1' as const,
});

export async function relaunchExternalSurvey({
  surveyId,
}: {
  surveyId: number;
}) {
  return (
    await axios.post<void>(getAPIUrl(RELAUNCH_EXTERNAL_SURVEY({ surveyId })))
  ).data;
}

export const UPDATE_SURVEY = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}`,
  version: 'v1' as const,
});

export type UpdateSurveyRequestData = Partial<
  CreateSurveyBody & {
    customizations: {
      buttonText: SurveyButtonText;
      endMessages: EndMessage[];
    };
    isBringYourOwnAudience: boolean;
    panelProvider: PanelProvider | null;
    qualityChecks: SurveyQualityCheck[];
    statusId: number;
  }
>;

export async function updateSurvey({
  data,
  surveyId,
}: {
  data: UpdateSurveyRequestData;
  surveyId: number;
}) {
  return (
    await axios.patch<Survey>(getAPIUrl(UPDATE_SURVEY({ surveyId })), data)
  ).data;
}

export const UPDATE_SURVEY_STATUS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/set-status/${surveyId}`,
  version: 'v1' as const,
});

export async function updateSurveyStatus({
  data,
  surveyId,
}: {
  data: { statusId: 1 | 6 | 7 };
  surveyId: number;
}) {
  return (
    await axios.patch<void>(getAPIUrl(UPDATE_SURVEY_STATUS({ surveyId })), data)
  ).data;
}
