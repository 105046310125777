import { SVGAttributes } from 'react';

// This is a list of icons available in our public/sprite.svg file. Each string
// here corresponds to a symbol id in that file.
type IconID =
  | 'alert-circle'
  | 'alert-triangle'
  | 'archive'
  | 'arrow-circle-left'
  | 'arrow-circle-right'
  | 'arrow-narrow-left'
  | 'bar-chart-10'
  | 'check'
  | 'check-square-broken'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'clipboard-download'
  | 'cloud-download'
  | 'concept-illustration'
  | 'copy-02'
  | 'copy-06'
  | 'crop-01'
  | 'dots-vertical'
  | 'download'
  | 'download-cloud-01'
  | 'dotpoints-01'
  | 'edit'
  | 'eye'
  | 'file-07'
  | 'file-plus-03'
  | 'file-question-02'
  | 'filter-funnel-01'
  | 'heavy-green-arrow'
  | 'help-circle'
  | 'info-circle'
  | 'layout-right'
  | 'lightning-01'
  | 'link-external-02'
  | 'log-out-01'
  | 'pencil'
  | 'plus'
  | 'respondent-group'
  | 'rocket'
  | 'save-01'
  | 'search-lg'
  | 'settings-01'
  | 'slash-circle-01'
  | 'table'
  | 'tag-01'
  | 'trash'
  | 'upload-01'
  | 'user-check-01'
  | 'user-circle'
  | 'users-01'
  | 'wave'
  | 'x';

const Icon = ({
  id,
  ...rest
}: Omit<SVGAttributes<SVGElement>, 'className'> & { id: IconID }) => {
  return (
    <svg {...rest} className="w-full h-full">
      <use href={`/sprite.svg#${id}`} />
    </svg>
  );
};

export default Icon;
