import { useOutletContext } from 'react-router-dom';

import CrosstabBuilder from './CrosstabBuilder';
import SidebarNavAnalytics from './SidebarNavAnalytics';
import { SurveyLaunchedContext } from './SurveyLaunchedPage';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';

export const Crosstab = () => {
  const { questions, survey, surveyId, waves } =
    useOutletContext<SurveyLaunchedContext>();

  return (
    <SurveyWithSidebar sidebarNav={<SidebarNavAnalytics surveyId={surveyId} />}>
      {survey && (
        <CrosstabBuilder questions={questions} survey={survey} waves={waves} />
      )}
    </SurveyWithSidebar>
  );
};
