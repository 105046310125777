import { useNavigate, useOutletContext } from 'react-router-dom';
import { useState } from 'react';

import { getSurveyWaveTitle, orderWaves } from 'util/surveyWaves';
import { showErrorMessage } from 'util/notifications';
import {
  useCreateRawDataExport,
  useCreateSPSSExport,
  useGenerateSummary,
} from 'hooks/backend/exports';
import { useHasRole } from 'hooks/users';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Checkbox from 'components/common/forms/Checkbox';
import QuestionnaireDownload from 'components/common/QuestionnaireDownload';
import Select from 'components/common/forms/Select';
import { SurveyLaunchedContext } from './SurveyLaunchedPage';
import SidebarNavAnalytics from './SidebarNavAnalytics';
import SurveyStepStickyHeader from 'components/surveyEdit/SurveyStepStickyHeader';
import SurveyWithSidebar from 'components/layout/SurveyWithSidebar';

const SummaryReports = () => {
  const { survey, surveyId, waves } = useOutletContext<SurveyLaunchedContext>();

  const navigate = useNavigate();
  const isAdmin = useHasRole('admin');

  const [includeRemovedRaw, setIncludeRemovedRaw] = useState(false);
  const [waveIdsFilter, setWaveIdsFilter] = useState<number[]>([]);

  const { isPending: isLoadingSummary, mutate: generateSummary } =
    useGenerateSummary({
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: () => {
        navigate(`/surveys/${surveyId}/analyze/exports`);
      },
      surveyId,
    });

  const { isPending: isCreatingRawDataExport, mutate: createRawDataExport } =
    useCreateRawDataExport({
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: () => {
        navigate(`/surveys/${surveyId}/analyze/exports`);
      },
    });

  const { isPending: isCreatingSPSSExport, mutate: createSPSSExport } =
    useCreateSPSSExport({
      onSuccess: () => {
        navigate(`/surveys/${surveyId}/analyze/exports`);
      },
    });

  const hasWaveFilter = waves.length > 1;
  const waveFilterOptions = orderWaves(waves).map((wave) => {
    return { label: getSurveyWaveTitle(wave), value: wave.id };
  });
  const waveIds =
    waveIdsFilter.length > 0 ? waveIdsFilter : waves.map((wave) => wave.id);

  return (
    <div className="w-1/2">
      <SurveyWithSidebar
        sidebarNav={<SidebarNavAnalytics surveyId={surveyId} />}
      >
        <SurveyStepStickyHeader>
          <h2 className="font-semibold text-base">Summary Reports</h2>
        </SurveyStepStickyHeader>

        {survey && (
          <div className="space-y-6 divide-y divide-gray-d-200">
            <div>
              <h3 className="font-semibold">Questionnaire</h3>
              <QuestionnaireDownload survey={survey} />
            </div>
            {hasWaveFilter ? (
              <div className="pt-6 flex gap-4">
                <span className="font-semibold shrink-0">
                  Filter Exports by Wave:
                </span>
                <div className="grow">
                  <Select
                    isMulti
                    onChange={(newOptions) => {
                      setWaveIdsFilter(
                        newOptions.map((option) => option.value),
                      );
                    }}
                    options={waveFilterOptions}
                    value={waveFilterOptions.filter((wave) =>
                      waveIdsFilter.includes(wave.value),
                    )}
                  />
                </div>
              </div>
            ) : null}
            <div className="pt-6 grid grid-cols-[1fr_100px] gap-12">
              <div>
                <h3 className="font-semibold">Raw Data</h3>
                <p>
                  Individual respondent data for every question. Also used for
                  open ended response analysis.
                </p>

                {isAdmin && (
                  <label className="mt-2 grid grid-cols-[max-content,1fr] gap-2 items-start">
                    <div className="mt-1">
                      <Checkbox
                        checked={includeRemovedRaw}
                        name="include-removed-raw"
                        onChange={(event) => {
                          setIncludeRemovedRaw(event.target.checked);
                        }}
                        tag="div"
                      />
                    </div>

                    <div>
                      <div>Include Removed Respondents</div>
                      <div className="text-sm text-gray-d-600">
                        Including removed respondents will include all
                        respondents, including those who have been removed from
                        the survey.
                      </div>
                    </div>
                  </label>
                )}
              </div>
              <div className="justify-self-end">
                <ButtonLoading
                  hierarchy="secondary-gray"
                  isLoading={isCreatingRawDataExport}
                  onClick={() => {
                    createRawDataExport({
                      respondentType: includeRemovedRaw ? 'all' : 'active',
                      surveyId,
                      waveIds,
                    });
                  }}
                  size="md"
                  type="button"
                >
                  Export
                </ButtonLoading>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_100px] gap-6 pt-6">
              <div>
                <h3 className="font-semibold">SPSS</h3>
                <p>Formatted for SPSS use.</p>
              </div>
              <div className="justify-self-end">
                <ButtonLoading
                  hierarchy="secondary-gray"
                  isLoading={isCreatingSPSSExport}
                  onClick={() => {
                    createSPSSExport({ surveyId, waveIds });
                  }}
                  size="md"
                  type="button"
                >
                  Export
                </ButtonLoading>
              </div>
            </div>
            <div className="grid grid-cols-[1fr_100px] gap-6 pt-6">
              <div>
                <h3 className="font-semibold">Survey Summary</h3>
                <p>Simple question-by-question summary of data results.</p>
              </div>
              <div className="justify-self-end">
                <ButtonLoading
                  hierarchy="secondary-gray"
                  isLoading={isLoadingSummary}
                  onClick={() => {
                    generateSummary({ survey, waveIds });
                  }}
                  size="md"
                  type="button"
                >
                  Export
                </ButtonLoading>
              </div>
            </div>
          </div>
        )}
      </SurveyWithSidebar>
    </div>
  );
};

export default SummaryReports;
