import { useMutation } from '@tanstack/react-query';

import { ReactQueryFunctionCallbacks } from 'types/internal';
import { validateRespondentIds } from 'services/backend/respondents';

export function useValidateRespondentIds(
  opts?: ReactQueryFunctionCallbacks<typeof validateRespondentIds>,
) {
  return useMutation({ ...opts, mutationFn: validateRespondentIds });
}
