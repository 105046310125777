import Button from 'components/common/forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import Modal, { ModalHeader } from 'components/common/Modal';

const PutSurveyIntoDraftDialog = ({
  formId,
  isSaving,
  onCloseModal,
}: {
  formId: string;
  isSaving: boolean;
  onCloseModal(): void;
}) => {
  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Put Survey into Draft
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
    >
      <p>
        Saving these changes will stop response collection. You will need to
        re-launch your survey to continue collecting respondents.
      </p>

      <div className="flex flex-row-reverse mt-8 gap-4">
        <ButtonLoading
          form={formId}
          grow
          hierarchy="primary"
          isLoading={isSaving}
          size="lg"
          type="submit"
        >
          Save Changes
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default PutSurveyIntoDraftDialog;
