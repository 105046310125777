import { clsx } from 'clsx';
import { ReactNode, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DATE_FORMATS, formatDate } from '../../util/dates';
import { Export } from '../../types/domainModels';
import {
  exportQueries,
  useDownloadExport,
  useEditExport,
} from 'hooks/backend/exports';
import { getExportFilename } from 'util/exports';
import { showErrorMessage } from 'util/notifications';

import Badge from 'components/common/Badge';
import Checkbox from 'components/common/forms/Checkbox';
import CircleLoader from 'components/common/CircleLoader';
import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import ErrorDisplay from 'components/common/ErrorDisplay';
import Icon from 'components/common/Icon';
import VerticalDotsButton from 'components/common/VerticalDotsButton';

const ExportsTable = ({
  surveyHash,
  surveyTitle,
}: {
  surveyHash: string;
  surveyTitle: string;
}) => {
  const [showArchived, setShowArchived] = useState(false);

  const {
    data: surveyExports = [],
    isLoading: isLoadingExports,
    isError: hasLoadExportsError,
    error: exportsError,
  } = useQuery({
    ...exportQueries.surveyExports({ showArchived, surveyHash }),
    refetchInterval(query) {
      const hasProcessingExport = !!query.state.data?.some(
        (surveyExport) =>
          surveyExport.status === 'PENDING' ||
          surveyExport.status === 'IN_PROGRESS',
      );

      return hasProcessingExport ? 3000 : false;
    },
  });

  return (
    <div>
      <div className="pb-4">
        <Checkbox
          checked={showArchived}
          label="Show archived"
          name="show-archived"
          onChange={(e) => {
            setShowArchived(e.target.checked);
          }}
        />
      </div>
      <div className="bg-white py-2 border-b border-gray-300 text-gray-600 font-normal rounded-t-lg">
        <ExportTableGrid
          createdBy="Created By"
          crosstabName="Crosstab Name"
          dateCreated="Date Created"
          exportType="Export Type"
          fileName="File Name"
          status="Status"
        />
      </div>
      <div className="space-y-2">
        {isLoadingExports ? (
          <ExportTablePlaceholderRows withPulse={true} />
        ) : hasLoadExportsError ? (
          <ErrorDisplay
            message={`Failed to load exports. ${exportsError.message}`}
          />
        ) : surveyExports.length > 0 ? (
          surveyExports.map((surveyExport) => (
            <div key={surveyExport.id}>
              <ExportRow
                surveyExport={surveyExport}
                surveyTitle={surveyTitle}
              />
            </div>
          ))
        ) : (
          <div className="py-4">
            <p>No exports have been created for the survey.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExportsTable;

const ExportTableGrid = ({
  actions,
  createdBy,
  crosstabName,
  dateCreated,
  exportType,
  fileName,
  status,
}: {
  actions?: ReactNode;
  createdBy: ReactNode;
  crosstabName: ReactNode;
  dateCreated: ReactNode;
  exportType: ReactNode;
  fileName: ReactNode;
  status: ReactNode;
}) => {
  return (
    <div className="grid grid-cols-[30%_10%_20%_12%_12%_1fr_50px] gap-2">
      <div>{fileName}</div>
      <div>{exportType}</div>
      <div>{crosstabName}</div>
      <div>{dateCreated}</div>
      <div>{createdBy}</div>
      <div className="justify-self-center">{status}</div>
      <div className="justify-self-center">{actions}</div>
    </div>
  );
};

const ExportTablePlaceholderRows = ({ withPulse }: { withPulse: boolean }) => {
  const rows = [1, 2, 3, 4];

  return rows.map((rowNum) => (
    <div
      key={rowNum}
      className={clsx('p-4 border-b border-gray-300', {
        'animate-pulse': withPulse,
      })}
    >
      <ExportTableGrid
        createdBy={<div className="w-3/4 h-4 bg-light-grey rounded" />}
        crosstabName={<div className="w-3/4 h-4 bg-light-grey rounded" />}
        dateCreated={<div className="w-2/3 h-4 bg-light-grey rounded" />}
        exportType={<div className="w-2/3 h-4 bg-light-grey rounded" />}
        fileName={<div className="w-3/4 h-4 bg-light-grey rounded" />}
        status={<div className="w-6 h-6 bg-light-grey rounded-full" />}
      />
    </div>
  ));
};

const EXPORT_TYPE_TO_DISPLAY_NAME = {
  CROSSTAB: 'Crosstab',
  RAW_DATA: 'Raw Data',
  SPSS: 'SPSS',
} satisfies Record<Export['exportType'], string>;

const ExportRow = ({
  surveyExport,
  surveyTitle,
}: {
  surveyExport: Export;
  surveyTitle: string;
}) => {
  const filename = getExportFilename({ surveyExport, surveyTitle });

  return (
    <div className="p-4 border-b border-gray-300">
      <ExportTableGrid
        actions={<ExportRowActions surveyExport={surveyExport} />}
        createdBy={`${surveyExport.creator.firstName} ${surveyExport.creator.lastName}`}
        crosstabName={surveyExport.crosstabName}
        dateCreated={formatDate(surveyExport.createdAt, {
          format: DATE_FORMATS.DATETIME,
        })}
        exportType={
          EXPORT_TYPE_TO_DISPLAY_NAME[surveyExport.exportType] ??
          surveyExport.exportType
        }
        fileName={
          <ExportRowFilename
            displayStatus={surveyExport.displayStatus}
            filename={filename}
          />
        }
        status={
          <div className="text-gray-900 flex">
            <StatusDisplay
              exportId={surveyExport.id}
              filename={filename}
              status={surveyExport.status}
            />
          </div>
        }
      />
    </div>
  );
};

const ExportRowFilename = ({
  displayStatus,
  filename,
}: {
  displayStatus: Export['displayStatus'];
  filename: string;
}) => {
  if (displayStatus === 'ARCHIVED') {
    return (
      <div className="space-x-2">
        <span>{filename}</span>
        <Badge color="gray" size="sm">
          Archived
        </Badge>
      </div>
    );
  }

  return filename;
};

const ExportRowActions = ({ surveyExport }: { surveyExport: Export }) => {
  const { mutate: editExport } = useEditExport({
    onError: (err) => {
      showErrorMessage({ err });
    },
  });

  return (
    <Dropdown
      button={
        <DropdownButton as="div">
          <VerticalDotsButton ariaLabel="Export Actions" />
        </DropdownButton>
      }
    >
      {surveyExport.displayStatus === 'ARCHIVED' ? (
        <DropdownItem
          as="button"
          icon={<Icon id="upload-01" />}
          onClick={() => {
            editExport({
              data: { displayStatus: 'ACTIVE' },
              exportId: surveyExport.id,
            });
          }}
          type="button"
        >
          Unarchive
        </DropdownItem>
      ) : surveyExport.displayStatus === 'ACTIVE' ? (
        <DropdownItem
          as="button"
          icon={<Icon id="archive" />}
          onClick={() => {
            editExport({
              data: { displayStatus: 'ARCHIVED' },
              exportId: surveyExport.id,
            });
          }}
          type="button"
        >
          Archive
        </DropdownItem>
      ) : null}
    </Dropdown>
  );
};

const StatusDisplay = ({
  exportId,
  filename,
  status,
}: {
  exportId: number;
  filename: string;
  status: Export['status'];
}) => {
  const { isPending, mutate: downloadExportMutation } = useDownloadExport({
    filename,
  });

  if (status === 'PENDING' || status === 'IN_PROGRESS') {
    return <span className="text-green">In Progress</span>;
  } else if (status === 'FAILED') {
    return <span className="text-red">Failed</span>;
  }

  return (
    <button
      onClick={() => downloadExportMutation({ id: exportId })}
      title="Download file"
      type="button"
    >
      <div className="h-5 w-5 flex items-center justify-center">
        {isPending ? <CircleLoader isColored /> : <Icon id="cloud-download" />}
      </div>
    </button>
  );
};
