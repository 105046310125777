import { FieldArray, Form, Formik, useField, useFormikContext } from 'formik';
import { partition } from 'lodash-es';
import { useEffect } from 'react';

import {
  formDataToApiData,
  getEmptyQuestionBlock,
  getInitialQuestionBlocksFormData,
  QuestionBlocksFormData,
  QuestionBlocksFormDataValidated,
  validateQuestionBlocksData,
} from '../../util/questionBlocks';
import { generateQuestionsSection } from '../../util/questions';
import { getEligibleQuestions } from '../../util/displayLogic';
import { getNestedErrorMessages } from 'util/forms';
import { getQuestionOption } from '../../util/formOptions';
import { Question, QuestionBlock, Survey } from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import {
  useDeleteQuestionBlock,
  useSaveQuestionBlock,
} from 'hooks/backend/questionBlocks';
import { useHasRole } from 'hooks/users';

import { useSubmitValidation } from '../../hooks/forms';
import AddButton from '../common/forms/AddButton';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import DeleteQuestionBlock from './DeleteQuestionBlock';
import DisplayLogicCheckbox from './DisplayLogicCheckbox';
import ErrorDisplay from '../common/ErrorDisplay';
import FormCheckbox from '../common/forms/FormCheckbox';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from '../common/forms/FormInput';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';
import XButton from '../common/forms/XButton';

interface CreateQuestionBlocksLoadedProps {
  isShowingUnsavedChanges: boolean;
  onBlockDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onQuestionBlockDeleted(): void;
  onQuestionBlocksSaved(): void;
  questionBlocks: QuestionBlock[];
  questions: Question[];
  survey: Survey;
  surveyId: number;
}

const CreateQuestionBlocks = ({
  isLoadingDemographicQuestions,
  isLoadingQuestionBlocks,
  isLoadingQuestions,
  loadQuestionBlocksError,
  ...rest
}: CreateQuestionBlocksLoadedProps & {
  isLoadingDemographicQuestions: boolean;
  isLoadingQuestionBlocks: boolean;
  isLoadingQuestions: boolean;
  loadQuestionBlocksError: Error | null;
}) => {
  if (
    isLoadingDemographicQuestions ||
    isLoadingQuestions ||
    isLoadingQuestionBlocks
  ) {
    return <SkeletonSurveyCard />;
  }

  return loadQuestionBlocksError ? (
    <ErrorDisplay
      message={`Failed to load the question blocks. ${
        loadQuestionBlocksError && ` Error: ${loadQuestionBlocksError.message}`
      }`}
    />
  ) : (
    <CreateQuestionBlocksLoaded {...rest} />
  );
};

export default CreateQuestionBlocks;

const CreateQuestionBlocksLoaded = ({
  isShowingUnsavedChanges,
  onBlockDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onQuestionBlockDeleted,
  onQuestionBlocksSaved,
  questionBlocks,
  questions,
  survey,
  surveyId,
}: CreateQuestionBlocksLoadedProps): JSX.Element => {
  // Eventually we'll need to support multiple master blocks for a survey but for now, if one master block exists,
  // we'll want to edit it instead of attempting to create a new master block.
  const existingQuestionBlock = questionBlocks[0];

  const { isPending: isSavingQuestionBlocks, mutateAsync: saveQuestionBlocks } =
    useSaveQuestionBlock({
      onError: (err) => {
        onHasError();
        showErrorMessage({ err });
      },
      onSuccess: () => {
        showSuccessMessage('The block was saved successfully.');
        onQuestionBlocksSaved();
      },
    });

  const { isPending: isDeletingQuestionBlock, mutate: deleteQuestionBlock } =
    useDeleteQuestionBlock({
      onError: (err) => {
        showErrorMessage({ err });
      },
      onSuccess: () => {
        onQuestionBlockDeleted();
      },
    });

  return (
    <Formik<QuestionBlocksFormData>
      enableReinitialize={true}
      initialValues={getInitialQuestionBlocksFormData({
        questionBlocks,
        questions,
      })}
      onSubmit={(formData) => {
        const data = formDataToApiData({
          formData: formData as QuestionBlocksFormDataValidated,
          survey,
        });

        return existingQuestionBlock
          ? saveQuestionBlocks({
              blockId: existingQuestionBlock.id,
              data,
              surveyId,
            })
          : saveQuestionBlocks({ data, surveyId });
      }}
      validate={(formData) => {
        return validateQuestionBlocksData(formData);
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full">
        <QuestionBlocksForm
          existingQuestionBlock={existingQuestionBlock}
          isDeletingQuestionBlock={isDeletingQuestionBlock}
          isSavingQuestionBlocks={isSavingQuestionBlocks}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onBlockDirtyChanged={onBlockDirtyChanged}
          onConfirmDelete={() => {
            if (!existingQuestionBlock) {
              throw new Error(
                'Could not find question block to delete. Please refresh your page.',
              );
            }

            return deleteQuestionBlock({
              questionBlockId: existingQuestionBlock.id,
            });
          }}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
          questions={questions}
        />
      </Form>
    </Formik>
  );
};

const QuestionBlocksForm = ({
  existingQuestionBlock,
  isDeletingQuestionBlock,
  isSavingQuestionBlocks,
  isShowingUnsavedChanges,
  onBlockDirtyChanged,
  onConfirmDelete,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  questions,
}: {
  existingQuestionBlock: QuestionBlock;
  isSavingQuestionBlocks: boolean;
  isDeletingQuestionBlock: boolean;
  isShowingUnsavedChanges: boolean;
  onBlockDirtyChanged(isDirty: boolean): void;
  onConfirmDelete(): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  questions: Question[];
}) => {
  const { dirty } = useFormikContext<QuestionBlocksFormData>();

  const { errors, onClickSubmit, validateAndSubmit } = useSubmitValidation({
    isSaving: isSavingQuestionBlocks,
    onHasError,
  });

  useEffect(() => {
    onBlockDirtyChanged(dirty);
  }, [dirty, onBlockDirtyChanged]);

  return (
    <div>
      {isShowingUnsavedChanges && (
        <UnsavedChangesModal
          isSaving={isSavingQuestionBlocks}
          onClickDiscardChanges={onDiscardChanges}
          onClickSaveChanges={validateAndSubmit}
          onCloseModal={onDismissUnsavedChanges}
        />
      )}

      <SurveyStepStickyHeader>
        <h2 className="font-semibold text-base">Question Blocks</h2>

        <div className="flex space-x-4">
          {existingQuestionBlock && (
            <DeleteQuestionBlock
              isDeleting={isDeletingQuestionBlock}
              onConfirmDelete={onConfirmDelete}
            />
          )}
          <ButtonLoading
            disabled={!!(existingQuestionBlock && !dirty)}
            hierarchy="primary"
            isLoading={isSavingQuestionBlocks}
            onClick={onClickSubmit}
            size="sm"
            // This can't currently be a submit button since we handle the form submission
            // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
            type="button"
          >
            Save Block
          </ButtonLoading>
        </div>
      </SurveyStepStickyHeader>

      {errors && (
        <div className="my-4">
          <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
        </div>
      )}

      <CreateQuestionBlocksForm questions={questions} />
      <hr className="text-light-grey" />
      <AddQuestionBlockFeatures />
    </div>
  );
};

const CreateQuestionBlocksForm = ({ questions }: { questions: Question[] }) => {
  const [{ value: blocks }] =
    useField<QuestionBlocksFormData['blocks']>('blocks');

  return (
    <div className="pb-6">
      <FieldArray
        name="blocks"
        render={(arrayHelpers) => {
          return (
            <>
              <div>
                {blocks.length === 0 && (
                  <p className="mb-4 text-sm">
                    No question blocks are currently configured.
                  </p>
                )}
                {blocks.map((_block, index) => {
                  return (
                    <QuestionBlockChild
                      key={index}
                      index={index}
                      onClickRemove={() => {
                        if (blocks.length === 1) {
                          arrayHelpers.replace(0, getEmptyQuestionBlock());
                        } else {
                          arrayHelpers.remove(index);
                        }
                      }}
                      questions={questions}
                    />
                  );
                })}
              </div>
              <div className="flex mt-4">
                <AddButton
                  label="Add Question Block"
                  onClick={() => {
                    arrayHelpers.push(getEmptyQuestionBlock());
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

const QuestionBlockChild = ({
  index,
  onClickRemove,
  questions,
}: {
  index: number;
  onClickRemove(): void;
  questions: Question[];
}) => {
  const isAdmin = useHasRole('admin');

  const [{ value: displayXOfY }] = useField<
    QuestionBlocksFormData['displayXOfY']['enabled']
  >('displayXOfY.enabled');

  const [demographicQuestions, customQuestions] = partition(
    questions,
    (q) => q.isDemographic,
  );

  return (
    <>
      <div className="flex space-x-4">
        <FormInput label="Title" name={`blocks.${index}.title`} size="md" />
        <FormInput
          label="Start at Q #"
          name={`blocks.${index}.start`}
          size="md"
          type="number"
        />
        <FormInput
          label="End at Q #"
          name={`blocks.${index}.end`}
          size="md"
          type="number"
        />
        <div className="mt-6">
          <XButton onClick={onClickRemove} title="Remove" />
        </div>
      </div>
      <div className="mt-4">
        <DisplayLogicCheckbox
          fieldPrefix={`blocks.${index}.displayLogic`}
          isWithinMonadicLoop={false}
          questionOptions={[
            generateQuestionsSection({
              questions: demographicQuestions.map((question) => {
                return getQuestionOption({ question });
              }),
              title: 'Demographic Questions',
            }),
            generateQuestionsSection({
              questions: getEligibleQuestions({
                questions: customQuestions,
              }).map((question) => {
                return getQuestionOption({ question });
              }),
              title: 'Survey Questions',
            }),
          ]}
          type="block"
        />

        {isAdmin && displayXOfY && (
          <div>
            <FormCheckbox
              checkboxLabel={
                <div className="flex items-center">
                  <span>Include block in X of Y</span>
                </div>
              }
              disabled={false}
              name={`blocks.${index}.countInX.enabled`}
            />
          </div>
        )}
      </div>
    </>
  );
};

const AddQuestionBlockFeatures = (): JSX.Element => {
  const [{ value: displayXOfY }] = useField<
    QuestionBlocksFormData['displayXOfY']['enabled']
  >('displayXOfY.enabled');

  return (
    <div className="space-y-4 py-6">
      <FormCheckbox checkboxLabel="Randomize Blocks" name="isRandomized" />
      <div className="flex items-center space-x-4">
        <FormCheckbox
          checkboxLabel="Display X of Y"
          name="displayXOfY.enabled"
        />
        {displayXOfY && (
          <FormInput name="displayXOfY.value" size="md" type="number" />
        )}
      </div>
    </div>
  );
};
