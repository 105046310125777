import { useField } from 'formik';

import {
  GABOR_GRANGER_FORMAT_OPTIONS,
  GABOR_GRANGER_OBJECTIVE_OPTIONS,
} from 'util/questions';
import { Question, QuestionBlock } from '../../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import FormInput from '../../common/forms/FormInput';
import FormSearchSelectInput from 'components/common/forms/FormSearchSelectInput';
import PipeConcept from '../questionFeatures/PipeConcept';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';
import ViewConcept from '../questionFeatures/ViewConcept';

const GaborGranger = ({
  concepts = [],
  pipeConcept = false,
  question,
  questionBlocks,
  questions,
}: {
  concepts?: QuestionFormOption[];
  pipeConcept?: boolean;
  question: Question | undefined;
  questionBlocks: QuestionBlock[];
  questions: Question[];
}) => {
  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          disabled={pipeConcept}
          question={question}
          questionBlocks={questionBlocks}
          questions={questions}
          upperLimit={1}
        />
      </SurveyEditRow>

      <GaborGrangerFormatFields />

      <div className="py-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <PipeConcept
            concepts={concepts}
            question={question}
            questions={questions}
          />
          <ViewConcept />
          <DisplayLogic question={question} questions={questions} />
        </div>
      </div>
    </>
  );
};

export default GaborGranger;

const GaborGrangerFormatFields = () => {
  const [{ value: objective }] = useField<
    QuestionFormData['gaborGrangerSettings']['objective']
  >('gaborGrangerSettings.objective');
  const [{ value: format }] = useField<
    QuestionFormData['gaborGrangerSettings']['format']
  >('gaborGrangerSettings.format');

  return (
    <div className="py-6 border-b border-gray-300 space-y-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="space-y-2">
          <FormSearchSelectInput
            inputId="objective"
            label="Objective"
            labelFor="objective"
            name="gaborGrangerSettings.objective"
            options={GABOR_GRANGER_OBJECTIVE_OPTIONS}
          />
          <p className="text-gray-600 text-xs">
            The algorithm will seek the{' '}
            <span className="font-bold">
              {objective.value === 'MAX_SEEKING' ? 'maximum' : 'minimum'}
            </span>{' '}
            value the respondent is willing to accept
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="grid grid-cols-3 gap-y-2 gap-x-4 col-span-3">
          <FormInput
            id="minimum"
            label="Minimum Value"
            labelFor="minimum"
            name="gaborGrangerSettings.min"
            size="md"
            type="number"
          />
          <FormInput
            id="maximum"
            label="Maximum Value"
            labelFor="maximum"
            name="gaborGrangerSettings.max"
            size="md"
            type="number"
          />
          <FormInput
            id="increment"
            label="Increment"
            labelFor="increment"
            name="gaborGrangerSettings.increment"
            size="md"
            type="number"
          />
          <p className="col-span-3 text-gray-600 text-xs">
            The algorithm will seek within this range by the increment
            specified.
          </p>
        </div>
        <FormSearchSelectInput
          inputId="format"
          label="Format"
          labelFor="format"
          name="gaborGrangerSettings.format"
          options={GABOR_GRANGER_FORMAT_OPTIONS}
        />
        {format.value === 'CUSTOM' && (
          <FormInput
            id="formatCustomText"
            label="Custom Text"
            labelFor="formatCustomText"
            name="gaborGrangerSettings.formatCustomText"
            size="md"
            type="text"
          />
        )}
        <FormInput
          id="unitDecimals"
          label="Decimals"
          labelFor="unitDecimals"
          name="gaborGrangerSettings.unitDecimals"
          size="md"
          type="number"
        />
      </div>
    </div>
  );
};
