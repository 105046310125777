import { QuestionFormOption, QuestionQuotaValidated } from 'types/forms';
import {
  QUESTION_TYPE,
  Question,
  QuestionQuotaResult,
} from 'types/domainModels';
import { orderBy } from 'lodash-es';

export function formQuotaToApiQuota({
  quota,
}: {
  quota: QuestionQuotaValidated;
}) {
  return {
    disqualificationType: quota.isQualityDisqualification
      ? ('QUALITY' as const)
      : ('NON_QUALITY' as const),
    logicalModifier: quota.logicalModifier.value,
    numberNeeded: quota.numberNeeded === '' ? null : quota.numberNeeded,
    options: quota.options.map(({ value }) => value),
    quotaId: quota.quotaId,
  };
}

export function getUnusedQuotaOptions({
  options,
  usedOptions,
}: {
  options: QuestionFormOption[];
  usedOptions: { id: number | null; title: string }[];
}) {
  const quotaOptions: QuestionFormOption[] = [];
  const usedOptionsClone = [...usedOptions];

  for (const option of options) {
    if (!option.image.data && !option.title) {
      continue;
    }

    const usedOptionIndex = usedOptionsClone.findIndex((usedOption) => {
      return option.id
        ? usedOption.id === option.id
        : usedOption.title === option.title;
    });
    if (usedOptionIndex !== -1) {
      usedOptionsClone.splice(usedOptionIndex, 1);
      continue;
    }

    quotaOptions.push(option);
  }

  return quotaOptions;
}

export function orderQuotasByOptions(quotas: QuestionQuotaResult[]) {
  return orderBy(quotas, (quota) => {
    const orderedOptions = orderBy(quota.options, (o) => o.sort);

    return orderedOptions[0]?.sort;
  });
}

export function questionSupportsQuotas(question: Question) {
  return (
    question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE ||
    question.questionTypeId === QUESTION_TYPE.RANKING
  );
}
